.dropDownSearch {
    display: flex;
    position: relative;
    .dataList{
        z-index: 9999;
        position: absolute;
        top: calc(100% + 3px);
        left: 0;
        max-height: 500px;


        ul{
            background-color: #fff;
            margin: 0;
            padding: 5px;
            border-radius: 0.25rem;
            li.item{
                padding: 5px 15px !important;
                cursor: pointer;
                width: 100%;
                display: flex;
                color: #003366 !important;

                &.selectedItem{
                    background-color: rgba(0,51,102, .1) !important;
                }
            }
        }
    }

    .__pagination{
        background-color: #F2F2F2;
        border-top: 1px solid rgba(0,0,0,.1);
        .__btnChangePage{
            color: #003366;
            font-size: 20px;
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
        }
        .__pageInfo{
            display: flex;
            gap: 5px;
            justify-content: center;
            align-items: center;

            .__pageNumber{
                &:first-child{
                    font-weight: 600;
                }
                font-size: 16px;
            }
        }
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

}
