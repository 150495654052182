/* Visibilidade dos links de pesquisa: */
@media (max-width: 600px) {
  .info__tabs,
  .info__tabs__content {
    padding: 40px 0 0 0 !important;
  }
  .card__header,
  .card__header__title {
    text-align: center;
  }

  .card__holder {
    padding: 0 !important;
  }
}

.info__tabs {
  padding: 20px 30px;
  background-color: #eeeeee;

  &.tabs-ask {
    background-color: $light-gray-2;
  }

  .info__tabs__tabs {
    display: flex;
    justify-content: center;
    border-bottom: 1px;

    .nav-item {
      &:hover {
        background-color: $white;
        transition: 300ms;
        box-shadow: inset 0px -3px 0px 0px $light-color;
      }
    }
  }

  .tab-content {
    background-color: $white;
  }

  .card__header {
    padding: 0.4em 1em;
    font-size: 1.3em;

    .card__header__title {
      color: $b-tema;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      border-bottom: 1px dotted $b-tema;

      &.tabs-ask {
        color: #000;
      }
    }

    .icone-header {
      height: 60px;
      width: 60px;     
      border-radius: 50%;
      padding: 10px;
      background-color: $white;
    }
  }

  .card__holder {
    padding: 0 5em;

    .card__holder__content.col {
      display: flex;
      flex-direction: column;
    }
  }

  .info__tabs__collapse {
    display: none;
    flex-direction: column;

    label {
      padding: 1em;
      background-color: $white;
      border: solid $light-color;
      border-radius: 3px;
      color: $b-tema;
      width: 100%;
      cursor: pointer;
    }

    .collapse__content {
      background-color: $white;
      margin-bottom: 1em;
      border: solid $light-gray;
    }
  }
}
