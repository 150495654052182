#pesquisa_select {
  display: none;
}

.clean {
  height: 35px;
  width: 35px;
}

/* Visibilidade dos links de pesquisa: */
@media (max-width: 1007px) {
  #pesquisa_list {
    display: none;
  }
  #pesquisa_select {
    display: block;
  }

  .input-holder,
  .left__input__action {
    padding: 0 !important;
  }

  .left__input__action {
    button {
      border: solid $light-gray !important;

      &.search {
        border-color: $success !important;
      }

      &.clean {
        border-color: $danger !important;
      }
    }

    span {
      display: block !important;
    }
  }
}

@media (max-width: 576px) {
  .left__input__action {
    flex-direction: column !important;

    button {
      width: 100%;
      border-radius: 5px;

      &.search {
        background-color: $success !important;
      }

      &.clean {
        background-color: $danger !important;
        margin-top: 1em;
      }

      svg,
      span {
        color: white !important;
      }
    }
  }
}

.header__search__content {

  padding-bottom: 100px;
  

  .search__content__left {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around; 

    a.content__left__header:hover {
      color: #0056b3;
      text-decoration: none;
      background-color: transparent;
    }

    .content__left__header {
      width: 100%;

      label.guia_pesquisa {
        border-bottom: 1px dotted #a6a6a6;
        color: #a6a6a6;
        padding: 5px 10px 5px 10px;
        border-radius: 0px;
        display: inline-block;
        position: relative;
        margin: 4px 7px 4px 7px;
        text-decoration: none;
        cursor: pointer;
        transition: All 0.2s ease;
        font-weight: bold;
        font-size: 12px;
        padding: 5px 10px 5px 10px;

        &.active {
          border: none;
          background: #8b8b8b;
          color: $white;
          border-radius: 5px !important;
          border-color: $link-color-h transparent transparent transparent;

          &:after {
            display: inline-block;
            border: 5px solid;
            height: 0;
            width: 0;
            position: absolute;
            top: 27px;
            content: "";
            display: inline-block;
            left: calc(50% - 4px);
            border-color: $b-tema transparent transparent transparent;
          }
        }

        &:hover{
          color:white !important;
        }
      }

      .content__left__input {
        display: flex;
        flex-direction: row;

        .left__input__action {
          display: flex;
          align-items: center;
          justify-content: space-around;

          button {
            background-color: transparent;
            border: transparent;
            display: flex;

            &:hover svg {
              color: $white !important;
            }

            &.search:hover {
              transition: 800ms;
              background-color: $success;
            }

            &.clean:hover {
              transition: 800ms;
              background-color: $danger;
            }

            span {
              color: #000;
              margin-left: 10px;
              display: none;
            }
          }

          svg {
            cursor: pointer;
          }
        }

        .left__input__historic {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $b-tema;

          svg {
            margin-right: 10px;
          }

          h6 {
            margin: 0;
            text-decoration: underline;
          }
        }
      }
    }

    a:hover {
      label {
        border: none;
        transition: All 0.2s ease;
        background: #8b8b8b;
        color: $white;
        border-radius: 5px;

        &:after {
          display: inline-block;
          border: 5px solid;
          height: 0;
          width: 0;
          position: absolute;
          top: 27px;
          content: "";
          display: inline-block;
          left: calc(50% - 4px);
          border-color: #8b8b8b transparent transparent transparent;
        }
        &.active:after {
          border-color: $b-tema transparent transparent transparent;
        }
      }
    }
  }
}
