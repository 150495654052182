$temas: (
  agata: (
    0,
    0,
    0,
    10,
    36,
    62,
  ),
  ambar: (
    210,
    155,
    0,
    10,
    36,
    62,
  ),
  ametista: (
    66,
    9,
    95,
    10,
    36,
    62,
  ),
  cristal: (
    10,
    36,
    62,
    111,
    111,
    113,
  ),
  esmeralda: (
    7,
    74,
    49,
    10,
    36,
    62,
  ),
  oceanus: (
    48,
    150,
    255,
    10,
    36,
    62,
  ),
  rose: (
    214,
    88,
    88,
    10,
    36,
    62,
  ),
  rubi: (
    172,
    35,
    51,
    10,
    36,
    62,
  ),
  safira: (
    0,
    51,
    102,
    10,
    36,
    62,
  ),
  topazio: (
    208,
    77,
    0,
    10,
    36,
    62,
  ),
  temaAltoContraste: (
    10,
    10,
    10,
    255,
    255,
    255,
  ),
);

@each $name, $colors in $temas {
  .#{$name} {
    &.acessibilidade {
      &.zoom-20 {
        zoom: 120%;
        width: 120%;
      }
      &.zoom-40 {
        zoom: 140%;
        width: 140%;
      }
      &.zoom-60 {
        zoom: 160%;
        width: 160%;
      }
      &.zoom-80 {
        zoom: 180%;
        width: 180%;
      }
      &.zoom-100 {
        width: 200%;
        zoom: 200%;
      }
      &.negrito,
      &.negrito * {
        font-weight: 900 !important;
      }
      &.escala-cinza {
        filter: grayscale(1);
      }
      &.invertido {
        filter: invert(100%);
      }
    }

    .corFundo {
      background: rgba(nth($colors, 4), nth($colors, 5), nth($colors, 6), 0.4) !important;
    }
    .corFundo2 {
      background: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) !important;
    } /* cor pura com opacidade de 100% */
    


    .htema:hover,
    .bktema,
    .z-window,
    .tag2,
    .modal-title,
    .hover3:hover,
    .btnTema:hover {
      background-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) !important	;
      &:after {
        border-top-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) !important	;
      }
    }
    .bktema10 {
      background-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.1) !important;
    }
    .bktema20 {
      background-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.2) !important;
    }
    .bktema30 {
      background-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.3) !important;
    }
    .bktema40 {
      background-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.4) !important;
    }
    .bktema50,
    .linhaMenu:hover {
      background-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.5) !important;
    }
    .bktema60 {
      background-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.6) !important;
    }
    .bktema70 {
      background-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.7) !important;
    }
    .bktema80 {
      background-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.8) !important;
    }
    .bktema90 {
      background-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.9) !important;
    }

    .btnMenuEnquete .cortxt {
      color: rgba(3, 13, 23, 0.8) !important;
      transition: All 0.2s ease;
    }
    .btnMenuEnquete:hover .cortxt {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) !important;
    }
    .btnMenuEnquete .ativo {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) !important;
    }
    .btnMenuEnquete {
      box-shadow: 0px -1px 0px 0px rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.2) inset;
      border: none;
      transition: All 0.5s ease;
    }
    .btnMenuEnquete:hover {
      box-shadow: 0px -5px 0px 0px rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) inset;
      border: none;
    }

    .btnMenuAdm .cortxt {
      color: rgba(3, 13, 23, 0.8) !important;
      transition: All 0.2s ease;
    }
    .btnMenuAdm:hover .cortxt {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) !important;
    }
    .btnMenuAdm .ativo {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) !important;
    }
    .btnMenuAdm {
      box-shadow: 0px -1px 0px 0px rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.2) inset;
      border: none;
      transition: All 0.5s ease;
    }
    .btnMenuAdm:hover {
      box-shadow: 0px -5px 0px 0px rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) inset;
      border: none;
    }

    .ativo2 {
      box-shadow: 0px -5px 0px 0px rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.5) inset;
      border: none;
      transition: All 0.5s ease;
    }

    .btnall2 {
      box-shadow: 300px 0px 0px 0px rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) inset;
      border: none;
      transition: All 0.2s ease;
    }
    .btnall2:hover {
      box-shadow: 0px 0px 0px 0px rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) inset;
      border: none;
    }

    .bktemadegrade {
      background: linear-gradient(
        -60deg,
        rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1),
        rgb(205, 225, 243)
      ) !important; /* degradê */
    }

    body,
    .hover1:hover,
    .btnNumber:hover,
    .cortxt {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) !important	;
    }
    .cortxt.qrcode {
      path:nth-child(2) {
        fill: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) !important	;
      }
    }
    .cortxt10 {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.1) !important	;
    }
    .cortxt20 {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.2) !important	;
    }
    .cortxt30 {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.3) !important	;
    }
    .totalFiltro:before,
    .totalFiltro:after,
    .cortxt40 {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.4) !important	;
    }
    .cortxt50 {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.5) !important	;
    }
    .cortxt60 {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.6) !important	;
    }
    .btnAzul,
    .cortxt70 {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.7) !important	;
    }
    .cortxt80 {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.8) !important	;
    }
    .cortxt90 {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.9) !important	;
    }
    .cortxt2 {
      color: #fff !important	;
    } /* cor para contraste / segunda cor */
    .b-tema {
      border-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) !important;
    }
    .b-none {
      border: none !important;
    }
    .capitalizar {
      text-transform: uppercase !important;
    }
    .b-tema10 {
      border-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.1) !important;
    }
    .b-tema20 {
      border-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.2) !important;
    }
    .b-tema30 {
      border-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.3) !important;
    }
    .b-tema40 {
      border-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.4) !important;
    }
    .b-tema50 {
      border-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.5) !important;
    }
    .b-tema70 {
      border-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.7) !important;
    }
    .b-tema80 {
      border-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.8) !important;
    }
    .b-tema90 {
      border-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.9) !important;
    }

    .stema {
      box-shadow: 1px 6px 10px 1px rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.4) !important;
    }
    .tag2:after {
      border-color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1) transparent transparent transparent;
    }

    .detaque_titulo1 {
      padding: 10px 10px 10px 22px;
      border-left: 8px solid rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.2); /* */
    }
    .imgTemaSelect {
      outline: 1px solid rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.2);
    }
    .z-window-header {
      color: rgba(255, 255, 255, 1) !important	;
    }

    .login1 {
      box-shadow: 0px 0px 9px 7px rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.2);
      border: 3px solid rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.29);
      background: rgba(255, 255, 255, 0.9);
    }
    .logintit {
      box-shadow: 0px
        2px
        18px
        rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.1); /* cor pura com opacidade de 10% */
    }
    .footerLogin {
      background: rgba(
        nth($colors, 4),
        nth($colors, 5),
        nth($colors, 6),
        0.4
      ); /* cor pura + escura com opacidade de 40% */
    }
    .imgLogoSelect {
      border: 1px solid rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1);
    }
    .linhabot01 {
      background: #f9f9f9;
    }
    .linhabot02 {
      background: #fff;
    }

    button {
      border: groove rgba(255, 255, 255, 0.5);
      &:hover {
        border: groove rgba(255, 255, 255, 0.8);
      }
      &:active {
        border: groove rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 0.2);
      }
    }

    .z-button,
    .z-button:focus {
      color: #991426;
    }
    .z-button:hover {
      background: #000;
      color: #fff;
    }
    .z-button:active {
      background: #008000;
      color: #fff;
    }

    .btn1,
    .btn1:focus {
      background: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1);
      color: #fff;
    }
    
  }
}

@each $name, $colors in $temas {
  .#{$name}-color {
    &.text {
      color: rgba(nth($colors, 1), nth($colors, 2), nth($colors, 3), 1);
    }
    &.background {
      color: rgba(nth($colors, 4), nth($colors, 5), nth($colors, 6), 1);
    }
  }
}



// Únicos no tema (não variam com o template
.no-p > p {
  margin: 0 !important;
  padding: 0 !important;
}

.fixedToTop {
  position: fixed;
  right: 40px;
  bottom: 50px;
  z-index: 3;
  transition: all;
}

/* --- cores --- */
.amarelo {
  color: #ffec0c !important;
}
.azul {
  color: #007bff !important;
}
.azul1 {
  color: #b0c8e0 !important;
}
.azul_celeste {
  color: #04c6bc !important;
}
.btnSearch,
.verde {
  color: #419f42 !important;
}
.verde1 {
  color: #b3ccb3 !important;
}
.vermelho {
  color: #f00 !important;
}
.btnVermelho,
.vermelho1 {
  color: #c39292 !important;
}
.laranja {
  color: #ff7f24 !important;
}
.laranja1 {
  color: #f5ad7a !important;
}
.roxo {
  color: #8a2be2 !important;
}
.preto {
  color: #000000 !important;
}
.apergamum {
  color: #003366 !important;
}
.hover3:hover,
.hover2:hover,
.btnTema:hover,
.btnBranco,
.branco,
.linhaMenu:hover,
.branco {
  color: rgba(255, 255, 254, 1) !important;
}
.branco10 {
  color: rgba(255, 255, 255, 0.1) !important;
}
.branco20 {
  color: rgba(255, 255, 255, 0.2) !important;
}
.branco30 {
  color: rgba(255, 255, 255, 0.3) !important;
}
.branco40 {
  color: rgba(255, 255, 255, 0.4) !important;
}
.branco50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.branco60 {
  color: rgba(255, 255, 255, 0.6) !important;
}
.branco70 {
  color: rgba(255, 255, 255, 0.7) !important;
}
.branco80 {
  color: rgba(255, 255, 255, 0.8) !important;
}
.branco90 {
  color: rgba(255, 255, 255, 0.9) !important;
}
.cinza {
  color: #c0c0c0 !important;
}
.azul_celeste1 {
  color: #a2b3e6 !important;
}
.magenta {
  color: #ff0076 !important;
}

.preto10 {
  color: rgba(0, 0, 0, 0.1) !important;
}
.preto20 {
  color: rgba(0, 0, 0, 0.2) !important;
}
.preto30 {
  color: rgba(0, 0, 0, 0.3) !important;
}
.preto40 {
  color: rgba(0, 0, 0, 0.4) !important;
}
.preto50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.preto60 {
  color: rgba(0, 0, 0, 0.6) !important;
}
.preto70 {
  color: rgba(0, 0, 0, 0.7) !important;
}
.preto80 {
  color: rgba(0, 0, 0, 0.8) !important;
}
.preto90 {
  color: rgba(0, 0, 0, 0.9) !important;
}

.pergamum-text {
  color: rgba(0, 63, 98, 1) !important;
}
.pergamum-80-text {
  color: rgba(0, 63, 98, 0.8) !important;
}
.pergamum-50-text {
  color: rgba(0, 63, 98, 0.5) !important;
}
.pergamum-10-text {
  color: rgba(0, 63, 98, 0.1) !important;
}

/* --- background --- */
.bkamarelo {
  background-color: #ffec0c !important;
}
.bkazul {
  background-color: #3399ff !important;
}
.bkazul1 {
  background-color: #b0c8e0 !important;
}
.bkverde {
  background-color: #419f42 !important;
}
.bkverde1 {
  background-color: #b3ccb3 !important;
}
.bkvermelho {
  background-color: #f00 !important;
}
.bkvermelho1 {
  background-color: #c39292 !important;
}
.bkvermelho2 {
  background-color: #d9534f !important;
}
.bklaranja {
  background-color: #ff7f24 !important;
}
.bklaranja1 {
  background-color: #f5ad7a !important;
}
.bkroxo {
  background-color: #8a2be2 !important;
}
.bkpreto {
  background-color: #000000 !important;
}
.bkamarelo {
  background-color: #fdc00d !important;
}
.bkpergamum {
  background-color: #00476b !important;
}
.bkbranco {
  background-color: #fff !important;
}
.bkcinza {
  background-color: #c0c0c0 !important;
}
.bkcinza2 {
  background-color: rgba(192, 192, 192, 0.2) !important;
}
.bkcinza3 {
  background-color: #525252 !important;
}
.bkazul_celeste {
  background-color: #04c6bc !important;
}
.bkmagenta {
  background-color: #ff0076 !important;
}
.bkpreto {
  background-color: #000 !important;
}
.bkazulclaro1 {
  background-color: #f1f5fb !important;
}
.bkamarelo {
  background-color: #ffec0c !important;
}
  
/* --- hover --- */
.z-checkbox .z-checkbox-content:hover,
.hover:hover {
  color: #fff !important;
  -webkit-transition: All 0.5s ease;
  transition: All 0.4s ease;
}
.hover2:hover {
  color: #000 !important;
  -webkit-transition: All 0.5s ease;
  transition: All 0.4s ease;
}
.hamarelo:hover {
  background-color: #ffec0c !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hazul:hover {
  background-color: #3399ff !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hazul1:hover {
  background-color: #b0c8e0 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hverde:hover {
  background-color: #419f42 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hverde1:hover {
  background-color: #b3ccb3 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hvermelho:hover {
  background-color: #f00 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hvermelho1:hover {
  background-color: #c39292 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hvermelho2:hover {
  background-color: #d9534f !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hlaranja:hover {
  background-color: #ff7f24 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hlaranja1:hover {
  background-color: #f5ad7a !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hroxo:hover {
  background-color: #8a2be2 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hpreto:hover {
  background-color: #000000 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hpergamum:hover {
  background-color: #003366 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hbranco:hover {
  background-color: #fff !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hcinza:hover {
  background-color: #c0c0c0 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hcinza2:hover {
  background-color: rgba(192, 192, 192, 0.2) !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hcinza3:hover {
  background-color: #525252 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hazul_celeste:hover {
  background-color: #04c6bc !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hmagenta:hover {
  background-color: #c609cc !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hpreto:hover {
  background-color: #000 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
.hazulclaro1:hover {
  background-color: #f1f5fb !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}
/* --- .hover --- */

/* --- opacidade --- */
  .op00 {  opacity: 0;    }
  .op01 {  opacity: 0.1;  }
  .op02 {  opacity: 0.2;  }
  .op03 {  opacity: 0.3;  }
  .op04 {  opacity: 0.4;  }
  .op05 {  opacity: 0.5;  }
  .op06 {  opacity: 0.6;  }
  .op07 {  opacity: 0.7;  }
  .op08 {  opacity: 0.8;  }
  .op09 {  opacity: 0.9;  }
  .op10 {  opacity: 1;    }
/* --- .opacidade --- */

.acessibilidade.contraste {
  @import "contraste.scss";
}
