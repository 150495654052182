/* -- BouStyle 					-- */
/* -- by Bourscheid, Ricardo 	-- */
/* -- 2017 						-- */

/* -------------------------------------------------------------------------------- */

/* -- Logotipo e Imagem Biblioteca -- */
.img_topo_biblioteca {
    min-height: 140px;
    background-size: cover;
    padding-top: 140px;
    padding-bottom: 20px;
}
.imgMeuPergamum {
    background-size: cover;
    background-repeat: no-repeat;
    /*height: 100vh;*/
    background-position: top center;
}
.img_topo_bibliotecaCor {
    height: 450px;
    margin-top: -450px;
    position: relative;
    z-index: 1;
}
.img_logo {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 226px;
    height: 120px;

    border-radius: 0px 0px 5px 5px;
    z-index: 2;
    position: relative;
    margin-bottom: -126px;
    margin-left: 90px;
    margin-top: 0px;
    border-top: 1px solid;
}
.bkbrancodegrade {
    background: url('../../images/fundo_degrade_branco.png') top center repeat-x;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    padding-right: 8px !important;
    padding-left: 8px !important;
}

.searchSpace {
    position: relative;
    width: 86%;
    /*margin-top: -300px;*/
    margin-top: -281px;
    margin-left: -43%;
    margin-bottom: 80px;
    left: 50%;
    z-index: 5;
}
.meuPergamumSpace {
    position: relative;
    width: 86%;
    margin-top: -90px;
    margin-left: -43%;
    margin-bottom: 80px;
    left: 50%;
    z-index: 6;
}
.topLinkSpace {
    position: absolute;
    left: 45%;
    z-index: 10;
    margin-top: 148px;
}
.topLinkSpace2 {
    /*position: absolute;
    right: 1%;
    z-index: 10;*/
    position: absolute;
    right: 20px;
    top: 0;
    z-index: 10;
    border-radius: 0 0 10px 10px;
}
.foto_user {
    border-radius: 100%;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
    width: 62px;
    height: 62px;
    border: 2px solid #fff;
}

/* -- detail img news */
.detail_news_img :hover .responsive-img {
    opacity: 0.25;
    color: #fff;
}
.detail_news_img :hover .descricao {
    display: block;
    opacity: 1;
}
.descricao {
    display: none;
    position: absolute;
    z-index: 100;
    color: #fff;
}

/* -- .detail img news -- */

/* -- ícones bandeiras -- */
.flag_ativo {
    width: 30px !important;
    height: 30px !important;
    top: 10px !important;
}
.ico_br,
.ico_ca,
.ico_en,
.ico_es,
.ico_fr,
.ico_it {
    width: 20px;
    height: 20px;
    position: relative;
    top: 6px;
    right: -10px;
    display: inline-block;
    margin-right: 5px;
}
.ico_br {
    background: url('../../images/br.svg');
    background-size: 152%;
    background-position: center;
}
.ico_ca {
    background: url('../../images/ca.svg');
    background-size: 190%;
    background-position: center;
}
.ico_en {
    background: url('../../images/en.svg');
    background-size: 190%;
    background-position: center;
}
.ico_es {
    background: url('../../images/es.svg');
    background-size: 152%;
    background-position: center left;
}
.ico_fr {
    background: url('../../images/fr.svg');
    background-size: 106%;
    background-position: center;
}
.ico_it {
    background: url('../../images/it.svg');
    background-size: 106%;
    background-position: center;
}
/* -- .ícones bandeiras -- */

/* ----------------------------------------------------------------------------- */

/* --- enquetes --- */
.videoError {
    width: 640px !important;
    height: 360px !important;
    margin: auto;
    padding-bottom: 0px !important;
}
/*.ativado {
      filter: blur(0px) !important;
    }*/
.divCentro2 {
    /*filter: blur(50px);*/
    /*height: 100%;*/
    min-height: 100vh;
    /*position: absolute;
        left: 0%;
        right: 0%;
        z-index: 10;*/

    /* habilita o flex nos filhos diretos */
    /*display: -ms-flex;
        display: -webkit-flex;
        display: flex;*/

    /* centraliza na vertical */
    /*-ms-align-items: center;
        -webkit-align-items: center;
        align-items: center;*/
    width: 80%;
    margin: auto;
}
.divCentro {
    /*height: 100%;*/
    min-height: 100%;
    position: absolute;
    left: 0%;
    right: 0%;
    z-index: 10;

    /* habilita o flex nos filhos diretos */
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;

    /* centraliza na vertical */
    -ms-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    width: 80%;
    margin: auto;
}
.cor {
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 1;
}
.imagem {
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 2;
}
.imgReponsivo {
    width: 100%;
}
/*.buttomEnquete {
    }
    .buttomEnquete:hover {
    }*/
.iframe {
    width: 100%;
    height: 100%;
}
.bkzero {
    background-color: transparent !important;
}
.zero {
    color: rgba(255, 255, 255, 0.1) !important;
}

.btnall33 {
    transition: All 0.2s ease;
    border: none;
}
.btnall33:hover {
    color: #fff !important;
    border: none;
}

.btn_abre_menu_cima {
    display: none;
}

.breadcrumbPortal1 {
    margin-left: 300px;
}

.breadcrumbPortal2 {
    margin-left: 10px;
}

.linhaMenuTopo {
    border-top: 1px solid #757575;
    border-left: 1px solid #757575;
    border-right: 3px solid #525252;
    border-bottom: 1px solid #000;
    color: #fff;
    margin-bottom: 2px;

    display: inline;
    margin-right: 2px;
    border-radius: 5px;
}
.linhaMenuTopo:hover {
    border-right: 3px solid #fff;
}

/* -- button check -- */
.checknew {
    font: normal normal normal 30px/1 FontAwesome;
}

.checknew > input[type='checkbox'] {
    display: none;
}

.checknew > input[type='checkbox'] ~ label {
    /*box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.34) inset;*/
    border-radius: 20px;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
}

.checknew > input[type='checkbox']:not(:checked) ~ label {
    /*padding: 2px 2px 2px 18px;
        background-color: #c0c0c0;*/
}

.checknew > input[type='checkbox']:not(:checked) ~ label:before {
    content: '\f096';
}

.checknew > input[type='checkbox']:checked ~ label {
    /*padding: 2px 20px 2px 2px;
        background-color: #00bb2f;*/
}

.checknew > input[type='checkbox']:checked ~ label:before {
    content: '\f046';
}
/* --.button check -- */

button:disabled:hover {
    background-color: #ffffff !important;
    color: rgba(0, 0, 0, 0.5) !important;
    cursor: no-drop;
    box-shadow: none;
}

button:disabled {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.3) !important;
    cursor: no-drop;
    box-shadow: none;
    text-decoration: line-through;
}

/* -- check bootstrap -- */

.btn i.fa {
    opacity: 0;
}
.btn.active i.fa {
    opacity: 1;
}
.btn {
    padding: 0px 1px;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-top: -2px;
}
/* -- .check bootstrap -- */

/* -- button checknew2 -- */
.checknew2 {
    font: normal normal normal 30px/1 FontAwesome;
}

.checknew2 > input[type='checkbox'] {
    display: none;
}

.checknew2 > input[type='checkbox'] ~ label {
    width: 25px;
    height: 25px;
    border: 2px solid rgba(109, 109, 109, 0.13);
    background: rgba(179, 179, 179, 0.1);
    border-radius: 5px;
    box-shadow: 3px 2px 6px 2px rgba(68, 68, 68, 0.5) inset;
    line-height: 10px;

    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
}

.checknew2 > input[type='checkbox']:not(:checked) ~ label {
    line-height: 10px;
}

.checknew2 > input[type='checkbox']:not(:checked) ~ label:before {
    content: '\f00c';
    font-size: 70%;
    color: rgba(0, 0, 0, 0);
}

.checknew2 > input[type='checkbox']:checked ~ label {
    background-color: #00bb2f;
}

.checknew2 > input[type='checkbox']:checked ~ label:before {
    content: '\f00c';
    font-size: 64%;
    color: #fff;
}
/* --.button check -- */

/* -- button radio -- */
.radionew {
    font: normal normal normal 30px/1 FontAwesome;
}

.radionew > input[type='radio'] {
    display: none;
}

.radionew > input[type='radio'] ~ label {
    /*box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.34) inset;*/
    border-radius: 20px;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
}

.radionew > input[type='radio']:not(:checked) ~ label {
    /*padding: 2px 2px 2px 18px;
        background-color: #c0c0c0;*/
}

.radionew > input[type='radio']:not(:checked) ~ label:after {
    content: '\f10c';
}

.radionew > input[type='radio']:checked ~ label {
    /*padding: 2px 20px 2px 2px;
        background-color: #00bb2f;*/
}

.radionew > input[type='radio']:checked ~ label:after {
    content: '\f192';
}
/* --.button radio -- */
/* -- button radio2 -- */
.radionew2 {
    font: normal normal normal 15px/1 FontAwesome;
}

.radionew2 > input[type='checkbox'] {
    display: none;
}

.radionew2 > input[type='checkbox'] ~ label {
    /*box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.34) inset;*/
    border-radius: 20px;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
}

.radionew2 > input[type='checkbox']:not(:checked) ~ label {
    /*padding: 2px 2px 2px 18px;
        background-color: #c0c0c0;*/
}

.radionew2 > input[type='checkbox']:not(:checked) ~ label:after {
    content: '\f10c';
}

.radionew2 > input[type='checkbox']:checked ~ label {
    /*padding: 2px 20px 2px 2px;
        background-color: #00bb2f;*/
}

.radionew2 > input[type='checkbox']:checked ~ label:after {
    content: '\f192';
}
/* --.button radio2 -- */

/* -- Bootstrap -- */
.carousel-inner {
    min-height: 270px;
    height: auto;
}
.carousel-indicators {
    margin-left: 40% !important;
    position: relative !important;
    border: none !important;
    margin-bottom: -71px;
    margin-top: 50px;

    /*width: 180% !important;*/
    left: 0% !important;
}
.carousel-indicators .active {
    width: 13px;
    height: 13px;
    margin: 0;
    background-color: #000;
}
.nav-tabs > li {
    float: none !important;
    margin-bottom: -2px;
    display: inline-flex !important;
}

/* -- .Bootstrap -- */

/* --- .enquetes --- */
body {
    font-family: 'Roboto', sans-serif !important;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    padding: 0px !important;
    background: rgba(0, 0, 0, 0.05);
    /* NEO */
    background: rgb(234, 234, 237) !important;

    /* DARK */
    /* background: rgb(46, 46, 46) !important; 
     color: rgba(var(--branco),1) !important; */
}
body:before {
    /* sombra top */
    content: '';
    position: fixed;
    top: -10px;
    left: -10px;
    width: 110%;
    height: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 999;
    font-size: 14px !important;
}
body:after {
    /* sombra rodapé */
    content: '';
    position: fixed;
    bottom: -10px;
    left: -10px;
    width: 110%;
    height: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 999;
}

/* --- fontes e tamanhos --- */
.fpergamum {
    font-family: 'Roboto', sans-serif !important;
}
.s9 {
    font-size: 9px !important;
}
.s10 {
    font-size: 10px !important;
}
.s11 {
    font-size: 11px !important;
}
.s12 {
    font-size: 12px !important;
}
.s13 {
    font-size: 13px !important;
}
.s14 {
    font-size: 14px !important;
}
.s15 {
    font-size: 15px !important;
}
.s16 {
    font-size: 16px !important;
}
.s17 {
    font-size: 17px !important;
}
.s18 {
    font-size: 18px !important;
}
.s19 {
    font-size: 19px !important;
}
.s20 {
    font-size: 20px !important;
}
.s21 {
    font-size: 21px !important;
}
.s22 {
    font-size: 22px !important;
}
.s23 {
    font-size: 23px !important;
}
.s24 {
    font-size: 24px !important;
}
.s25 {
    font-size: 25px !important;
}
.s30 {
    font-size: 30px !important;
}
.s35 {
    font-size: 35px !important;
}
.s40 {
    font-size: 40px !important;
}
.s50 {
    font-size: 50px !important;
}
.s60 {
    font-size: 60px !important;
}
.s70 {
    font-size: 70px !important;
}
.s80 {
    font-size: 80px !important;
}
.s90 {
    font-size: 90px !important;
}
.s100 {
    font-size: 100px !important;
}
.s110 {
    font-size: 110px !important;
}
.s120 {
    font-size: 120px !important;
}
.s130 {
    font-size: 130px !important;
}
.s140 {
    font-size: 140px !important;
}
.s150 {
    font-size: 150px !important;
}

.smenu {
    font-size: 30px !important;
}
.tiny {
    font-size: 1rem !important;
}
.small {
    font-size: 2rem !important;
}
.medium {
    font-size: 3rem !important;
}
.large {
    font-size: 6rem !important;
}

/* --- cores --- */
.azul {
    color: #007bff !important;
}
.azul1 {
    color: #b0c8e0 !important;
}
.azul_celeste {
    color: #04c6bc !important;
}
.btnSearch,
.verde {
    color: #419f42 !important;
}
.verde1 {
    color: #b3ccb3 !important;
}
.vermelho {
    color: #f00 !important;
}
.btnVermelho,
.vermelho1 {
    color: #c39292 !important;
}
.laranja {
    color: #ff7f24 !important;
}
.laranja1 {
    color: #f5ad7a !important;
}
.roxo {
    color: #8a2be2 !important;
}
.preto {
    color: #000000 !important;
}
.apergamum {
    color: #003366 !important;
}
.hover3:hover,
.hover2:hover,
.btnTema:hover,
.btnBranco,
.branco,
.linhaMenu:hover,
.branco {
    color: rgba(255, 255, 255, 1) !important;
}
.branco10 {
    color: rgba(255, 255, 255, 0.1) !important;
}
.branco20 {
    color: rgba(255, 255, 255, 0.2) !important;
}
.branco30 {
    color: rgba(255, 255, 255, 0.3) !important;
}
.branco40 {
    color: rgba(255, 255, 255, 0.4) !important;
}
.branco50 {
    color: rgba(255, 255, 255, 0.5) !important;
}
.branco60 {
    color: rgba(255, 255, 255, 0.6) !important;
}
.branco70 {
    color: rgba(255, 255, 255, 0.7) !important;
}
.branco80 {
    color: rgba(255, 255, 255, 0.8) !important;
}
.branco90 {
    color: rgba(255, 255, 255, 0.9) !important;
}
.cinza {
    color: #c0c0c0 !important;
}
.azul_celeste1 {
    color: #a2b3e6 !important;
}
.magenta {
    color: #c609cc !important;
}
.preto10 {
    color: rgba(0, 0, 0, 0.1) !important;
}
.preto20 {
    color: rgba(0, 0, 0, 0.2) !important;
}
.preto30 {
    color: rgba(0, 0, 0, 0.3) !important;
}
.preto40 {
    color: rgba(0, 0, 0, 0.4) !important;
}
.preto50 {
    color: rgba(0, 0, 0, 0.5) !important;
}
.preto60 {
    color: rgba(0, 0, 0, 0.6) !important;
}
.preto70 {
    color: rgba(0, 0, 0, 0.7) !important;
}
.preto80 {
    color: rgba(0, 0, 0, 0.8) !important;
}
.preto90 {
    color: rgba(0, 0, 0, 0.9) !important;
}
.pergamum-text {
    color: rgba(0, 63, 98, 1) !important;
}
.pergamum-80-text {
    color: rgba(0, 63, 98, 0.8) !important;
}
.pergamum-50-text {
    color: rgba(0, 63, 98, 0.5) !important;
}
.pergamum-10-text {
    color: rgba(0, 63, 98, 0.1) !important;
}

/* --- background --- */
.bkazul {
    background-color: #3399ff !important;
}
.bkazul1 {
    background-color: #b0c8e0 !important;
}
.bkverde {
    background-color: #419f42 !important;
}
.bkverde1 {
    background-color: #b3ccb3 !important;
}
.bkvermelho {
    background-color: #f00 !important;
}
.bkvermelho1 {
    background-color: #c39292 !important;
}
.bkvermelho2 {
    background-color: #d9534f !important;
}
.bklaranja {
    background-color: #ff7f24 !important;
}
.bklaranja1 {
    background-color: #f5ad7a !important;
}
.bkroxo {
    background-color: #8a2be2 !important;
}
.bkpreto {
    background-color: #000000 !important;
}
.bkamarelo {
    background-color: #fdc00d !important;
}
.bkpergamum {
    background-color: #00476b !important;
}
.bkbranco {
    background-color: #fff !important;
}
.bkcinza {
    background-color: #c0c0c0 !important;
}
.bkcinza2 {
    background-color: rgba(192, 192, 192, 0.2) !important;
}
.bkcinza3 {
    background-color: #525252 !important;
}
.bkazul_celeste {
    background-color: #04c6bc !important;
}
.bkmagenta {
    background-color: #c609cc !important;
}
.bkpreto {
    background-color: #000 !important;
}
.bkazulclaro1 {
    background-color: #f1f5fb !important;
}
.bkamarelo {
    background-color: #f0ad4e !important;
}

/* -- sombras -- */
.sombraPadrao {
    box-shadow: 0px 0px 14px 1px rgba(109, 109, 109, 0.3);
}
.sombra {
    box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
}
.sombra2 {
    box-shadow: 14px 1px 20px 0px rgba(0, 0, 0, 0.4);
}
/*.sverde	    	{	    box-shadow: 1px 6px 10px 1px rgba(65, 159, 66, 0.4);	    }*/
/*.sazul	    	{	    box-shadow: 1px 6px 10px 1px rgba(51, 153, 255, 0.4);	    }*/
.sroxo {
    box-shadow: 0 14px 26px -12px rgba(156, 39, 176, 0.42),
        0 4px 23px 0px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(156, 39, 176, 0.2);
}
.scinza {
    box-shadow: 0 14px 26px -12px rgba(208, 208, 208, 0.42),
        0 4px 23px 0px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(210, 210, 210, 0.2);
}
.sazul {
    box-shadow: 0 12px 20px -10px rgba(0, 188, 212, 0.28),
        0 4px 23px 0px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(0, 188, 212, 0.2);
}
.svermelho {
    box-shadow: 0 12px 20px -10px rgba(244, 67, 54, 0.28),
        0 4px 23px 0px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(244, 67, 54, 0.2);
}
.sverde {
    box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28),
        0 4px 23px 0px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(76, 175, 80, 0.2);
}
.slaranja {
    box-shadow: 0 12px 20px -10px rgba(255, 152, 0, 0.28),
        0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(255, 152, 0, 0.2);
}
/* --- .sombras --- */

/* --- opacidade --- */
.op00 {
    opacity: 0;
}
.op05 {
    opacity: 0.5;
}
.op07 {
    opacity: 0.7;
}
.op10 {
    opacity: 1;
}
/* --- .opacidade --- */

/* --- hover --- */
.hover .svg-inline--fa {
    transition: All 0.2s ease;
}

.hover:hover .svg-inline--fa,
.hover:hover .svg-inline--fa {
    transform: rotateY(360deg) !important;
    color: #fff !important;
}

.hoverSimples .svg-inline--fa {
    transition: All 0.2s ease;
}
.hoverSimples:hover .svg-inline--fa,
.hoverSimples:hover .svg-inline--fa {
    color: #fff !important;
}

.z-checkbox .z-checkbox-content:hover,
.hoverSimples:hover,
.hover:hover {
    color: #fff !important;
    -webkit-transition: All 0.5s ease;
    transition: All 0.4s ease;
}
.hazul:hover {
    background-color: #3399ff !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hazul1:hover {
    background-color: #b0c8e0 !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hverde:hover {
    background-color: #419f42 !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hverde1:hover {
    background-color: #b3ccb3 !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hvermelho:hover {
    background-color: #f00 !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hvermelho1:hover {
    background-color: #c39292 !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hvermelho2:hover {
    background-color: #d9534f !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hlaranja:hover {
    background-color: #ff7f24 !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hlaranja1:hover {
    background-color: #f5ad7a !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hroxo:hover {
    background-color: #8a2be2 !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hpreto:hover {
    background-color: #000000 !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hamarelo:hover {
    background-color: #fdc00d !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hpergamum:hover {
    background-color: #003366 !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hbranco:hover {
    background-color: #fff !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hcinza:hover {
    background-color: #c0c0c0 !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hcinza2:hover {
    background-color: rgba(192, 192, 192, 0.2) !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hcinza3:hover {
    background-color: #525252 !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hazul_celeste:hover {
    background-color: #04c6bc !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hmagenta:hover {
    background-color: #c609cc !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hpreto:hover {
    background-color: #000 !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hazulclaro1:hover {
    background-color: #f1f5fb !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
.hamarelo:hover {
    background-color: #f0ad4e !important;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
}
/* --- .hover --- */

.hover3:hover,
.btnTema,
.btnTema:hover {
    transition: All 0.4s ease;
}
.btnall {
    box-shadow: 600px 0px 0px 0px rgba(0, 0, 0, 0.2) inset;
    border: none;
    transition: All 0.4s ease;
}
.btnall:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 1) inset;
    border: none;
}

.btnSearch,
.btnClose,
.btnVermelho,
.btnBranco,
.btnAzul {
    width: 35px;
    height: 35px;
}
.btnSearch:hover {
    color: #fff !important;
    background: #01ad4f !important;
}
.btnVermelho:hover,
.btnClose:hover {
    color: #fff !important;
    background: #c00 !important;
}
.btnBranco:hover,
.btnAzul:hover {
    color: #fff !important;
    background: #8080ff !important;
}

.btnFormula {
    position: absolute;
    margin-top: 50px;
    margin-left: -26px;
    opacity: 0.1;
    cursor: pointer;
}
.btnFormula:hover,
.btnFormula2:hover {
    opacity: 1;
}
.btnFormula2 {
    position: absolute;
    margin-top: 0px;
    margin-left: -15px;
    opacity: 0.1;
    cursor: pointer;
}
.bkbrancodegrade {
    background: url('../../images/fundo_degrade_branco.png') top center repeat-x;
}
.bkbimgUser {
    /* background: url("../img/img_login_001.jpg") no-repeat #fff; */
    background-size: 100%;
}
.imgGerenciadorEnquete {
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 120px;
}

.pergamum {
    background-color: rgba(0, 63, 98, 1) !important;
}
.pergamum-light90 {
    background-color: rgba(0, 63, 98, 0.9) !important;
}
.pergamum-light80 {
    background-color: rgba(0, 63, 98, 0.8) !important;
}
.pergamum-light70 {
    background-color: rgba(0, 63, 98, 0.7) !important;
}
.pergamum-light60 {
    background-color: rgba(0, 63, 98, 0.6) !important;
}
.pergamum-light50 {
    background-color: rgba(0, 63, 98, 0.5) !important;
}
.pergamum-light40 {
    background-color: rgba(0, 63, 98, 0.4) !important;
}
.pergamum-light30 {
    background-color: rgba(0, 63, 98, 0.3) !important;
}
.pergamum-light20 {
    background-color: rgba(0, 63, 98, 0.2) !important;
}
.pergamum-light10 {
    background-color: rgba(0, 63, 98, 0.1) !important;
}
.pergamum-light05 {
    background-color: rgba(0, 63, 98, 0.05) !important;
}
.bkdark5 {
    background-color: rgba(0, 0, 0, 0.05);
}
.bkdark10 {
    background-color: rgba(0, 0, 0, 0.1);
}
.bkdark20 {
    background-color: rgba(0, 0, 0, 0.2);
}
.bkdark30 {
    background-color: rgba(0, 0, 0, 0.3);
}
.bkdark40 {
    background-color: rgba(0, 0, 0, 0.4);
}
.bkdark50 {
    background-color: rgba(0, 0, 0, 0.5);
}
.bkdark60 {
    background-color: rgba(0, 0, 0, 0.6);
}
.bkdark70 {
    background-color: rgba(0, 0, 0, 0.7);
}
.bkdark70_0 {
    background-color: rgba(0, 0, 0, 0.7);
}
.bkdark80 {
    background-color: rgba(0, 0, 0, 0.8);
}
.bkdark90 {
    background-color: rgba(0, 0, 0, 0.9);
}
.bklight10 {
    background-color: rgba(255, 255, 255, 0.1);
}
.bklight20 {
    background-color: rgba(255, 255, 255, 0.2);
}
.bklight30 {
    background-color: rgba(255, 255, 255, 0.3);
}
.bklight40 {
    background-color: rgba(255, 255, 255, 0.4);
}
.bklight50 {
    background-color: rgba(255, 255, 255, 0.5);
}
.bklight60 {
    background-color: rgba(255, 255, 255, 0.6);
}
.bklight70 {
    background-color: rgba(255, 255, 255, 0.7);
}
.bklight80 {
    background-color: rgba(255, 255, 255, 0.8);
}
.bklight90 {
    background-color: rgba(255, 255, 255, 0.9);
}
.bkzero {
    background-color: transparent !important;
}

.img_Inicial_filter {
    background-position: center;
    /*background-size: 100% auto;*/
    background-size: cover;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    height: 100vh;
}

/* --- cores para bordas --- */
.b-azul {
    border-color: #3399ff !important;
}
.b-verde {
    border-color: #419f42 !important;
}
.b-vermelho {
    border-color: #f00 !important;
}
.b-laranja {
    border-color: #ff7f24 !important;
}
.b-roxo {
    border-color: #8a2be2 !important;
}
.b-preto {
    border-color: #000000 !important;
}
.b-branco {
    border-color: #fff !important;
}
.b-branco20 {
    border-color: rgba(255, 255, 255, 0.2) !important;
}
.b-branco30 {
    border-color: rgba(255, 255, 255, 0.3) !important;
}
.b-branco50 {
    border-color: rgba(255, 255, 255, 0.5) !important;
}
.b-branco70 {
    border-color: rgba(255, 255, 255, 0.7) !important;
}
.b-azul-claro {
    border-color: #a4d1ff !important;
}
.b-azul01 {
    border-color: #8fcbdf !important;
}
.b-cinza {
    border-color: #aeaeae !important;
}
.b-cinza2 {
    border-color: #dddfe6 !important;
}
.b-verde-tiffany {
    border-color: #16e4e4 !important;
}

.b-solid {
    border-style: solid;
}
.b-dotted {
    border-style: dotted;
}
.b-dashed {
    border-style: dashed;
}

.b-solid-bottom {
    border-style: none none solid none;
}
.b-dotted-bottom {
    border-style: none none dotted none;
}
.b-dashed-bottom {
    border-style: none none dashed none;
}
.b-solid-top {
    border-style: solid none none none;
}
.b-dotted-top {
    border-style: dotted none none none;
}
.b-dashed-top {
    border-style: dashed none none none;
}
.b-solid-left {
    border-style: none none none solid;
}
.b-dotted-left {
    border-style: none none none dotted;
}
.b-dashed-left {
    border-style: none none none dashed;
}
.b-solid-right {
    border-style: none solid none none;
}
.b-dotted-right {
    border-style: none dotted none none;
}
.b-dashed-right {
    border-style: none dashed none none;
}

.b-w1 {
    border-width: 1px !important;
}
.b-w2 {
    border-width: 2px !important;
}
.b-w3 {
    border-width: 3px !important;
}
.b-w4 {
    border-width: 4px !important;
}
.b-w5 {
    border-width: 5px !important;
}
.b-w10 {
    border-width: 10px !important;
}
.b-w20 {
    border-width: 20px !important;
}
.b-w30 {
    border-width: 30px !important;
}
.b-w40 {
    border-width: 40px !important;
}
.b-w50 {
    border-width: 50px !important;
}
.b-w100 {
    border-width: 100px !important;
}

.b-none,
.b-none:hover {
    border: none !important;
}

/* --- text align	--- */
.txt-center {
    text-align: center;
}
.txt-left {
    text-align: left;
}
.txt-right {
    text-align: right;
}
.txt-justify {
    text-align: justify;
}
.align-RL {
    text-align: right;
}
.align-CL {
    text-align: center;
}
.l5 {
    margin-left: 5px;
}
.l10 {
    margin-left: 10px;
}
.l20 {
    margin-left: 20px;
}
.l30 {
    margin-left: 30px;
}
.l40 {
    margin-left: 40px;
}
.r5 {
    margin-right: 5px;
}
.r10 {
    margin-right: 10px;
}
.r20 {
    margin-right: 20px;
}
.r30 {
    margin-right: 30px;
}
.t5 {
    margin-top: 5px;
}
.t-15 {
    margin-top: -15px;
}
.t-10 {
    margin-top: -10px;
}
.t-5 {
    margin-top: -5px;
}
.t10 {
    margin-top: 10px;
}
.t20 {
    margin-top: 20px;
}
.t30 {
    margin-top: 30px;
}
.t100 {
    margin-top: 100px;
}
.b5 {
    margin-bottom: 5px;
}
.b10 {
    margin-bottom: 10px;
}
.b20 {
    margin-bottom: 20px;
}
.b30 {
    margin-bottom: 30px;
}

/* --- padding geral 4 lados --- */
.pad0 {
    padding: 0px !important;
}
.pad2 {
    padding: 2px !important;
}
.pad5 {
    padding: 5px !important;
}
.pad10 {
    padding: 10px !important;
}
.pad15 {
    padding: 15px !important;
}
.pad20 {
    padding: 20px !important;
}
.pad30 {
    padding: 30px !important;
}
.pad50 {
    padding: 50px !important;
}
.padUnico1 {
    padding: 15px 15px 15px 30px;
}

/* --- estilos da font --- */
.hover2:hover,
.negrito {
    font-weight: bold;
}
.light {
    font-weight: lighter;
}
.italico {
    font-style: italic;
}
.f100 {
    font-weight: 100 !important;
}
.f300 {
    font-weight: 300 !important;
}
.f400 {
    font-weight: 400 !important;
}
.f500 {
    font-weight: 500 !important;
}
.f700 {
    font-weight: 700 !important;
}
.f900 {
    font-weight: 900 !important;
}

/* --- margin --- */
.marg5LR {
    margin: 0px 5px;
}

/* --- cursores --- */
.click {
    cursor: pointer;
}
.noclick {
    cursor: not-allowed;
}

/* --- Login --- */

/* --- espaçamento --- */
.space-5 {
    width: 10px;
    display: inline-block;
}
.space-10 {
    width: 10px;
    display: inline-block;
}
.space-20 {
    width: 20px;
    display: inline-block;
}
.space-30 {
    width: 30px;
    display: inline-block;
}
.inline {
    display: inline !important;
}
.h-MeuPergamum {
    min-height: calc(100vh - 155px);
}
.cor {
    width: 30px;
    height: 30px;
    position: relative;
    margin: auto;
    padding-top: 2px;
}
.cor2 {
    width: 30px;
    height: 30px;
    position: relative;
    float: left;
    padding-top: 2px;
}
.corVarias {
    background: -moz-linear-gradient(
        -45deg,
        #ffe732 0%,
        #ffe732 20%,
        #3037ff 21%,
        #3037ff 40%,
        #3037ff 40%,
        #ff8228 41%,
        #ff8228 41%,
        #ff8228 60%,
        #7728ff 61%,
        #7728ff 80%,
        #ff2828 81%,
        #ff2828 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #ffe732 0%,
        #ffe732 20%,
        #3037ff 21%,
        #3037ff 40%,
        #3037ff 40%,
        #ff8228 41%,
        #ff8228 41%,
        #ff8228 60%,
        #7728ff 61%,
        #7728ff 80%,
        #ff2828 81%,
        #ff2828 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #ffe732 0%,
        #ffe732 20%,
        #3037ff 21%,
        #3037ff 40%,
        #3037ff 40%,
        #ff8228 41%,
        #ff8228 41%,
        #ff8228 60%,
        #7728ff 61%,
        #7728ff 80%,
        #ff2828 81%,
        #ff2828 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffe732', endColorstr='#ff2828',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

/* -- Geral -- */
.erro_404 {
    /* background-image: url("../img/erro_404.jpg"); */
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
}
.dinamico {
    display: none;
}
.dinamicoInverso {
    display: block;
}
.btnAction .action {
    opacity: 0.5;
}
.btnAction .action:hover {
    opacity: 1;
}
.areaCadastro {
    height: 72vh;
    margin: -5px 0px -5px -5px;
    box-shadow: 0px 0px 10px 5px rgba(109, 109, 109, 0.2);
    overflow-y: scroll;
    overflow-x: hidden;
}
.imgLogin {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    background-attachment: fixed;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    animation: imgsLogin 15s linear 2s infinite alternate;
    -moz-animation: imgsLogin 15s linear 2s infinite alternate;
    -webkit-animation: imgsLogin 15s linear 2s infinite alternate;
}
.posicaoSeta {
    height: 30px;
    width: 7px;
    position: absolute;
    border-right: none;
    border-top: none;
    margin-left: 19px;
    margin-top: -2px;
}
.corFundo,
.corFundo2 {
    position: fixed;
    top: 40px;
    bottom: 0px;
    right: 2%;
    min-width: 300px;
    width: 30%;
    /*overflow: auto;*/
}
.posicaoCadeado {
    position: relative;
    top: -45px;
    left: 3px;
}

.progress {
    height: 11px !important;
    margin-bottom: 5px !important;
    background-color: rgba(199, 199, 199, 0.46) !important;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) !important;
    box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.32) !important;
}

.w90 {
    width: 90% !important;
}
.w80p {
    width: 80px !important;
}
.w160p {
    width: 160px !important;
}

.detTemTit {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: -5px;
}
.container-fluid1 {
    margin-left: 300px;
    min-height: 570px !important;
    box-shadow: 3px 5px 6px 8px rgba(25, 25, 25, 0.2);
    margin-bottom: 20px;
    z-index: 1;
    /*margin-top: -15px;*/
}
.container-fluid2 {
    margin-right: 300px;
    min-height: 570px !important;
    box-shadow: 3px 5px 6px 8px rgba(25, 25, 25, 0.2);
    margin-bottom: 20px;
    margin-top: -15px;
}
.menu1 {
    position: absolute;
    width: 290px;
    left: 1x;
    margin-top: 0px;
}
.menu2 {
    position: absolute;
    width: 285px;
    right: 1px;
    margin-top: 0px;
}
.linhaMenu {
    border-top: 1px solid #757575;
    border-bottom: 1px solid #000;
}
.linhaMenu:hover {
    border-top: 1px solid #757575;
    border-right: 3px solid #fff;
}
.cxportal {
    box-shadow: 0px 0px 8px 3px rgba(177, 177, 177, 0.5);
    position: relative;
    overflow-y: auto;
    height: 400px;
}
.cxportal2 {
    box-shadow: 12px 12px 6px 2px rgba(0, 0, 0, 0.3);
    position: relative;
    margin-bottom: 10px;
}
.cxportal3 {
    box-shadow: 6px 6px 6px -1px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-bottom: 4px;
}
.cxportal3_icones {
    position: absolute;
    width: 40px;
    margin-top: 22px;
    display: none;
}
.telaMeio {
    position: fixed;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
    overflow-y: auto;
}
.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.abasMontagemEnquete {
    position: absolute;
    margin-left: -30px;
    margin-top: 20px;
}

.divGeral1 {
    position: fixed;
    overflow: auto;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 50;
    opacity: 0;
    height: 0vh;
}
.divGeral2 {
    position: fixed;
    overflow: auto;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 50;
    opacity: 1;
    height: 100vh;
}
.meio {
    margin: 30px 60px 30px 30px;
    height: calc(100vh - 60px);
    opacity: 1;
    overflow-y: auto;
}
.meio2 {
    margin: 30px 60px 30px 30px;
    height: calc(100vh - 60px);
    opacity: 0;
    overflow-y: auto;
}
.visualizar1 {
    opacity: 0;
    /*position: absolute;
          right: 10px;
          left: 4%;*/
    height: 1px;
    width: 0px;
    overflow: hidden;
}
.visualizar2 {
    opacity: 1;
    /*position: absolute;
          right: 10px;
          margin-left: -500%;*/
}
.roda180 {
    transform: rotate(180deg);
}
.idoculta {
    height: 0px;
    overflow: hidden;
    opacity: 0;
    width: 0px;
}
.idResultado {
    height: 0px;
    overflow: hidden;
    opacity: 0;
}
.divResumoNota1 {
    height: 100px;
    overflow: hidden;
}
.OcultarMostrar {
    opacity: 0;
    width: 0px;
    overflow: hidden;
}

.loginlock {
    opacity: 0;
    height: 0px;
    overflow: hidden;
    /*width: 0px;*/
}
.loginLogin,
.loginUnlock {
    opacity: 0;
    height: 0px;
    overflow: hidden;
    width: 0px;
}
.idInicial3 {
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;
    opacity: 0 !important;
    width: 0% !important;
    height: 0vh !important;
}
.idInicial2 {
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;
    opacity: 1 !important;
    width: 100% !important;
    height: 100vh !important;
    animation: inicioTela 6s linear !important;
}
.idInicial {
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
}

/* --- CAROUSEL MANUAL --- */
.carouselManual {
    width: 200%;
    overflow-x: auto;
    overflow-y: hidden;
}

.carouselM a:link,
.carouselM a:hover,
.carouselM a:active,
.carouselM a:visited {
    transition: color 150ms;
    color: #95a5a6;
    text-decoration: none;
}

.carouselM a:hover {
    color: #7f8c8d;
    text-decoration: underline;
}
/* --- .CAROUSEL MANUAL --- */

@keyframes inicioTela {
    0% {
    }
    80% {
        opacity: 1;
        width: 100%;
        height: 100vh;
    }
    95% {
        opacity: 0;
        width: 100%;
        height: 100vh;
    }
    100% {
        opacity: 0;
        width: 0%;
        height: 0vh;
    }
}
/* -- .Animation -- */

.video {
    width: 100%;
    height: auto;
}

/* -- Cursor -- */
.hover1,
.hover2,
.hover3,
.cursor {
    cursor: pointer;
}

/* --- link --- */
.hover3:hover,
.nolink,
.nolink:hover {
    text-decoration: none !important;
}

.link,
.link:hover {
    text-decoration: underline !important;
}

/* --- bordas arredondadas --- */
.rad30 {
    border-radius: 30px !important;
}
.rad20 {
    border-radius: 20px !important;
}
.rad15 {
    border-radius: 15px !important;
}
.rad10 {
    border-radius: 10px !important;
}
.rad5 {
    border-radius: 5px !important;
}
.rad3 {
    border-radius: 3px !important;
}
.rad2 {
    border-radius: 2px !important;
}
.rad1 {
    border-radius: 1px !important;
}
.rad20p {
    border-radius: 20% !important;
}
.rad50p {
    border-radius: 50% !important;
}

/* --- botões --- */
.btIcoCamera {
    position: absolute;
    margin-top: -51px;
    margin-left: 30%;
    border-radius: 50%;
    padding: 2px;
}
.btIcoImg {
    margin: 5px 5px 0px 0px;
    float: right;
}
.org {
    width: 50px;
    height: 50px;
    margin: 10px;
}
.imgTemaSelect {
    box-sizing: border-box;
    outline-offset: -5px;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
    border-radius: 5px;
    border: 10px solid #fff;
    padding: 5px;
}
.imgTemaSelectactive {
    box-sizing: border-box;
    outline-offset: -5px;
    -webkit-transition: All 0.2s ease;
    transition: All 0.2s ease;
    border-radius: 5px;
    border: 10px solid rgba(80, 154, 24, 0.5);
}
.imgTemaSelect:hover {
    outline-offset: -20px;
    outline: 20px solid rgba(255, 255, 255, 0.5);
}
.bandboxCombo i:before {
    content: '\f0dd';
}
.resultadoImagem {
    width: 150px;
    height: 150px;
}
.resultadoImagem2 {
    width: 100px;
    height: 100px;
}
.imagemVitrine {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
}
.alturaTitulo {
    height: 60px;
    overflow: hidden;
}
.alturaMeuPergamumMin {
    min-height: 400px;
}

/* -- button ON	-- */

/* -- efeito de tabela -- */
.tabela .row {
    padding: 8px;
}

.tabela {
    font-size: 14px;
}

.tabela .row:nth-child(1) {
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
.tabela .row:nth-child(odd) {
    background-color: transparent;
}
.tabela .row:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.02);
}
/* -- .efeito de tabela -- */

/* -- colaboracao de Guilherme Alceu Xavier da Silva -- */
.switch {
    font: normal normal normal 18px/1 FontAwesome;
    color: #fff;
}

.switch > input[type='checkbox'] {
    display: none;
}

.switch > input[type='checkbox'] ~ label {
    box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.34) inset;
    border-radius: 20px;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
}

.switch > input[type='checkbox']:not(:checked) ~ label {
    padding: 2px 2px 2px 18px;
    background-color: #c0c0c0;
}

.switch > input[type='checkbox']:not(:checked) ~ label:after {
    content: 'off \f111';
}

.switch > input[type='checkbox']:checked ~ label {
    padding: 2px 20px 2px 2px;
    background-color: #00bb2f;
}

.switch > input[type='checkbox']:checked ~ label:before {
    content: '\f111   on';
}
/* -- .colaboracao de Guilherme Alceu Xavier da Silva -- */
.on {
    transition: All 0.1s ease;
    background: #fff;
    padding: 2px 20px 2px 2px;
    background-color: #00bb2f;
    box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.34) inset;
}
.on:before {
    content: '\f111';
}
.on:after {
    content: ' on';
}
.on:hover {
    padding: 2px 2px 2px 18px;
    background-color: #c0c0c0;
}
.on:hover:after {
    content: ' \f111';
}
.on:hover:before {
    content: 'off';
}
/* -- .button ON	-- */

/* -- button OFF	-- */
.off {
    transition: All 0.1s ease;
    background: #fff;
    padding: 2px 2px 2px 18px;
    background-color: #c0c0c0;
    box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.34) inset;
}
.off:after {
    content: ' \f111';
}
.off:before {
    content: 'off';
}
.off:hover {
    padding: 2px 20px 2px 2px;
    background-color: #00bb2f;
}
.off:hover:after {
    content: ' on';
}
.off:hover:before {
    content: ' \f111';
}
/* -- .button OFF	-- */

.btnMenuAdm {
    display: inline-block;
    min-height: 70px;
    width: 120px;
    margin: 2px;
    padding-top: 20px;
}

.tag {
    padding: 2px 12px 2px 10px;
    border: 1px solid #aaa9c0;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    position: relative;
    margin: 4px 8px 4px 7px;
    text-decoration: none;
    background: #aaa9c0;
    cursor: pointer;
    transition: All 0.2s ease;
}
.tag:after {
    display: inline-block;
    border: 12px solid;
    border-color: transparent transparent transparent #aaa9c0;
    height: 0;
    width: 0;
    position: absolute;
    right: -22px;
    top: 0;
    content: '';
    display: inline-block;
    transition: All 0.2s ease;
}
.tag:hover {
    border: 1px solid #bb0000;
    background: #bb0000;
}
.tag:hover:after {
    border-color: transparent transparent transparent #bb0000;
}

.tag5 {
    padding: 2px 12px 2px 10px;
    /*border: 1px solid #aaa9c0;*/
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    position: relative;
    margin: 4px 8px 4px 7px;
    text-decoration: none;
    background: rgba(85, 87, 88, 0.7);
    cursor: pointer;
    transition: All 0.2s ease;
}
.tag5:hover {
    /*border: 1px solid #bb0000;*/
    background: #bb0000;
}

.tag2,
.tag3 {
    padding: 5px 10px 5px 10px;
    /*border: 1px solid #aaa9c0;*/
    border-radius: 5px;
    display: inline-block;
    /*color: #fff;*/
    position: relative;
    margin: 4px 7px 4px 7px;
    text-decoration: none;
    /*background: #aaa9c0;*/
    cursor: pointer;
    transition: All 0.2s ease;
}
.tag3 {
    background: transparent;
    border-bottom: 1px dotted #a6a6a6;
    border-radius: 0px !important;
    color: #8b8b8b;
}
.tag2:after,
.tag3:after {
    display: inline-block;
    border: 5px solid;
    height: 0;
    width: 0;
    position: absolute;
    top: 27px;
    content: '';
    display: inline-block;
    transition: All 0.2s ease;
    left: calc(50% - 4px);
}
.tag3:after {
    border-color: transparent transparent transparent transparent;
}
.tag3:hover {
    /*border: 1px solid #000;*/
    background: #8b8b8b;
    color: #fff;
    border-radius: 5px !important;
}
.tag3:hover:after {
    border-color: #8b8b8b transparent transparent transparent;
}

input.verify-code {
    display: block !important;
    border: none !important;
    padding: 0 !important;
    width: 10.5ch !important;
    background: repeating-linear-gradient(
            90deg,
            dimgrey 0,
            dimgrey 1ch,
            transparent 0,
            transparent 1.5ch
        )
        0 100%/ 10ch 2px no-repeat !important;
    font: 5ch droid sans mono, consolas, monospace !important;
    letter-spacing: 0.5ch !important;
    box-shadow: none !important;
}
input.verify-code:focus {
    outline: none !important;
    color: dodgerblue !important;
}

/* -- caracteres especiais -- */
.umaLinha3p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.barraInvertida:before {
    content: ' / ';
    color: #3399ff;
    font-weight: bold;
    font-size: 15px;
}
.setaDupla:before {
    content: ' » ';
    color: #3399ff;
    font-weight: bold;
    font-size: 15px;
}
.totalFiltro:before {
    content: ' ( ';
}
.totalFiltro:after {
    content: ' ) ';
}

.oculta {
    display: none;
}
.hackMeuPergamum01 {
    margin-bottom: -310px;
}
.meioMenu {
    opacity: 1;
    margin-left: 0%;
}
.meioMenu2 {
    opacity: 0;
    margin-left: -50%;
}
.pagination {
    justify-content: flex-start !important;
}

/* MODAL */
.modal-title {
    border-radius: 5px;
    background-image: url(../../images/montanha.png),
        url(../../images/card_tema.svg);
    background-position: center;
    background-size: cover;
    padding: 10px;
}
/* .MODAL */
.bkmontanha {
    background-image: url(../../images/montanha.png),
        url(../../images/card_tema.svg);
    background-size: cover;
    background-position: center;
}

/* ---------------------------------- CONTRASTE ------------------------------------------------- */

/* -- VARIAVEIS -- */
:root {
    --altoBrancoAAA: #fafafa;
    --altoBrancoAA: rgba(255, 255, 255, 0.9);
    --altoBrancoA: rgba(255, 255, 255, 0.7);
    --altoBrancoa: rgba(255, 255, 255, 0.5);
    --BrancoRGB: 255, 255, 255;

    --altoPretoAAA: #100f10;
    --altoPretoAA: #1b1b1b;
    --altoPretoA: #242424;

    --altoCinza: #100f10;

    --altoVermelho: #ff0043;
    --altoVerde: #58ee5a;
    --altoAzul: #99ccff;
    --altoAmarelo: #fff099;
    --altoLaranja: #ffcb20;
}
/* -- .VARIAVEIS -- */

.contraste .img_logo {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.contraste .img_topo_biblioteca {
    background-image: none !important;
    background-color: var(--altoPretoA) !important;
}

.contraste .b-tema20.form-control,
.contraste .form-control {
    background-color: var(--altoPretoAAA) !important;
    border: 1px solid var(--altoBrancoAA) !important;
}

.contraste .b-tema {
    border: 1px solid var(--altoBrancoAA) !important;
}

.contraste footer,
.contraste .bkbranco {
    background-color: var(--altoPretoA) !important;
}

.contraste .form-control,
.contraste .active.bktema,
.contraste .cortxt.form-control,
.contraste .cortxt {
    color: var(--altoBrancoAAA) !important;
}

.contraste .hover {
    border: 2px solid var(--altoBrancoa) !important;
    color: var(--altoPretoAAA) !important;
}

.contraste .btnPesquisa1.branco,
.contraste .active.bktema,
.contraste button.branco {
    color: var(--altoPretoAAA) !important;
}

.contraste .active.bktema {
    background-color: var(--altoBrancoAAA) !important;
}
.contraste .bkverde {
    background-color: var(--altoVerde) !important;
}
.contraste .bkazul {
    background-color: var(--altoAzul) !important;
}
.contraste .verde {
    color: var(--altoVerde) !important;
}
.contraste.temaAltoContraste .topLinkSpace2:hover {
    background-color: var(--altoVerde) !important;
    color: var(--altoPretoAAA) !important;
}
.contraste.temaAltoContraste .topLinkSpace2:hover .hover1.l10.r5 {
    color: var(--altoPretoAAA) !important;
}
.contraste .cortxt70.b-none.bknone.sombra.bkbranco.btn.btn-secondary,
.contraste .sazul,
.contraste .sverde {
    box-shadow: 0 12px 20px -10px #fff !important;
}
.contraste .azul,
.contraste.temaAltoContraste .azul.link {
    color: var(--altoAzul) !important;
}
.contraste a {
    color: var(--altoAmarelo) !important;
    text-decoration: underline !important;
}
.contraste .azul.link.hover1:hover {
    color: var(--altoBrancoAAA) !important;
}
.contraste .hover1.verde.t5 {
    color: var(--altoVerde) !important;
}
.contraste .verde.t5.hover1:hover {
    color: var(--altoBrancoAAA) !important;
}
.contraste .guia_pesquisa {
    background-color: var(--altoPretoA) !important;
}
.contraste .rad2.pad10.bktema60 {
    background-color: rgba(255, 255, 255, 0.6) !important;
}

.contraste
    .header__search__content
    .search__content__left
    .content__left__header
    label.guia_pesquisa {
    color: var(--altoBrancoAA);
}
.contraste .info__tabs .info__tabs__tabs .nav-item:hover {
    background-color: var(--altoPretoAAA);
    transition: 300ms;
    box-shadow: none;
}

.contraste .content__left__header label.guia_pesquisa.active:after {
    border-color: #fff transparent transparent transparent !important;
    top: 29px !important;
}
.contraste .guia_pesquisa:hover {
    color: var(--altoPretoA);
}
.contraste .guia_pesquisa {
    border: 1px solid var(--altoBrancoAA) !important;
    border-radius: 10px !important;
}
.contraste .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: rgba(var(--BrancoRGB), 1) !important;
    background-color: var(--altoPretoAA) !important;
    border-color: var(--altoPretoAA) !important;
}
.contraste .info__tabs .card__header .card__header__title {
    border-color: var(--altoBrancoAA) !important;
}

.contraste .topLinkSpace2.bklight90 {
    background-color: var(--altoPretoA);
    border-color: var(--altoBrancoAA) !important;
    border-width: 2px;
}

.contraste .op07 {
    opacity: 1;
}
.contraste button.bktema.branco {
    border: 1px solid var(--altoBrancoAA) !important;
    color: var(--altoBrancoA) !important;
}

.contraste .preto10 {
    color: rgba(var(--BrancoRGB), 0.1) !important;
}
.contraste .preto20 {
    color: rgba(var(--BrancoRGB), 0.2) !important;
}

.contraste .cortxt30.s30,
.contraste .preto30 {
    color: rgba(var(--BrancoRGB), 0.3) !important;
}

.contraste .preto40 {
    color: rgba(var(--BrancoRGB), 0.4) !important;
}

.contraste .s15.s30m.cortxt70,
.contraste.temaAltoContraste .cortxt70,
.contraste .cortxt50,
.contraste .preto50 {
    color: rgba(var(--BrancoRGB), 0.7) !important;
}

.contraste .cortxt60.s25,
.contraste .preto60 {
    color: rgba(var(--BrancoRGB), 0.7) !important;
}
.contraste .preto70 {
    color: rgba(var(--BrancoRGB), 0.7) !important;
}

.contraste .s16.cortxt80.italico.f400,
.contraste .cortxt80.corAlto1,
.contraste .preto80 {
    color: rgba(var(--BrancoRGB), 0.8) !important;
}

.contraste .preto90 {
    color: rgba(var(--BrancoRGB), 0.9) !important;
}

.contraste .preto,
.contraste .f500.s15.cortxt,
.contraste .custom-control-label,
.contraste .s13.cortxt.f500,
.contraste .s24.cortxt,
.contraste .s11.negrito.cortxt,
.contraste .cortxt.s15,
.contraste .cortxt.s16,
.contraste .cortxt.s30.f300,
.contraste .s14.click.hover1,
.contraste .corAlto1,
.contraste .cortxt.corAlto1,
.contraste .link.hover1:hover,
body .contraste.temaAltoContraste,
.contraste .m-0.click.link.negrito,
.contraste .preto60.hover1:hover,
.contraste .preto70.hover1:hover,
.contraste .cinza.hover1:hover,
.contraste .cortxt.l20,
.contraste .bkzero.hover1:hover,
.contraste .s16.negrito.preto80.link.hover1:hover,
.contraste .hover1.link,
.contraste .f900.negrito.link.safira-color.text,
.contraste .pad5.s14.d-none.d-lg-block.float-right.cortxt.f900,
.contraste.temaAltoContraste .cortxt {
    color: rgba(var(--BrancoRGB), 1) !important;
}
.contraste .bkazul.branco.sombraPadrao {
    color: var(--altoPretoAAA) !important;
}

.contrasteNao {
    display: inline-block;
}
.contrasteSim {
    display: none;
}
.contraste .contrasteNao {
    display: none;
}
.contraste .contrasteSim {
    display: inline-block;
}

.contraste .modal-title {
    background-color: var(--altoPretoA) !important;
    color: #fff !important;
}
.contraste .modal-header .close {
    margin-top: -54px !important;
    opacity: 1;
    color: #fff !important;
    margin-right: 0px !important;
}

.contraste .icone-header.cortxt {
    background-color: var(--altoPretoA) !important;
    color: rgba(var(--BrancoRGB), 1) !important;
}

.contraste .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: var(--altoLaranja) !important;
    background-color: var(--altoLaranja) !important;
}
.contraste
    .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-image: url('../../images/checkPreto.svg') !important;
}
.contraste
    .custom-radio
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-image: url('../../images/radioPreto.svg') !important;
}
.contraste .custom-control-label::before {
    background-color: var(--altoPretoAAA) !important;
    border: var(--altoBrancoAAA) solid 3px;
}

.contraste .cortxt70.b-none.bknone.sombra.bkbranco.btn.btn-secondary {
    background-color: var(--altoPretoAAA) !important;
    color: rgba(var(--BrancoRGB), 1) !important;
    border: 2px solid var(--altoBrancoAAA) !important;
}

.contraste .consultaContent {
    background-color: var(--altoPretoAA);
}
.contraste .info__tabs,
.contraste .bkbranco {
    background-color: var(--altoPretoA) !important;
}

.contraste .info__tabs .tab-content {
    background-color: var(--altoPretoAA) !important;
}
.contraste .b-cinza {
    border-color: var(--altoBrancoAA) !important;
}
.contraste .page-item {
    background-color: var(--altoPretoA) !important;
}
.contraste .page-link.preto {
    color: var(--altoBrancoAA) !important;
}
.contraste .page-item.bktema .page-link {
    background-color: var(--altoBrancoAAA) !important;
    color: var(--altoPretoA) !important;
}
.contraste .page-link {
    background-color: var(--altoPretoAAA) !important;
}
.contraste .bkpreto.contrasteAlto {
    background-color: var(--altoPretoAAA) !important;
}
.contraste .contrasteAlto button.branco {
    color: var(--altoBrancoAAA) !important;
}
.contraste .contrasteAlto .bkazul button.branco {
    color: var(--altoPretoAAA) !important;
}

.contraste .click.link.f400.s13.hover.htema.pad5.rad5.d-inline {
    color: var(--altoBrancoAA) !important;
}
.contraste .htema.pad5.rad5.d-inline.click.link.f400.s13.hover:hover {
    background-color: var(--altoPretoAAA) !important;
}

.contraste .b-tema10.b-w3.b-solid-top {
    border-color: rgba(var(--BrancoRGB), 0.1) !important;
}
.contraste .b-tema30.b-w3.b-solid-top {
    border-color: rgba(var(--BrancoRGB), 0.3) !important;
}
.contraste .b-solid-bottom.b-tema50,
.contraste .b-w1.b-tema50,
.contraste .b-tema50.b-w3.b-solid-top {
    border-color: rgba(var(--BrancoRGB), 0.5) !important;
}
.contraste .b-tema80.b-w3.b-solid-top {
    border-color: rgba(var(--BrancoRGB), 0.8) !important;
}
.contraste .b-tema.b-w3.b-solid-top {
    border-color: rgba(var(--BrancoRGB), 1) !important;
}

/* ---------------------------------- CONTRASTE ------------------------------------------------- */

/* ---------------------------------- Login ------------------------------------------------- */
/* -- Login -- */
.login1 {
    border: 1px solid;
}
.logintit {
    margin: -50px auto auto auto;
    width: 90%;
    min-height: 120px;
}

/* ----------------------------------- Top --------------------------------------------------- */

/* ---------------------------------- Transform ---------------------------------------------- */
.skew30 {
    transform: skewX(30deg);
}
.skew-30 {
    transform: skewX(-30deg);
}

/* ---------------------------------- Desktop ------------------------------------------------ */

.mobileViewS {
    display: block;
}

.mobileViewN {
    display: none;
}

.btnPesquisa1 {
    display: inline;
    padding: 5px 7px;
}
.btnPesquisa2 {
    display: none;
}

/* ---------------------------------- .Desktop ----------------------------------------------- */

/* ----------------------------------- coisas do piá ----------------------------------------- */
.header__search__content {
    width: 86%;
    margin-left: 7% !important;
}
.search {
    height: 35px;
    width: 35px;
}

/* ----------------------------------- .coisas do piá ---------------------------------------- */

/* ---------------------------------- Complemento Font-awesome ------------------------------- */

/* ---------------------------------- Collapsible ------------------------------- */

/* ---------------------------------- Media Queries ------------------------------------------ */

@media (min-width: 576px) {
}
@media (max-width: 576px) {
    .searchSpace {
        position: relative;
        width: 100%;
        /*margin-top: 0px;*/
        margin-left: -50%;
        /*margin-bottom: 0px;*/
        left: 50%;
        z-index: 5;
    }
    .imgMeuPergamum {
        background-image: none !important;
        min-height: 0px !important;
    }
    .bkdark70_0 {
        background: rgba(192, 192, 192, 0.2);
    }
    .header__search {
        /* margin-top: 0px !important;        
          margin-bottom: 0px !important; */
        background-color: #000;
    }
    .header__search__content {
        margin-left: 0px !important;
    }

    .additional_search_input {
        border-radius: 10px;
        border: 1px solid lightgray;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    /* CAROUSEL MANUAL MOBILE */
    .horizontal {
        overflow-x: auto !important;
    }
    .carouselM .janela .horizontal .imgCarousel {
        margin-right: 5% !important;
        width: 100% !important;
    }

    .carouselM .janela .horizontal {
        scroll-snap-type: x mandatory !important;
    }

    .carouselM .janela .horizontal .imgCarousel .nomeEvento {
        width: 100% !important;
    }

    .img_topo_biblioteca {
        padding-bottom: 0px !important;
    }

    /* FONTE MOBILE */
    .s14m {
        font-size: 15px !important;
    }
    .s15m {
        font-size: 16px !important;
    }
    .s16m {
        font-size: 16px !important;
    }
    .s17m {
        font-size: 17px !important;
    }
    .s18m {
        font-size: 18px !important;
    }
    .s19m {
        font-size: 19px !important;
    }
    .s20m {
        font-size: 20px !important;
    }
    .s21m {
        font-size: 21px !important;
    }
    .s22m {
        font-size: 22px !important;
    }
    .s23m {
        font-size: 23px !important;
    }
    .s24m {
        font-size: 24px !important;
    }
    .s25m {
        font-size: 25px !important;
    }
    .s30m {
        font-size: 30px !important;
    }
    .s35m {
        font-size: 35px !important;
    }
    .s40m {
        font-size: 40px !important;
    }
    .s50m {
        font-size: 50px !important;
    }
    .s60m {
        font-size: 60px !important;
    }
    .s70m {
        font-size: 70px !important;
    }
    .s80m {
        font-size: 80px !important;
    }
    .s90m {
        font-size: 90px !important;
    }
    .s100m {
        font-size: 100px !important;
    }
    .s110m {
        font-size: 110px !important;
    }
    .s120m {
        font-size: 120px !important;
    }
    .s130m {
        font-size: 130px !important;
    }
    .s140m {
        font-size: 140px !important;
    }
    .s150m {
        font-size: 150px !important;
    }

    .pagination {
        justify-content: space-around !important;
    }
}

/*@media (max-width: 768px)
    {
      .imgMeuPergamum {  background-image: none !important; background-color: #f2f2f2;}
    }*/
@media only screen and (max-width: 991px) and (min-width: 576px) {
    .header__search {
        /*margin-top: 92px !important;
      margin-bottom: -492px !important;
      height: 400px; */
    }
}

@media only screen and (max-width: 1007px) and (min-width: 992px) {
    .header__search {
        /*margin-top: 151px !important;        
      margin-bottom: -329px !important;*/
    }
}

/*@media only screen and (max-width: 768px) {*/
@media only screen and (max-width: 991px) {
    .header__search__content {
        width: 100%;
        margin-left: 0px !important;
    }

    .btnPesquisa2 {
        display: block;
    }
    .btnPesquisa1 {
        display: none;
    }
    .search {
        height: 35px;
        width: 100%;
    }

    .mobileViewS {
        display: none;
    }
    .mobileViewN {
        display: block;
    }

    .menuMobileAtivo {
        display: block;
    }
    .menuMobile {
        display: none;
    }
    .dinamico {
        display: inline-block;
    }
    .dinamicoInverso {
        display: none;
    }
    .videoError {
        padding-bottom: 56.25% !important;
        width: 100%;
        height: 100%;
    }
    .corFundo,
    .corFundo2 {
        top: 0.1%;
        bottom: 0.1%;
        right: 0.1%;
        left: 0.1%;
        min-width: 100%;
        width: 100%;
        overflow: auto;
        margin-bottom: 42px;
        min-width: 40px;
    }
    .imgLogin {
        display: none;
    }
    .footerLogin {
        background: rgb(72, 72, 72) !important;
    }
    .logintit {
        margin: auto;
        width: 100%;
        min-height: none;
    }
    .footerLogin {
        right: 1%;
        padding: 5px !important;
    }

    /*h3 				{	margin: auto		}*/

    .align-RL {
        text-align: left;
    }
    .align-CL {
        text-align: left;
    }
    .idAlert,
    .idPerfil {
        display: none;
    }
    .esconde_tab,
    .esconde_menu {
        display: none;
    }

    .oculta,
    .btn_abre_menu_cima {
        display: block;
    }

    .linhaMenuTopo {
        border-top: 1px solid #757575;
        border-left: 0px solid #757575;
        border-right: 3px solid #525252;
        border-bottom: 1px solid #000;
        color: #fff;
        margin-bottom: 2px;

        display: block;
        margin-right: 0px;
        border-radius: 0px;
    }

    .container-fluid1,
    .container-fluid2 {
        margin-left: 2px;
        margin-right: 2px;
        /*margin-top: 65px !important;*/
    }
    .menu1,
    .menu2 {
        position: inherit;
        width: 100%;
        /*display: table;*/
        /*height: 800px;*/
        margin-left: 0px;
        margin-top: 0px;
        display: table;
        z-index: 2;
    }

    .breadcrumbPortal1 {
        margin-left: 10px;
    }
    .alturaMeuPergamumMin {
        min-height: 10px;
    }
}

/* @media (min-width: 992px) { ... }
  
  @media (min-width: 1200px) { ... } */

@media (max-width: 768px) {
    #collapsible-filter {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.5s ease-in-out;
    }
    .img_topo_biblioteca {
        padding-top: 0px;
        background-image: none !important;
        background-color: rgba(192, 192, 192, 0.5);
        min-height: 0px !important;
    }
}

.containerSombra {
    box-shadow: 0px 0px 14px 1px rgba(109, 109, 109, 0.3);
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #fff;
}
.containerEnquete {
    box-shadow: none;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: none;
}

@media print {
    .pdf-container {
        display: none;
    }
}
