body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.header {
  min-height: 440px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .header__navbar__logo {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 226px;
    height: 120px;
    background-color: $white;
    border-radius: 0px 0px 5px 5px;
    border-top: 1px solid $b-tema;
    box-shadow: 0px 4px 8px 5px rgba(68, 68, 68, 0.16);
    margin-left: 50px;
  }

  .header__navbar__user {
    display: flex;
    border-color: rgba(172, 35, 51, 0.3);
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-right: 10px;
    height: 50px;
    font-size: 1.5em;
    color: rgba(0, 0, 0, 0.7);
    background-color: #c5c5c5;
    border-radius: 0 0 10px 10px !important;
    cursor: pointer;

    &:hover {
      color: rgba(172, 35, 51, 1);
    }

    svg {
      color: rgba(0, 0, 0, 0.1);

      &:hover {
        color: rgba(172, 35, 51, 1);
      }
    }

    h6 {
      margin: 0;
      font-weight: 400;
    }
  }

  .header__navbar__user {
    box-shadow: 0px 0px 14px 1px rgba(109, 109, 109, 0.3);
  }
}

.header__search {
  /*width: 100%;
    z-index: 2;
    position: absolute;
    height: 330px;
    margin-top: 170px;
    display: flex;
    justify-content: center;
    align-items: center;*/
  width: 100%;
  margin-top: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -374px;
}

@media (max-width: 575px) {
  .header__navbar {
    flex-direction: column;

    .header__navbar__user {
      align-self: flex-end;
    }
  }
}

.header__img {
  background-image: url(../../images/img_topo_biblioteca.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.footer__layer-1 {
  background-color: $light-gray-3;
  width: 100%;
  height: 80px;
}

.footer__layer-2 {
  width: 100%;
  height: 100px;
  background-color: $white;
  display: flex;
  justify-content: center;
  padding: 1em;

  .footer__layer-2__img {
    background-image: url(/static/media/ico.7c632ecf.svg);
    height: 66px;
    background-size: cover;
    width: 100px;
  }
}

.footer__layer-3 {
  width: 100%;
  height: 50px;
  background-color: $perga-blue;
  display: flex;
  justify-content: center;
  padding: 1em;

  label {
    color: $white;
    font-size: 0.85em;

    & a {
      color: $white;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
