.novasAquisicoesTipoLista{
 .__coverList{
     width: 100%;
     height: auto;
     display: flex;
     flex-wrap: wrap;
     gap: 15px;
     list-style: none;

    li.__cover{
        cursor: pointer;
        .__item{
            max-width: 230px;
            max-height: 300px;
            overflow: hidden;
            position: relative;
            .img-fluid{
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                display: flex;
            }
        }
    }

 }
}

.__bookName {
    position: absolute;
    left:0;
    top:0;
    z-index: +1;
    color: #fff;
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    width: 100%;
    text-align: center;
}
