/* ------------------------------------- variables ------------------------------------- */
:root {
    --verde: 76, 165, 80;
    --roxo: 141, 65, 159;
    --vermelho: 255, 0, 0;
    --azul: 0, 188, 212;
    --laranja: 255, 127, 36;
    --tiffany: 8, 124, 162;
    --magenta: 226, 10, 123;
    --amarelo: 255, 127, 36;
    --preto: 0, 0, 0;
    --branco: 255, 255, 255;

    --agata: 0, 0, 0;
    --ambar: 210, 155, 0;
    --ametista: 66, 9, 95;
    --cristal: 111, 111, 113;
    --esmeralda: 7, 74, 49;
    --oceanus: 48, 150, 255;
    --rose: 235, 158, 158;
    --rubi: 172, 35, 51;
    --safira: 0, 51, 102;
    --topazio: 208, 77, 0;

    --family: 'Roboto', sans-serif !important;

    --blur1: blur(5px);
    --blur2: blur(10px);
    --blur3: blur(15px);
    --blur4: blur(20px);

    --anima1: 1000ms;
    --anima2: 500ms;
}
/* ------------------------------------- .variables ------------------------------------ */

/* seletor #mp-root para aplicar somente em telas do meu pergamum */
#mp-root {
    .app.acessibilidade {
        &.agata {
            --tema: var(--agata);
        }
        &.ambar {
            --tema: var(--ambar);
        }
        &.ametista {
            --tema: var(--ametista);
        }
        &.cristal {
            --tema: var(--cristal);
        }
        &.esmeralda {
            --tema: var(--esmeralda);
        }
        &.oceanus {
            --tema: var(--oceanus);
        }
        &.rose {
            --tema: var(--rose);
        }
        &.rubi {
            --tema: var(--rubi);
        }
        &.safira {
            --tema: var(--safira);
        }
        &.topazio {
            --tema: var(--topazio);
        }
    }

    /* LINK */
    .z-a,
    .z-a2 {
        font-family: 'Roboto', sans-serif !important;
        text-decoration: none;
        border-radius: 2px;
        padding: 2px;
        transition: All 0.4s ease;
        cursor: pointer;
    }

    .z-a {
        color: rgba(var(--preto), 1);
        border-bottom: 2px solid rgba(var(--tema), 1);
    }

    .z-a2 {
        color: rgba(var(--branco), 1);
        border-bottom: 1px solid rgba(var(--branco), 0.5);
    }

    .z-a2:visited {
        color: rgba(var(--branco), 1);
    }

    .z-a:visited {
        color: rgba(var(--tema), 1);
    }

    .z-a:visited:hover,
    .z-a2:visited:hover {
        color: #fff !important;
    }

    .z-a[disabled] {
        border-bottom: 2px dotted rgba(255, 0, 0, 0.5);
    }

    .z-a:hover,
    .z-a2:hover {
        color: #fff !important;
        background: rgba(var(--tema), 1);
        border-radius: 5px;
        text-decoration: none;
    }

    .z-a[disabled]:hover {
        color: #fff;
        background: red;
        cursor: no-drop;
        border-radius: 5px;
        text-decoration: none;
    }
    /* .LINK */

    /* ANIMA */
    .mov-1 {
        transform: translateX(-110%);
        opacity: 0;
    }
    .mov0 {
        transform: translateX(0%);
        opacity: 1;
    }
    .mov1 {
        transform: translateX(110%);
        opacity: 0;
    }
    /* .ANIMA */

    /* ESTRUTURA */
    h5,
    h6 {
        padding: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid;
        border-image: linear-gradient(
            to right,
            rgba(var(--tema), 0.2),
            rgba(var(--tema), 1)
        );
        border-image-slice: 1;
        color: rgba(var(--tema), 1);
    }

    h5 svg,
    h6 svg {
        color: rgba(0, 0, 0, 0.5);
        margin-right: 0.5em;
    }
    .tabela .row {
        padding: 8px;
    }

    .tabela {
        font-size: 14px;
    }

    .tabela .row:nth-child(1) {
        background-color: transparent;
        border-bottom: 1px solid rgba(var(--tema), 0.4);
        font-weight: 700;
    }
    .tabela .row:nth-child(odd) {
        background-color: transparent;
    }
    .tabela .row:nth-child(even) {
        background-color: rgba(var(--preto), 0.02);
    }

    .rotulo {
        font-family: var(--family);
        font-weight: 700;
        font-size: 12px;
        color: rgba(var(--preto), 0.7);
        margin-left: 5px;
    }
    .w-300 {
        width: 300px;
    }

    .imgMeuPergamum {
        height: 100vh;
        background-image: url('../../images/original_bg_login.png');
        background-size: cover;
        background-color: rgba(var(--tema), 0);
        background-attachment: fixed;
        background-blend-mode: overlay;
    }

    .alturadoHeaderMeuPergamum {
        margin-top: calc(-100vh + 150px);
    }

    .topLinkSpaceMeuPergamum {
        float: right;
        border-radius: 0 0 8px 8px;
        margin-right: 24px;
    }

    .img_logoMeuPergamum {
        display: block;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 226px;
        height: 120px;
        border-radius: 0px 0px 5px 5px;
        position: relative;
        margin-left: 90px;
        border-top: 1px solid;
        margin-top: 1px;
    }
    .menu_logout {
        position: absolute;
        width: 100%;
        bottom: 0px;
    }
    .menu_container {
        @media screen and (max-width: 768px) {
            position: absolute;
            z-index: 1;
            width: 100%;
            pointer-events: none;

            &.mb-open {
                pointer-events: all;
            }
        }
    }
    .menu_wrapper {
        @media screen and (max-width: 768px) {
            width: 100% !important;
        }
    }
    .submenu_wrapper {
        margin-top: -45px;
    }
    .anima-1 {
        transition: var(--anima1);
    }
    .anima-2 {
        transition: var(--anima2);
    }
    .anima-3 {
        transition: var(--anima3);
    }
    .anima-4 {
        transition: var(--anima4);
    }

    .posicao1 {
        position: absolute;
        top: 46px;
        width: 100%;
    }
    .btnABC {
        width: 50px;
    }
    .alertaNumeral {
        width: 30px;
        height: 30px;
        text-align: center;
        padding-top: 5px;
        border-radius: 50%;
        position: relative;
        top: -11px;
        left: -11px;
        font-weight: 700;
        background: rgba(var(--laranja), 0.9);
        color: rgba(var(--branco), 1);
        font-size: 11px;
        border: 2px solid rgba(var(--branco), 0.5);
        box-shadow: 0px 0px 14px 1px rgba(109, 109, 109, 0.3);
        display: inline-block;

        @media screen and (max-width: 768px) {
            width: 24px;
            height: 24px;
            top: -10px;
            left: -10px;
        }
    }
    .alertaMsg {
        position: absolute;
        z-index: 1;
    }
    .alert-dismissible > .close {
        border: none !important;
        &:hover {
            color: rgba(10, 10, 10, 0.8) !important;
            border: none !important;
            background-image: none !important;
            background-color: transparent !important;
        }
    }
    /* .ESTRUTURA */

    /* CARTAO VIRTUAL */
    .cartao {
        height: 250px;
        min-width: 200px;
        max-width: 400px;
        width: 100%;
        perspective: 1000px;
    }

    .cartao-container:before,
    .cartao-container:after {
        content: '';
        display: block;
        clear: both;
    }

    .cartao-container {
        position: relative;
        height: 100%;
        transition: transform 0.4s;
        transform-style: preserve-3d;
    }

    /* .cartao:hover .cartao-container, */
    .girar {
        transform: rotateY(180deg);
    }

    .cartao-front,
    .cartao-back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        z-index: 1;
    }

    .cartao-front {
        background: green;
    }

    .cartao-back {
        transform: rotateY(180deg);
        background: red;
    }

    .maskCircle {
        clip-path: circle(40% at 50% 50%);
    }
    /* .CARTAO VIRTUAL */

    /* BUTTON */
    button.primeiro,
    .btnPri {
        box-shadow: 0 12px 20px -10px rgba(var(--tema), 0.28),
            0 4px 23px 0 rgba(0, 0, 0, 0.12),
            0 0 4px -5px rgba(var(--tema), 0.2);
        padding: 0.5rem !important;
        border-radius: 5px;
        font-weight: 500;
        transition: All 0.4s ease;
        color: #fff;
        border: none;
        font-size: 14px;
        margin-left: 1rem;
        background-color: rgba(var(--tema), 1);
        background-image: none;
        text-shadow: none;
        cursor: pointer;
        margin-left: 1rem;
    }
    button.primeiro:disabled,
    .btnPri:disabled {
        cursor: no-drop;
        font-size: 14px;
        font-family: 'Roboto', sans-serif !important;
        text-decoration: line-through;
        font-weight: 500;
        background-color: rgba(122, 60, 60, 0.2) !important;
        background-image: none;
        color: rgba(163, 128, 128, 0.7);
    }

    button.segundo,
    .btnSeg {
        box-shadow: inset 0 0px 0px 1px rgba(var(--tema), 0.3);
        padding: 0.5rem !important;
        border-radius: 5px;
        font-weight: 500;
        transition: All 0.4s ease;
        color: rgba(var(--preto), 0.6);
        border: none;
        font-size: 14px;
        margin-left: 1rem;
        background-color: rgba(var(--tema), 0);
        cursor: pointer;
        margin-left: 1rem;
    }
    button.segundo:disabled,
    .btnSeg:disabled {
        cursor: no-drop;
        font-size: 14px;
        font-family: 'Roboto', sans-serif !important;
        text-decoration: line-through;
        font-weight: 500;
        background-color: rgba(122, 60, 60, 0.1) !important;
        box-shadow: inset 0 0px 0px 1px rgba(var(--tema), 0.1);
        color: rgba(163, 128, 128, 0.7);
    }

    button:focus,
    .btnPri:focus,
    .btnSeg:focus {
        color: #fff !important;
        -webkit-transition: All 0.8s ease;
        transition: All 0.8s ease;
        background: linear-gradient(139deg, #2c5624, transparent);
        box-shadow: 0px 0px 20px 0px #f2ff0096, inset 0px 0px 8px 3px #eefb0094 !important;
        background-image: none;
    }
    button:active,
    .btnPri:active,
    .btnSeg:active {
        background-color: #000 !important;
        color: #fff !important;
        -webkit-transition: All 0.8s ease;
        transition: All 0.8s ease;
        background-image: linear-gradient(139deg, #2c5624, transparent);
        box-shadow: none !important;
    }

    button:active .s1,
    .btnPri:active .s1,
    .btnSeg:active .s1 {
        display: none;
    }
    button:active .s2,
    .btnPri:active .s2,
    .btnSeg:active .s2 {
        display: inline !important;
        -webkit-animation: rotate-center 2s linear infinite;
        animation: rotate-center 2s linear infinite;
    }

    button:hover,
    .btnPri:hover,
    .btnSeg:hover {
        background-color: #000 !important;
        color: #fff !important;
        -webkit-transition: All 0.8s ease;
        transition: All 0.8s ease;
        background-image: linear-gradient(139deg, #2c5624, transparent);
    }
    button:focus,
    button.primeiro:focus,
    button.segundo:focus {
        background-color: rgba(var(--verde), 1) !important;
    }

    button .loading {
        -webkit-animation: rotate-center 2s linear infinite;
        animation: rotate-center 2s linear infinite;
    }

    @-webkit-keyframes rotate-center {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotate-center {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    /* .BUTTON */

    .nav-tabs .nav-link.active:hover {
        background: rgba(var(--preto), 1);
    }
    .nav-tabs > li {
        font-size: 14px;
        cursor: pointer;
    }
    .nav-link {
        color: rgba(var(--tema), 1);
    }
    .nav-link:hover {
        color: rgba(var(--preto), 1);
        background: rgba(var(--branco), 0.5);
    }
    .nav-item:hover {
        background: rgba(var(--branco), 0.5);
    }
    .nav-tabs .nav-link.active:hover {
        background: rgba(var(--preto), 1);
    }
    .nav-tabs > li {
        font-size: 14px;
        cursor: pointer;
    }
    .nav-link {
        color: rgba(var(--tema), 1);
    }
    .nav-link:hover {
        color: rgba(var(--preto), 1);
        background: rgba(var(--branco), 0.5);
    }
    .nav-item:hover {
        background: rgba(var(--branco), 0.5);
    }
    /* BUTTON NEO */

    /* FORM */
    .custom-radio input[type='radio'],
    input[type='checkbox'] {
        box-sizing: border-box;
        padding: 0;
        width: 24px;
        height: 24px;
        accent-color: rgba(var(--tema), 1);
        margin-top: 0;
        margin-left: 0px;
    }

    .custom-checkbox label,
    .custom-radio label {
        cursor: pointer;
        margin-left: 32px;
        font-family: var(--family);
        font-weight: 300;
        font-size: 20px;
    }

    /* .FORM */
    /* BASIC */
    .blur1 {
        backdrop-filter: var(--blur1);
        -webkit-backdrop-filter: var(--blur1);
    }
    .blur2 {
        backdrop-filter: var(--blur2);
        -webkit-backdrop-filter: var(--blur2);
    }
    .blur3 {
        backdrop-filter: var(--blur3);
        -webkit-backdrop-filter: var(--blur3);
    }

    .b-tema-10 {
        border-color: rgba(var(--tema), 0.1);
    }
    .b-tema-20 {
        border-color: rgba(var(--tema), 0.2);
    }
    .b-tema-30 {
        border-color: rgba(var(--tema), 0.3);
    }
    .b-tema-40 {
        border-color: rgba(var(--tema), 0.4);
    }
    .b-tema-50 {
        border-color: rgba(var(--tema), 0.5);
    }
    .b-tema-60 {
        border-color: rgba(var(--tema), 0.6);
    }
    .b-tema-70 {
        border-color: rgba(var(--tema), 0.7);
    }
    .b-tema-80 {
        border-color: rgba(var(--tema), 0.8);
    }
    .b-tema-90 {
        border-color: rgba(var(--tema), 0.9);
    }
    .b-tema {
        border-color: rgba(var(--tema), 1);
    }
    .b-azul-50 {
        border-color: rgba(var(--azul), 0.5);
    }
    .b-verde-50 {
        border-color: rgba(var(--verde), 0.5);
    }
    .b-verde-60 {
        border-color: rgba(var(--verde), 0.6);
    }
    .b-verde-70 {
        border-color: rgba(var(--verde), 0.7);
    }
    .b-verde-80 {
        border-color: rgba(var(--verde), 0.8);
    }
    .b-verde-90 {
        border-color: rgba(var(--verde), 0.9);
    }
    .b-preto-20 {
        border-color: rgba(var(--preto), 0.2);
    }

    .bg-tema {
        background-color: rgba(var(--tema), 1);
    }
    .bg-tema-10 {
        background-color: rgba(var(--tema), 0.1);
    }
    .bg-tema-20 {
        background-color: rgba(var(--tema), 0.2);
    }
    .bg-tema-30 {
        background-color: rgba(var(--tema), 0.3);
    }
    .bg-tema-40 {
        background-color: rgba(var(--tema), 0.4);
    }
    .bg-tema-50 {
        background-color: rgba(var(--tema), 0.5);
    }
    .bg-tema-60 {
        background-color: rgba(var(--tema), 0.6);
    }
    .bg-tema-70 {
        background-color: rgba(var(--tema), 0.7);
    }
    .bg-tema-80 {
        background-color: rgba(var(--tema), 0.8);
    }
    .bg-tema-90 {
        background-color: rgba(var(--tema), 0.9);
    }
    .bg-none {
        background-color: rgba(var(--preto), 0) !important;
    }

    .bg-degrade-mobile {
        background-image: linear-gradient(
            145deg,
            rgba(var(--tema), 1) 0%,
            rgba(var(--tema), 0.8) 35%,
            rgba(var(--tema), 0.05) 100%
        );
    }

    .text-tema {
        color: rgba(var(--tema), 1);
    }
    .text-tema-10 {
        color: rgba(var(--tema), 0.1);
    }
    .text-tema-20 {
        color: rgba(var(--tema), 0.2);
    }
    .text-tema-30 {
        color: rgba(var(--tema), 0.3);
    }
    .text-tema-40 {
        color: rgba(var(--tema), 0.4);
    }
    .text-tema-50 {
        color: rgba(var(--tema), 0.5);
    }
    .text-tema-60 {
        color: rgba(var(--tema), 0.6);
    }
    .text-tema-70 {
        color: rgba(var(--tema), 0.7);
    }
    .text-tema-80 {
        color: rgba(var(--tema), 0.8);
    }
    .text-tema-90 {
        color: rgba(var(--tema), 0.9);
    }

    .bg-verde {
        background-color: rgba(var(--verde), 1);
    }
    .bg-verde-5 {
        background-color: rgba(var(--verde), 0.05);
    }
    .bg-verde-10 {
        background-color: rgba(var(--verde), 0.1);
    }
    .bg-verde-20 {
        background-color: rgba(var(--verde), 0.2);
    }
    .bg-verde-30 {
        background-color: rgba(var(--verde), 0.3);
    }
    .bg-verde-40 {
        background-color: rgba(var(--verde), 0.4);
    }
    .bg-verde-50 {
        background-color: rgba(var(--verde), 0.5);
    }
    .bg-vermelho {
        background-color: rgba(var(--vermelho), 1);
    }
    .bg-roxo {
        background-color: rgba(var(--roxo), 1);
    }
    .bg-azul {
        background-color: rgba(var(--azul), 1);
    }
    .bg-azul-5 {
        background-color: rgba(var(--azul), 0.05);
    }
    .bg-azul-10 {
        background-color: rgba(var(--azul), 0.1);
    }
    .bg-azul-20 {
        background-color: rgba(var(--azul), 0.2);
    }
    .bg-azul-30 {
        background-color: rgba(var(--azul), 0.3);
    }
    .bg-azul-40 {
        background-color: rgba(var(--azul), 0.4);
    }
    .bg-azul-50 {
        background-color: rgba(var(--azul), 0.5);
    }
    .bg-laranja {
        background-color: rgba(var(--laranja), 1);
    }
    .bg-tiffany {
        background-color: rgba(var(--tiffany), 1);
    }
    .bg-magenta {
        background-color: rgba(var(--magenta), 1);
    }
    .bg-preto {
        background-color: rgba(var(--preto), 0.7);
    }
    .bg-amarelo {
        background-color: rgba(var(--amarelo), 1);
    }
    .bg-branco {
        background-color: rgba(var(--branco), 1);
    }

    .text-verde {
        color: rgba(var(--verde), 1);
    }
    .text-vermelho {
        color: rgba(var(--vermelho), 1);
    }
    .text-roxo {
        color: rgba(var(--roxo), 1);
    }
    .text-azul {
        color: rgba(var(--azul), 1);
    }
    .text-laranja {
        color: rgba(var(--laranja), 1);
    }
    .text-tiffany {
        color: rgba(var(--tiffany), 1);
    }
    .text-magenta {
        color: rgba(var(--magenta), 1);
    }
    .text-preto {
        color: rgba(var(--preto), 0.7);
    }
    .text-amarelo {
        color: rgba(var(--amarelo), 1);
    }
    .text-branco {
        color: rgba(var(--branco), 1);
    }

    .obrigatorio::after {
        color: #f00;
        content: ' (*)';
        font-weight: 900;
        font-family: var(--family);
    }
    .opcional::after {
        color: rgba(var(--azul), 1);
        content: ' (op)';
        font-weight: 900;
        font-family: var(--family);
    }
    .link-underline {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    /* .BASIC */

    /* BOOSTRAP */
    .btn-w100 {
        display: inline;
        width: auto;
    }
    /* .BOOSTRAP */

    /* MODAL */
    .modal-title {
        background-color: rgba(var(--tema), 1);
        border-radius: 5px;
        background-image: url('../../images/montanha.png'),
            url('../../images/card_tema.svg');
        background-position: center;
        background-size: cover;
    }
    /* .MODAL */

    .neo {
        /* BUTTON MOBILE */
        button.primeiro.mobile,
        button.segundo.mobile {
            padding: 0.5rem 0rem 0.5rem 1rem !important;
            text-align: left;
            height: 60px;
        }
        /* .BUTTON MOBILE */

        /* BUTTON NEO */
        button.primeiro,
        .btnPri {
            box-shadow: 15px 10px 20px -13px rgba(var(--tema), 1),
                -20px -10px 20px -10px rgba(var(--preto), 0.1),
                inset 3px 3px 10px 5px rgba(255, 255, 255, 0.5);
            padding: 0.5rem !important;
            border-radius: 16px;
            font-weight: 500;
            transition: All 0.4s ease;
            color: #fff;
            border: 0px solid rgba(255, 255, 255, 0.8);
            font-size: 14px;
            margin-left: 1rem;
            background-color: rgba(var(--tema), 1);
            background-image: none;
            text-shadow: none;
            cursor: pointer;
            margin-left: 0.25rem;
        }
        button.primeiro:disabled,
        .btnPri:disabled {
            cursor: no-drop;
            font-size: 14px;
            font-family: 'Roboto', sans-serif !important;
            text-decoration: line-through;
            font-weight: 500;
            background-color: rgba(122, 60, 60, 0.2) !important;
            background-image: none;
            color: rgba(163, 128, 128, 0.7);
            box-shadow: 15px 10px 20px -13px rgba(var(--tema), 0.4),
                -20px -10px 20px -10px rgba(var(--preto), 0.1),
                inset 3px 3px 10px 5px rgba(255, 255, 255, 0.5);
        }

        button.segundo,
        .btnSeg {
            box-shadow: inset 0 0px 0px 1px rgba(var(--tema), 0.3);
            padding: 0.5rem !important;
            border-radius: 16px;
            font-weight: 500;
            transition: All 0.4s ease;
            color: rgba(var(--preto), 0.6);
            border: rgba(var(--tema), 0.3) 1px solid;
            font-size: 14px;
            margin-left: 1rem;
            background-color: rgba(var(--tema), 0);
            cursor: pointer;
            margin-left: 1rem;
            background-color: rgba(255, 255, 255, 0.2);
            box-shadow: 15px 10px 20px -13px rgba(var(--tema), 0.3),
                -20px -10px 20px -10px rgba(var(--preto), 0.05),
                inset 3px 3px 10px 5px rgba(255, 255, 255, 0.5);
        }
        button.segundo:disabled,
        .btnSeg:disabled {
            cursor: no-drop;
            font-size: 14px;
            font-family: 'Roboto', sans-serif !important;
            text-decoration: line-through;
            font-weight: 500;
            background-color: rgba(122, 60, 60, 0.1) !important;
            box-shadow: inset 0 0px 0px 1px rgba(var(--tema), 0.1);
            color: rgba(163, 128, 128, 0.7);
        }
        button.primeiro.nao,
        .btnPri.nao {
            box-shadow: 15px 10px 20px -13px rgba(var(--vermelho), 1),
                -20px -10px 20px -10px rgba(var(--preto), 0.1),
                inset 3px 3px 10px 5px rgba(255, 255, 255, 0.5);
            padding: 0.5rem !important;
            border-radius: 16px;
            font-weight: 500;
            transition: All 0.4s ease;
            color: #fff;
            border: 0px solid rgba(255, 255, 255, 0.8);
            font-size: 14px;
            margin-left: 1rem;
            background-color: rgba(var(--vermelho), 1);
            background-image: none;
            text-shadow: none;
            cursor: pointer;
            margin-left: 0.25rem;
        }
        button.segundo.nao,
        .btnSeg.nao {
            box-shadow: inset 0 0px 0px 1px rgba(var(--vermelho), 0.3);
            padding: 0.5rem !important;
            border-radius: 16px;
            font-weight: 500;
            transition: All 0.4s ease;
            color: rgba(var(--vermelho), 0.6);
            border: rgba(var(--vermelho), 0.3) 1px solid;
            font-size: 14px;
            margin-left: 1rem;
            background-color: rgba(var(--vermelho), 0.05);
            cursor: pointer;
            margin-left: 1rem;
            background-color: rgba(255, 255, 255, 0.2);
            box-shadow: 15px 10px 20px -13px rgba(var(--vermelho), 0.3),
                -20px -10px 20px -10px rgba(var(--preto), 0.05),
                inset 3px 3px 10px 5px rgba(255, 255, 255, 0.1);
        }
        .nav-tabs .nav-link.active {
            background: rgba(var(--tema), 0.9) !important;
            color: #fff;
            font-family: var(--family);
            font-weight: 700;
        }
        /* BUTTON NEO */
        input[type='date'],
        input[type='datetime-local'],
        input[type='email'],
        input[type='hidden'],
        input[type='image'],
        input[type='month'],
        input[type='number'],
        input[type='password'],
        input[type='search'],
        input[type='tel'],
        input[type='text'],
        input[type='time'],
        input[type='week'],
        textarea,
        select,
        input[type='url'] {
            font-family: var(--family);
            font-weight: 300;
            font-size: 20px;
            color: rgba(var(--preto), 1);
            border-radius: 5px;

            box-shadow: inset 0 -1px rgba(var(--tema), 0.2),
                inset 3px 3px 10px rgba(var(--tema), 0.1),
                inset -2px -2px 10px #ffffff;

            background: transparent;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(238, 238, 238);
            transition: all 0.4s ease 0s;
            padding: 10px 15px !important;
        }
        textarea {
            height: 205px !important;
        }

        .bkneo {
            background: linear-gradient(
                204deg,
                rgba(241, 240, 255, 1) 0%,
                rgba(231, 231, 231, 1) 35%,
                rgba(244, 251, 253, 1) 100%
            );
        }
        .bkneo {
            background-color: rgba(var(--preto), 0);
        }

        .imgMeuPergamum {
            height: 100vh;
            background-image: url(/images/original_bg_login.png);
            background-size: cover;
            background-color: rgba(var(--tema), 0.1);
            background-attachment: fixed;
            background-blend-mode: overlay;
        }
        .alturadoHeaderMeuPergamum {
            margin-top: calc(-100vh + 150px);
        }
        .bkmenu-1 {
            background: rgba(var(--branco), 0.3);
        }
        .bkmenu-2 {
            background: linear-gradient(
                140deg,
                rgba(var(--preto), 0.8) 0%,
                rgba(var(--tema), 0.8) 50%,
                rgba(var(--tema), 1) 100%
            );
        }
        .b-menu {
            border: rgba(var(--branco), 1) solid 1px;
        }
        .b-menu-divide {
            border-color: rgba(var(--branco), 0.3);
        }
        .cor-menu {
            color: rgba(var(--branco), 1);
        }
        .cor-menu-ico {
            color: rgba(var(--branco), 0.5);
        }
        .cor-menu-sub {
            color: rgba(var(--branco), 0.5);
        }
        .bkbread {
            background: rgba(240, 240, 245, 0.9);
        }
        .cor-bread {
            color: rgba(var(--preto), 0.8);
        }
        .z-a-bread {
            color: rgba(var(--preto), 0.8);
        }
        .cor-bread-ico {
            color: rgba(var(--tema), 1);
        }
        .bk-theme-meio {
            background: rgba(var(--branco), 0.5);
        }
    }

    /* DARK */

    .dark {
        button.primeiro,
        .btnPri {
            box-shadow: 15px 10px 20px -13px rgba(var(--tema), 1),
                -20px -10px 20px -10px rgba(var(--branco), 0.1),
                inset 3px 3px 10px 5px rgba(165, 165, 165, 0.5);
            padding: 0.5rem !important;
            border-radius: 16px;
            font-weight: 500;
            transition: All 0.4s ease;
            color: #fff;
            border: 1px solid rgba(255, 255, 255, 0.4);
            font-size: 14px;
            margin-left: 1rem;
            background-color: rgba(var(--tema), 1);
            background-image: none;
            text-shadow: none;
            cursor: pointer;
            margin-left: 0.25rem;
        }
        button.primeiro:disabled,
        .btnPri:disabled {
            cursor: no-drop;
            font-size: 14px;
            font-family: 'Roboto', sans-serif !important;
            text-decoration: line-through;
            font-weight: 500;
            background-color: rgba(122, 60, 60, 0.2) !important;
            background-image: none;
            color: rgba(163, 128, 128, 0.7);
            box-shadow: 15px 10px 20px -13px rgba(var(--tema), 0.4),
                -20px -10px 20px -10px rgba(var(--preto), 0.1),
                inset 3px 3px 10px 5px rgba(255, 255, 255, 0.5);
        }

        button.segundo,
        .btnSeg {
            box-shadow: inset 0 0px 0px 1px rgba(var(--tema), 0.3);
            padding: 0.5rem !important;
            border-radius: 16px;
            font-weight: 500;
            transition: All 0.4s ease;
            color: rgba(var(--branco), 0.8);
            border: rgba(var(--branco), 0.2) 1px solid;
            font-size: 14px;
            margin-left: 1rem;
            background-color: rgba(var(--tema), 0);
            cursor: pointer;
            margin-left: 1rem;
            background-color: rgba(149, 149, 149, 0.2);
            box-shadow: 15px 10px 20px -13px rgba(var(--preto), 0.3),
                -20px -10px 20px -10px rgba(var(--branco), 0.05),
                inset 3px 3px 10px 5px rgba(5, 5, 5, 0.1);
        }
        button.segundo:disabled,
        .btnSeg:disabled {
            cursor: no-drop;
            font-size: 14px;
            font-family: 'Roboto', sans-serif !important;
            text-decoration: line-through;
            font-weight: 500;
            background-color: rgba(122, 60, 60, 0.1) !important;
            box-shadow: inset 0 0px 0px 1px rgba(var(--tema), 0.1);
            color: rgba(163, 128, 128, 0.7);
        }
        button.primeiro.nao,
        .btnPri.nao {
            box-shadow: 15px 10px 20px -13px rgba(var(--vermelho), 1),
                -20px -10px 20px -10px rgba(var(--preto), 0.1),
                inset 3px 3px 10px 5px rgba(255, 255, 255, 0.5);
            padding: 0.5rem !important;
            border-radius: 16px;
            font-weight: 500;
            transition: All 0.4s ease;
            color: #fff;
            border: 1px solid rgba(var(--vermelho), 0.3);
            font-size: 14px;
            margin-left: 1rem;
            background-color: rgba(var(--vermelho), 1);
            background-image: none;
            text-shadow: none;
            cursor: pointer;
            margin-left: 0.25rem;
        }
        button.segundo.nao,
        .btnSeg.nao {
            box-shadow: inset 0 0px 0px 1px rgba(var(--vermelho), 0.3);
            padding: 0.5rem !important;
            border-radius: 16px;
            font-weight: 500;
            transition: All 0.4s ease;
            color: rgba(var(--vermelho), 0.6);
            border: rgba(var(--vermelho), 0.3) 1px solid;
            font-size: 14px;
            margin-left: 1rem;
            background-color: rgba(var(--vermelho), 0.05);
            cursor: pointer;
            margin-left: 1rem;
            background-color: rgba(255, 255, 255, 0.2);
            box-shadow: 15px 10px 20px -13px rgba(var(--vermelho), 0.3),
                -20px -10px 20px -10px rgba(var(--preto), 0.05),
                inset 3px 3px 10px 5px rgba(255, 255, 255, 0.1);
        }
        .nav-tabs .nav-link.active {
            background: rgba(var(--tema), 0.9) !important;
            color: #fff;
            font-family: var(--family);
            font-weight: 700;
        }
        input[type='date'],
        input[type='datetime-local'],
        input[type='email'],
        input[type='hidden'],
        input[type='image'],
        input[type='month'],
        input[type='number'],
        input[type='password'],
        input[type='search'],
        input[type='tel'],
        input[type='text'],
        input[type='time'],
        input[type='week'],
        textarea,
        select,
        input[type='url'] {
            font-family: var(--family);
            font-weight: 300;
            font-size: 20px;
            color: rgba(var(--branco), 1);
            border-radius: 5px;
            box-shadow: inset 0 -1px rgba(var(--preto), 0.2),
                inset 3px 3px 10px rgba(var(--preto), 0.1),
                inset -2px -2px 10px #353535;
            background: transparent;
            border-width: 1px;
            border-style: solid;
            border-color: rgba(var(--branco), 0.3);
            transition: all 0.4s ease 0s;
            padding: 10px 15px !important;
        }
        textarea {
            height: 205px !important;
        }
        .form-control:focus {
            color: #000000;
            background-color: rgba(255, 255, 255, 0.8);
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }

        .bkneo {
            background: linear-gradient(
                155deg,
                rgb(52, 52, 52) 0%,
                rgb(82, 82, 82) 35%,
                rgb(82, 82, 82) 100%
            );
        }

        .imgMeuPergamum {
            height: 100vh;
            background-image: url(/images/original_bg_login.png);
            background-size: cover;
            background-color: rgba(var(--tema), 0.1);
            background-attachment: fixed;
            background-blend-mode: overlay;
        }
        .alturadoHeaderMeuPergamum {
            margin-top: calc(-100vh + 150px);
        }
        .bkmenu-1 {
            background: rgba(var(--preto), 0.7);
        }
        .bkmenu-2 {
            background: linear-gradient(
                140deg,
                rgba(var(--preto), 0.8) 0%,
                rgba(var(--tema), 0.8) 50%,
                rgba(var(--tema), 1) 100%
            );
        }
        .b-menu {
            border: rgba(var(--preto), 1) solid 1px;
        }
        .b-menu-divide {
            border-color: rgba(var(--branco), 0.3);
        }
        .cor-menu {
            color: rgba(var(--branco), 1);
        }
        .cor-menu-ico {
            color: rgba(var(--branco), 0.5);
        }
        .cor-menu-sub {
            color: rgba(var(--branco), 0.5);
        }
        .bkbread {
            background: rgba(10, 10, 10, 0.8);
        }
        .cor-bread {
            color: rgba(var(--branco), 0.8);
        }
        .z-a-bread {
            color: rgba(var(--branco), 0.8);
        }
        .cor-bread-ico {
            color: rgba(var(--branco), 1);
        }
        .bk-theme-meio {
            background: rgba(var(--branco), 0.1);
        }
        .rotulo {
            color: rgba(var(--branco), 1);
        }
        h5,
        h6 {
            padding: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid;
            border-image: linear-gradient(
                to right,
                rgba(var(--branco), 0.2),
                rgba(var(--branco), 0.5)
            );
            border-image-slice: 1;
            color: rgba(var(--branco), 1);
        }

        h5 svg,
        h6 svg {
            color: rgba(var(--branco), 0.5);
            margin-right: 0.5em;
        }
        .tabela {
            color: rgba(var(--branco), 1);
        }
        .tabela .row:nth-child(1) {
            border-bottom: 1px solid rgba(var(--branco), 0.2);
        }
        .topLinkSpaceMeuPergamum {
            background-color: rgba(var(--preto), 0.5);
        }
        .b-tema {
            border-color: rgba(var(--preto), 0.5);
        }
    }
    /* DARK */

    /* ---------------------------------- Media Queries ------------------------------------------ */

    @media (max-width: 576px) {
        .btn-w100 {
            display: block;
            width: 100%;
        }
        .tabela .row:nth-child(1) {
            background-color: transparent;
            border-bottom: none;
            font-weight: 700;
            margin-top: -15px;
        }
    }
}
