//* Cor vinda do banco:
$color_default: "safira";

$success: #01ad4f;
$agata: rgb(0, 0, 0);
$ambar: rgb(210, 155, 0);
$ametista: rgb(66, 9, 95);
$cristal: rgb(111, 111, 113);
$esmeralda: rgb(7, 74, 49);
$oceanus: rgb(48, 150, 255);
$rose: rgb(214, 88, 88);
$rubi: rgb(172, 35, 51);
$safira: rgb(0, 51, 102);
$topazio: rgb(208, 77, 0);

@if ($color_default == "agata") {
  $color_default: $agata;
} @else if ($color_default == "ambar") {
  $color_default: $ambar;
} @else if ($color_default == "ametista") {
  $color_default: $ametista;
} @else if ($color_default == "cristal") {
  $color_default: $cristal;
} @else if ($color_default == "esmeralda") {
  $color_default: $esmeralda;
} @else if($color_default == "oceanus") {
  $color_default: $oceanus;
} @else if ($color_default == "rose") {
  $color_default: $rose;
} @else if ($color_default == "rubi") {
  $color_default: $rubi;
} @else if ($color_default == "safira") {
  $color_default: $safira;
} @else if ($color_default == "topazio") {
  $color_default: $topazio;
}

//* Vermelho e tons quentes:
$danger: #c00;
$b-tema: rgba($color_default, 1);
$light-color: rgba($color_default, 0.5);
$border-color: rgba($color_default, 0.1);
$border-color20: rgba($color_default, 0.2);
$border-color30: rgba($color_default, 0.3);
$border-color50: rgba($color_default, 0.5) !important;
$icon-color: rgba($color_default, 0.3);
$link-color: rgba($color_default, 0.7) !important;
$link-color-h: rgba($color_default, 1) !important;
$link-color-2: rgba($color_default, 0.5) !important;
$link-color-3: rgba($color_default, 0.4) !important;
$bkcolor60: rgba($color_default, 0.6) !important;



//* Branco e cores neutras:
$white: #fff;
$light-gray: #cecece;
$light-gray-2: #0000001a;
$light-gray-3: #c0c0c033;

//* Azul e cores frias:
$perga-blue: #003366;

//* VARIAVEIS CAROUSEL MANUAL 
  $duration: 450ms;
  $tileWidth: 400px;
  $tileHeight: auto;
  $iconSize: 50px;
  $growFactor: 1.5;

  $moveLeft: -($tileWidth * ($growFactor - 1) / 2);
  $moveRight: $tileWidth * ($growFactor - 1);
//* VARIAVEIS CAROUSEL MANUAL

.align-c {
  display: flex;
  align-items: center;
}

.align-end {
  justify-content: flex-end;
}

.column {
  display: flex;
  flex-direction: column;
}

.column-c {
  flex-direction: column;
  justify-content: center;
}

.align-sp-bt {
  display: flex;
  justify-content: space-between !important;
}

.align-sp-ar {
  display: flex;
  justify-content: space-around !important;
}

.link-no-und {
  &:hover {
    text-decoration: none !important;
  }
}

.link,
.link:hover {
  text-decoration: underline !important;
}

label.text-justify.s14.lead b {
  font-weight: bold;
}

label.text-justify.s14.lead i {
  font-style: italic;
}

.max-w-150 {
  max-width: 150px;
}

.max-w-300 {
  max-width: 300px;
}

.over-x-scroll {
  overflow-x: scroll;
}

.table th, .table td {
  vertical-align: middle;
}

mark, .mark {
  padding: 0.2em;
  background-color: #ffef9c;
}





