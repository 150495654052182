@charset "UTF-8";
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url(./layout/boustyle.css);
@import url(./layout/boostrap-custom.css);
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");
@import url(react-toastify/dist/ReactToastify.css);
@import url(animate.css/animate.min.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.align-c {
  display: flex;
  align-items: center;
}

.align-end {
  justify-content: flex-end;
}

.column {
  display: flex;
  flex-direction: column;
}

.column-c {
  flex-direction: column;
  justify-content: center;
}

.align-sp-bt {
  display: flex;
  justify-content: space-between !important;
}

.align-sp-ar {
  display: flex;
  justify-content: space-around !important;
}

.link-no-und:hover {
  text-decoration: none !important;
}

.link,
.link:hover {
  text-decoration: underline !important;
}

label.text-justify.s14.lead b {
  font-weight: bold;
}

label.text-justify.s14.lead i {
  font-style: italic;
}

.max-w-150 {
  max-width: 150px;
}

.max-w-300 {
  max-width: 300px;
}

.over-x-scroll {
  overflow-x: scroll;
}

.table th,
.table td {
  vertical-align: middle;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #ffef9c;
}

.agata {
  /* cor pura com opacidade de 100% */
  /* cor para contraste / segunda cor */
}

.agata.acessibilidade.zoom-20 {
  zoom: 120%;
  width: 120%;
}

.agata.acessibilidade.zoom-40 {
  zoom: 140%;
  width: 140%;
}

.agata.acessibilidade.zoom-60 {
  zoom: 160%;
  width: 160%;
}

.agata.acessibilidade.zoom-80 {
  zoom: 180%;
  width: 180%;
}

.agata.acessibilidade.zoom-100 {
  width: 200%;
  zoom: 200%;
}

.agata.acessibilidade.negrito,
.agata.acessibilidade.negrito * {
  font-weight: 900 !important;
}

.agata.acessibilidade.escala-cinza {
  filter: grayscale(1);
}

.agata.acessibilidade.invertido {
  filter: invert(100%);
}

.agata .corFundo {
  background: rgba(10, 36, 62, 0.4) !important;
}

.agata .corFundo2 {
  background: black !important;
}

.agata .htema:hover,
.agata .bktema,
.agata .z-window,
.agata .tag2,
.agata .modal-title,
.agata .hover3:hover,
.agata .btnTema:hover {
  background-color: black !important;
}

.agata .htema:hover:after,
.agata .bktema:after,
.agata .z-window:after,
.agata .tag2:after,
.agata .modal-title:after,
.agata .hover3:hover:after,
.agata .btnTema:hover:after {
  border-top-color: black !important;
}

.agata .bktema10 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.agata .bktema20 {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.agata .bktema30 {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.agata .bktema40 {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.agata .bktema50,
.agata .linhaMenu:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.agata .bktema60 {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.agata .bktema70 {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.agata .bktema80 {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.agata .bktema90 {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.agata .btnMenuEnquete .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.agata .btnMenuEnquete:hover .cortxt {
  color: black !important;
}

.agata .btnMenuEnquete .ativo {
  color: black !important;
}

.agata .btnMenuEnquete {
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.agata .btnMenuEnquete:hover {
  box-shadow: 0px -5px 0px 0px black inset;
  border: none;
}

.agata .btnMenuAdm .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.agata .btnMenuAdm:hover .cortxt {
  color: black !important;
}

.agata .btnMenuAdm .ativo {
  color: black !important;
}

.agata .btnMenuAdm {
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.agata .btnMenuAdm:hover {
  box-shadow: 0px -5px 0px 0px black inset;
  border: none;
}

.agata .ativo2 {
  box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.5) inset;
  border: none;
  transition: All 0.5s ease;
}

.agata .btnall2 {
  box-shadow: 300px 0px 0px 0px black inset;
  border: none;
  transition: All 0.2s ease;
}

.agata .btnall2:hover {
  box-shadow: 0px 0px 0px 0px black inset;
  border: none;
}

.agata .bktemadegrade {
  background: linear-gradient(-60deg, black, #cde1f3) !important;
  /* degradê */
}

.agata body,
.agata .hover1:hover,
.agata .btnNumber:hover,
.agata .cortxt {
  color: black !important;
}

.agata .cortxt.qrcode path:nth-child(2) {
  fill: black !important;
}

.agata .cortxt10 {
  color: rgba(0, 0, 0, 0.1) !important;
}

.agata .cortxt20 {
  color: rgba(0, 0, 0, 0.2) !important;
}

.agata .cortxt30 {
  color: rgba(0, 0, 0, 0.3) !important;
}

.agata .totalFiltro:before,
.agata .totalFiltro:after,
.agata .cortxt40 {
  color: rgba(0, 0, 0, 0.4) !important;
}

.agata .cortxt50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.agata .cortxt60 {
  color: rgba(0, 0, 0, 0.6) !important;
}

.agata .btnAzul,
.agata .cortxt70 {
  color: rgba(0, 0, 0, 0.7) !important;
}

.agata .cortxt80 {
  color: rgba(0, 0, 0, 0.8) !important;
}

.agata .cortxt90 {
  color: rgba(0, 0, 0, 0.9) !important;
}

.agata .cortxt2 {
  color: #fff !important;
}

.agata .b-tema {
  border-color: black !important;
}

.agata .b-none {
  border: none !important;
}

.agata .capitalizar {
  text-transform: uppercase !important;
}

.agata .b-tema10 {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.agata .b-tema20 {
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.agata .b-tema30 {
  border-color: rgba(0, 0, 0, 0.3) !important;
}

.agata .b-tema40 {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

.agata .b-tema50 {
  border-color: rgba(0, 0, 0, 0.5) !important;
}

.agata .b-tema70 {
  border-color: rgba(0, 0, 0, 0.7) !important;
}

.agata .b-tema80 {
  border-color: rgba(0, 0, 0, 0.8) !important;
}

.agata .b-tema90 {
  border-color: rgba(0, 0, 0, 0.9) !important;
}

.agata .stema {
  box-shadow: 1px 6px 10px 1px rgba(0, 0, 0, 0.4) !important;
}

.agata .tag2:after {
  border-color: black transparent transparent transparent;
}

.agata .detaque_titulo1 {
  padding: 10px 10px 10px 22px;
  border-left: 8px solid rgba(0, 0, 0, 0.2);
  /* */
}

.agata .imgTemaSelect {
  outline: 1px solid rgba(0, 0, 0, 0.2);
}

.agata .z-window-header {
  color: white !important;
}

.agata .login1 {
  box-shadow: 0px 0px 9px 7px rgba(0, 0, 0, 0.2);
  border: 3px solid rgba(0, 0, 0, 0.29);
  background: rgba(255, 255, 255, 0.9);
}

.agata .logintit {
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);
  /* cor pura com opacidade de 10% */
}

.agata .footerLogin {
  background: rgba(10, 36, 62, 0.4);
  /* cor pura + escura com opacidade de 40% */
}

.agata .imgLogoSelect {
  border: 1px solid black;
}

.agata .linhabot01 {
  background: #f9f9f9;
}

.agata .linhabot02 {
  background: #fff;
}

.agata button {
  border: groove rgba(255, 255, 255, 0.5);
}

.agata button:hover {
  border: groove rgba(255, 255, 255, 0.8);
}

.agata button:active {
  border: groove rgba(0, 0, 0, 0.2);
}

.agata .z-button,
.agata .z-button:focus {
  color: #991426;
}

.agata .z-button:hover {
  background: #000;
  color: #fff;
}

.agata .z-button:active {
  background: #008000;
  color: #fff;
}

.agata .btn1,
.agata .btn1:focus {
  background: black;
  color: #fff;
}

.ambar {
  /* cor pura com opacidade de 100% */
  /* cor para contraste / segunda cor */
}

.ambar.acessibilidade.zoom-20 {
  zoom: 120%;
  width: 120%;
}

.ambar.acessibilidade.zoom-40 {
  zoom: 140%;
  width: 140%;
}

.ambar.acessibilidade.zoom-60 {
  zoom: 160%;
  width: 160%;
}

.ambar.acessibilidade.zoom-80 {
  zoom: 180%;
  width: 180%;
}

.ambar.acessibilidade.zoom-100 {
  width: 200%;
  zoom: 200%;
}

.ambar.acessibilidade.negrito,
.ambar.acessibilidade.negrito * {
  font-weight: 900 !important;
}

.ambar.acessibilidade.escala-cinza {
  filter: grayscale(1);
}

.ambar.acessibilidade.invertido {
  filter: invert(100%);
}

.ambar .corFundo {
  background: rgba(10, 36, 62, 0.4) !important;
}

.ambar .corFundo2 {
  background: #d29b00 !important;
}

.ambar .htema:hover,
.ambar .bktema,
.ambar .z-window,
.ambar .tag2,
.ambar .modal-title,
.ambar .hover3:hover,
.ambar .btnTema:hover {
  background-color: #d29b00 !important;
}

.ambar .htema:hover:after,
.ambar .bktema:after,
.ambar .z-window:after,
.ambar .tag2:after,
.ambar .modal-title:after,
.ambar .hover3:hover:after,
.ambar .btnTema:hover:after {
  border-top-color: #d29b00 !important;
}

.ambar .bktema10 {
  background-color: rgba(210, 155, 0, 0.1) !important;
}

.ambar .bktema20 {
  background-color: rgba(210, 155, 0, 0.2) !important;
}

.ambar .bktema30 {
  background-color: rgba(210, 155, 0, 0.3) !important;
}

.ambar .bktema40 {
  background-color: rgba(210, 155, 0, 0.4) !important;
}

.ambar .bktema50,
.ambar .linhaMenu:hover {
  background-color: rgba(210, 155, 0, 0.5) !important;
}

.ambar .bktema60 {
  background-color: rgba(210, 155, 0, 0.6) !important;
}

.ambar .bktema70 {
  background-color: rgba(210, 155, 0, 0.7) !important;
}

.ambar .bktema80 {
  background-color: rgba(210, 155, 0, 0.8) !important;
}

.ambar .bktema90 {
  background-color: rgba(210, 155, 0, 0.9) !important;
}

.ambar .btnMenuEnquete .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.ambar .btnMenuEnquete:hover .cortxt {
  color: #d29b00 !important;
}

.ambar .btnMenuEnquete .ativo {
  color: #d29b00 !important;
}

.ambar .btnMenuEnquete {
  box-shadow: 0px -1px 0px 0px rgba(210, 155, 0, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.ambar .btnMenuEnquete:hover {
  box-shadow: 0px -5px 0px 0px #d29b00 inset;
  border: none;
}

.ambar .btnMenuAdm .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.ambar .btnMenuAdm:hover .cortxt {
  color: #d29b00 !important;
}

.ambar .btnMenuAdm .ativo {
  color: #d29b00 !important;
}

.ambar .btnMenuAdm {
  box-shadow: 0px -1px 0px 0px rgba(210, 155, 0, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.ambar .btnMenuAdm:hover {
  box-shadow: 0px -5px 0px 0px #d29b00 inset;
  border: none;
}

.ambar .ativo2 {
  box-shadow: 0px -5px 0px 0px rgba(210, 155, 0, 0.5) inset;
  border: none;
  transition: All 0.5s ease;
}

.ambar .btnall2 {
  box-shadow: 300px 0px 0px 0px #d29b00 inset;
  border: none;
  transition: All 0.2s ease;
}

.ambar .btnall2:hover {
  box-shadow: 0px 0px 0px 0px #d29b00 inset;
  border: none;
}

.ambar .bktemadegrade {
  background: linear-gradient(-60deg, #d29b00, #cde1f3) !important;
  /* degradê */
}

.ambar body,
.ambar .hover1:hover,
.ambar .btnNumber:hover,
.ambar .cortxt {
  color: #d29b00 !important;
}

.ambar .cortxt.qrcode path:nth-child(2) {
  fill: #d29b00 !important;
}

.ambar .cortxt10 {
  color: rgba(210, 155, 0, 0.1) !important;
}

.ambar .cortxt20 {
  color: rgba(210, 155, 0, 0.2) !important;
}

.ambar .cortxt30 {
  color: rgba(210, 155, 0, 0.3) !important;
}

.ambar .totalFiltro:before,
.ambar .totalFiltro:after,
.ambar .cortxt40 {
  color: rgba(210, 155, 0, 0.4) !important;
}

.ambar .cortxt50 {
  color: rgba(210, 155, 0, 0.5) !important;
}

.ambar .cortxt60 {
  color: rgba(210, 155, 0, 0.6) !important;
}

.ambar .btnAzul,
.ambar .cortxt70 {
  color: rgba(210, 155, 0, 0.7) !important;
}

.ambar .cortxt80 {
  color: rgba(210, 155, 0, 0.8) !important;
}

.ambar .cortxt90 {
  color: rgba(210, 155, 0, 0.9) !important;
}

.ambar .cortxt2 {
  color: #fff !important;
}

.ambar .b-tema {
  border-color: #d29b00 !important;
}

.ambar .b-none {
  border: none !important;
}

.ambar .capitalizar {
  text-transform: uppercase !important;
}

.ambar .b-tema10 {
  border-color: rgba(210, 155, 0, 0.1) !important;
}

.ambar .b-tema20 {
  border-color: rgba(210, 155, 0, 0.2) !important;
}

.ambar .b-tema30 {
  border-color: rgba(210, 155, 0, 0.3) !important;
}

.ambar .b-tema40 {
  border-color: rgba(210, 155, 0, 0.4) !important;
}

.ambar .b-tema50 {
  border-color: rgba(210, 155, 0, 0.5) !important;
}

.ambar .b-tema70 {
  border-color: rgba(210, 155, 0, 0.7) !important;
}

.ambar .b-tema80 {
  border-color: rgba(210, 155, 0, 0.8) !important;
}

.ambar .b-tema90 {
  border-color: rgba(210, 155, 0, 0.9) !important;
}

.ambar .stema {
  box-shadow: 1px 6px 10px 1px rgba(210, 155, 0, 0.4) !important;
}

.ambar .tag2:after {
  border-color: #d29b00 transparent transparent transparent;
}

.ambar .detaque_titulo1 {
  padding: 10px 10px 10px 22px;
  border-left: 8px solid rgba(210, 155, 0, 0.2);
  /* */
}

.ambar .imgTemaSelect {
  outline: 1px solid rgba(210, 155, 0, 0.2);
}

.ambar .z-window-header {
  color: white !important;
}

.ambar .login1 {
  box-shadow: 0px 0px 9px 7px rgba(210, 155, 0, 0.2);
  border: 3px solid rgba(210, 155, 0, 0.29);
  background: rgba(255, 255, 255, 0.9);
}

.ambar .logintit {
  box-shadow: 0px 2px 18px rgba(210, 155, 0, 0.1);
  /* cor pura com opacidade de 10% */
}

.ambar .footerLogin {
  background: rgba(10, 36, 62, 0.4);
  /* cor pura + escura com opacidade de 40% */
}

.ambar .imgLogoSelect {
  border: 1px solid #d29b00;
}

.ambar .linhabot01 {
  background: #f9f9f9;
}

.ambar .linhabot02 {
  background: #fff;
}

.ambar button {
  border: groove rgba(255, 255, 255, 0.5);
}

.ambar button:hover {
  border: groove rgba(255, 255, 255, 0.8);
}

.ambar button:active {
  border: groove rgba(210, 155, 0, 0.2);
}

.ambar .z-button,
.ambar .z-button:focus {
  color: #991426;
}

.ambar .z-button:hover {
  background: #000;
  color: #fff;
}

.ambar .z-button:active {
  background: #008000;
  color: #fff;
}

.ambar .btn1,
.ambar .btn1:focus {
  background: #d29b00;
  color: #fff;
}

.ametista {
  /* cor pura com opacidade de 100% */
  /* cor para contraste / segunda cor */
}

.ametista.acessibilidade.zoom-20 {
  zoom: 120%;
  width: 120%;
}

.ametista.acessibilidade.zoom-40 {
  zoom: 140%;
  width: 140%;
}

.ametista.acessibilidade.zoom-60 {
  zoom: 160%;
  width: 160%;
}

.ametista.acessibilidade.zoom-80 {
  zoom: 180%;
  width: 180%;
}

.ametista.acessibilidade.zoom-100 {
  width: 200%;
  zoom: 200%;
}

.ametista.acessibilidade.negrito,
.ametista.acessibilidade.negrito * {
  font-weight: 900 !important;
}

.ametista.acessibilidade.escala-cinza {
  filter: grayscale(1);
}

.ametista.acessibilidade.invertido {
  filter: invert(100%);
}

.ametista .corFundo {
  background: rgba(10, 36, 62, 0.4) !important;
}

.ametista .corFundo2 {
  background: #42095f !important;
}

.ametista .htema:hover,
.ametista .bktema,
.ametista .z-window,
.ametista .tag2,
.ametista .modal-title,
.ametista .hover3:hover,
.ametista .btnTema:hover {
  background-color: #42095f !important;
}

.ametista .htema:hover:after,
.ametista .bktema:after,
.ametista .z-window:after,
.ametista .tag2:after,
.ametista .modal-title:after,
.ametista .hover3:hover:after,
.ametista .btnTema:hover:after {
  border-top-color: #42095f !important;
}

.ametista .bktema10 {
  background-color: rgba(66, 9, 95, 0.1) !important;
}

.ametista .bktema20 {
  background-color: rgba(66, 9, 95, 0.2) !important;
}

.ametista .bktema30 {
  background-color: rgba(66, 9, 95, 0.3) !important;
}

.ametista .bktema40 {
  background-color: rgba(66, 9, 95, 0.4) !important;
}

.ametista .bktema50,
.ametista .linhaMenu:hover {
  background-color: rgba(66, 9, 95, 0.5) !important;
}

.ametista .bktema60 {
  background-color: rgba(66, 9, 95, 0.6) !important;
}

.ametista .bktema70 {
  background-color: rgba(66, 9, 95, 0.7) !important;
}

.ametista .bktema80 {
  background-color: rgba(66, 9, 95, 0.8) !important;
}

.ametista .bktema90 {
  background-color: rgba(66, 9, 95, 0.9) !important;
}

.ametista .btnMenuEnquete .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.ametista .btnMenuEnquete:hover .cortxt {
  color: #42095f !important;
}

.ametista .btnMenuEnquete .ativo {
  color: #42095f !important;
}

.ametista .btnMenuEnquete {
  box-shadow: 0px -1px 0px 0px rgba(66, 9, 95, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.ametista .btnMenuEnquete:hover {
  box-shadow: 0px -5px 0px 0px #42095f inset;
  border: none;
}

.ametista .btnMenuAdm .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.ametista .btnMenuAdm:hover .cortxt {
  color: #42095f !important;
}

.ametista .btnMenuAdm .ativo {
  color: #42095f !important;
}

.ametista .btnMenuAdm {
  box-shadow: 0px -1px 0px 0px rgba(66, 9, 95, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.ametista .btnMenuAdm:hover {
  box-shadow: 0px -5px 0px 0px #42095f inset;
  border: none;
}

.ametista .ativo2 {
  box-shadow: 0px -5px 0px 0px rgba(66, 9, 95, 0.5) inset;
  border: none;
  transition: All 0.5s ease;
}

.ametista .btnall2 {
  box-shadow: 300px 0px 0px 0px #42095f inset;
  border: none;
  transition: All 0.2s ease;
}

.ametista .btnall2:hover {
  box-shadow: 0px 0px 0px 0px #42095f inset;
  border: none;
}

.ametista .bktemadegrade {
  background: linear-gradient(-60deg, #42095f, #cde1f3) !important;
  /* degradê */
}

.ametista body,
.ametista .hover1:hover,
.ametista .btnNumber:hover,
.ametista .cortxt {
  color: #42095f !important;
}

.ametista .cortxt.qrcode path:nth-child(2) {
  fill: #42095f !important;
}

.ametista .cortxt10 {
  color: rgba(66, 9, 95, 0.1) !important;
}

.ametista .cortxt20 {
  color: rgba(66, 9, 95, 0.2) !important;
}

.ametista .cortxt30 {
  color: rgba(66, 9, 95, 0.3) !important;
}

.ametista .totalFiltro:before,
.ametista .totalFiltro:after,
.ametista .cortxt40 {
  color: rgba(66, 9, 95, 0.4) !important;
}

.ametista .cortxt50 {
  color: rgba(66, 9, 95, 0.5) !important;
}

.ametista .cortxt60 {
  color: rgba(66, 9, 95, 0.6) !important;
}

.ametista .btnAzul,
.ametista .cortxt70 {
  color: rgba(66, 9, 95, 0.7) !important;
}

.ametista .cortxt80 {
  color: rgba(66, 9, 95, 0.8) !important;
}

.ametista .cortxt90 {
  color: rgba(66, 9, 95, 0.9) !important;
}

.ametista .cortxt2 {
  color: #fff !important;
}

.ametista .b-tema {
  border-color: #42095f !important;
}

.ametista .b-none {
  border: none !important;
}

.ametista .capitalizar {
  text-transform: uppercase !important;
}

.ametista .b-tema10 {
  border-color: rgba(66, 9, 95, 0.1) !important;
}

.ametista .b-tema20 {
  border-color: rgba(66, 9, 95, 0.2) !important;
}

.ametista .b-tema30 {
  border-color: rgba(66, 9, 95, 0.3) !important;
}

.ametista .b-tema40 {
  border-color: rgba(66, 9, 95, 0.4) !important;
}

.ametista .b-tema50 {
  border-color: rgba(66, 9, 95, 0.5) !important;
}

.ametista .b-tema70 {
  border-color: rgba(66, 9, 95, 0.7) !important;
}

.ametista .b-tema80 {
  border-color: rgba(66, 9, 95, 0.8) !important;
}

.ametista .b-tema90 {
  border-color: rgba(66, 9, 95, 0.9) !important;
}

.ametista .stema {
  box-shadow: 1px 6px 10px 1px rgba(66, 9, 95, 0.4) !important;
}

.ametista .tag2:after {
  border-color: #42095f transparent transparent transparent;
}

.ametista .detaque_titulo1 {
  padding: 10px 10px 10px 22px;
  border-left: 8px solid rgba(66, 9, 95, 0.2);
  /* */
}

.ametista .imgTemaSelect {
  outline: 1px solid rgba(66, 9, 95, 0.2);
}

.ametista .z-window-header {
  color: white !important;
}

.ametista .login1 {
  box-shadow: 0px 0px 9px 7px rgba(66, 9, 95, 0.2);
  border: 3px solid rgba(66, 9, 95, 0.29);
  background: rgba(255, 255, 255, 0.9);
}

.ametista .logintit {
  box-shadow: 0px 2px 18px rgba(66, 9, 95, 0.1);
  /* cor pura com opacidade de 10% */
}

.ametista .footerLogin {
  background: rgba(10, 36, 62, 0.4);
  /* cor pura + escura com opacidade de 40% */
}

.ametista .imgLogoSelect {
  border: 1px solid #42095f;
}

.ametista .linhabot01 {
  background: #f9f9f9;
}

.ametista .linhabot02 {
  background: #fff;
}

.ametista button {
  border: groove rgba(255, 255, 255, 0.5);
}

.ametista button:hover {
  border: groove rgba(255, 255, 255, 0.8);
}

.ametista button:active {
  border: groove rgba(66, 9, 95, 0.2);
}

.ametista .z-button,
.ametista .z-button:focus {
  color: #991426;
}

.ametista .z-button:hover {
  background: #000;
  color: #fff;
}

.ametista .z-button:active {
  background: #008000;
  color: #fff;
}

.ametista .btn1,
.ametista .btn1:focus {
  background: #42095f;
  color: #fff;
}

.cristal {
  /* cor pura com opacidade de 100% */
  /* cor para contraste / segunda cor */
}

.cristal.acessibilidade.zoom-20 {
  zoom: 120%;
  width: 120%;
}

.cristal.acessibilidade.zoom-40 {
  zoom: 140%;
  width: 140%;
}

.cristal.acessibilidade.zoom-60 {
  zoom: 160%;
  width: 160%;
}

.cristal.acessibilidade.zoom-80 {
  zoom: 180%;
  width: 180%;
}

.cristal.acessibilidade.zoom-100 {
  width: 200%;
  zoom: 200%;
}

.cristal.acessibilidade.negrito,
.cristal.acessibilidade.negrito * {
  font-weight: 900 !important;
}

.cristal.acessibilidade.escala-cinza {
  filter: grayscale(1);
}

.cristal.acessibilidade.invertido {
  filter: invert(100%);
}

.cristal .corFundo {
  background: rgba(111, 111, 113, 0.4) !important;
}

.cristal .corFundo2 {
  background: #0a243e !important;
}

.cristal .htema:hover,
.cristal .bktema,
.cristal .z-window,
.cristal .tag2,
.cristal .modal-title,
.cristal .hover3:hover,
.cristal .btnTema:hover {
  background-color: #0a243e !important;
}

.cristal .htema:hover:after,
.cristal .bktema:after,
.cristal .z-window:after,
.cristal .tag2:after,
.cristal .modal-title:after,
.cristal .hover3:hover:after,
.cristal .btnTema:hover:after {
  border-top-color: #0a243e !important;
}

.cristal .bktema10 {
  background-color: rgba(10, 36, 62, 0.1) !important;
}

.cristal .bktema20 {
  background-color: rgba(10, 36, 62, 0.2) !important;
}

.cristal .bktema30 {
  background-color: rgba(10, 36, 62, 0.3) !important;
}

.cristal .bktema40 {
  background-color: rgba(10, 36, 62, 0.4) !important;
}

.cristal .bktema50,
.cristal .linhaMenu:hover {
  background-color: rgba(10, 36, 62, 0.5) !important;
}

.cristal .bktema60 {
  background-color: rgba(10, 36, 62, 0.6) !important;
}

.cristal .bktema70 {
  background-color: rgba(10, 36, 62, 0.7) !important;
}

.cristal .bktema80 {
  background-color: rgba(10, 36, 62, 0.8) !important;
}

.cristal .bktema90 {
  background-color: rgba(10, 36, 62, 0.9) !important;
}

.cristal .btnMenuEnquete .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.cristal .btnMenuEnquete:hover .cortxt {
  color: #0a243e !important;
}

.cristal .btnMenuEnquete .ativo {
  color: #0a243e !important;
}

.cristal .btnMenuEnquete {
  box-shadow: 0px -1px 0px 0px rgba(10, 36, 62, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.cristal .btnMenuEnquete:hover {
  box-shadow: 0px -5px 0px 0px #0a243e inset;
  border: none;
}

.cristal .btnMenuAdm .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.cristal .btnMenuAdm:hover .cortxt {
  color: #0a243e !important;
}

.cristal .btnMenuAdm .ativo {
  color: #0a243e !important;
}

.cristal .btnMenuAdm {
  box-shadow: 0px -1px 0px 0px rgba(10, 36, 62, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.cristal .btnMenuAdm:hover {
  box-shadow: 0px -5px 0px 0px #0a243e inset;
  border: none;
}

.cristal .ativo2 {
  box-shadow: 0px -5px 0px 0px rgba(10, 36, 62, 0.5) inset;
  border: none;
  transition: All 0.5s ease;
}

.cristal .btnall2 {
  box-shadow: 300px 0px 0px 0px #0a243e inset;
  border: none;
  transition: All 0.2s ease;
}

.cristal .btnall2:hover {
  box-shadow: 0px 0px 0px 0px #0a243e inset;
  border: none;
}

.cristal .bktemadegrade {
  background: linear-gradient(-60deg, #0a243e, #cde1f3) !important;
  /* degradê */
}

.cristal body,
.cristal .hover1:hover,
.cristal .btnNumber:hover,
.cristal .cortxt {
  color: #0a243e !important;
}

.cristal .cortxt.qrcode path:nth-child(2) {
  fill: #0a243e !important;
}

.cristal .cortxt10 {
  color: rgba(10, 36, 62, 0.1) !important;
}

.cristal .cortxt20 {
  color: rgba(10, 36, 62, 0.2) !important;
}

.cristal .cortxt30 {
  color: rgba(10, 36, 62, 0.3) !important;
}

.cristal .totalFiltro:before,
.cristal .totalFiltro:after,
.cristal .cortxt40 {
  color: rgba(10, 36, 62, 0.4) !important;
}

.cristal .cortxt50 {
  color: rgba(10, 36, 62, 0.5) !important;
}

.cristal .cortxt60 {
  color: rgba(10, 36, 62, 0.6) !important;
}

.cristal .btnAzul,
.cristal .cortxt70 {
  color: rgba(10, 36, 62, 0.7) !important;
}

.cristal .cortxt80 {
  color: rgba(10, 36, 62, 0.8) !important;
}

.cristal .cortxt90 {
  color: rgba(10, 36, 62, 0.9) !important;
}

.cristal .cortxt2 {
  color: #fff !important;
}

.cristal .b-tema {
  border-color: #0a243e !important;
}

.cristal .b-none {
  border: none !important;
}

.cristal .capitalizar {
  text-transform: uppercase !important;
}

.cristal .b-tema10 {
  border-color: rgba(10, 36, 62, 0.1) !important;
}

.cristal .b-tema20 {
  border-color: rgba(10, 36, 62, 0.2) !important;
}

.cristal .b-tema30 {
  border-color: rgba(10, 36, 62, 0.3) !important;
}

.cristal .b-tema40 {
  border-color: rgba(10, 36, 62, 0.4) !important;
}

.cristal .b-tema50 {
  border-color: rgba(10, 36, 62, 0.5) !important;
}

.cristal .b-tema70 {
  border-color: rgba(10, 36, 62, 0.7) !important;
}

.cristal .b-tema80 {
  border-color: rgba(10, 36, 62, 0.8) !important;
}

.cristal .b-tema90 {
  border-color: rgba(10, 36, 62, 0.9) !important;
}

.cristal .stema {
  box-shadow: 1px 6px 10px 1px rgba(10, 36, 62, 0.4) !important;
}

.cristal .tag2:after {
  border-color: #0a243e transparent transparent transparent;
}

.cristal .detaque_titulo1 {
  padding: 10px 10px 10px 22px;
  border-left: 8px solid rgba(10, 36, 62, 0.2);
  /* */
}

.cristal .imgTemaSelect {
  outline: 1px solid rgba(10, 36, 62, 0.2);
}

.cristal .z-window-header {
  color: white !important;
}

.cristal .login1 {
  box-shadow: 0px 0px 9px 7px rgba(10, 36, 62, 0.2);
  border: 3px solid rgba(10, 36, 62, 0.29);
  background: rgba(255, 255, 255, 0.9);
}

.cristal .logintit {
  box-shadow: 0px 2px 18px rgba(10, 36, 62, 0.1);
  /* cor pura com opacidade de 10% */
}

.cristal .footerLogin {
  background: rgba(111, 111, 113, 0.4);
  /* cor pura + escura com opacidade de 40% */
}

.cristal .imgLogoSelect {
  border: 1px solid #0a243e;
}

.cristal .linhabot01 {
  background: #f9f9f9;
}

.cristal .linhabot02 {
  background: #fff;
}

.cristal button {
  border: groove rgba(255, 255, 255, 0.5);
}

.cristal button:hover {
  border: groove rgba(255, 255, 255, 0.8);
}

.cristal button:active {
  border: groove rgba(10, 36, 62, 0.2);
}

.cristal .z-button,
.cristal .z-button:focus {
  color: #991426;
}

.cristal .z-button:hover {
  background: #000;
  color: #fff;
}

.cristal .z-button:active {
  background: #008000;
  color: #fff;
}

.cristal .btn1,
.cristal .btn1:focus {
  background: #0a243e;
  color: #fff;
}

.esmeralda {
  /* cor pura com opacidade de 100% */
  /* cor para contraste / segunda cor */
}

.esmeralda.acessibilidade.zoom-20 {
  zoom: 120%;
  width: 120%;
}

.esmeralda.acessibilidade.zoom-40 {
  zoom: 140%;
  width: 140%;
}

.esmeralda.acessibilidade.zoom-60 {
  zoom: 160%;
  width: 160%;
}

.esmeralda.acessibilidade.zoom-80 {
  zoom: 180%;
  width: 180%;
}

.esmeralda.acessibilidade.zoom-100 {
  width: 200%;
  zoom: 200%;
}

.esmeralda.acessibilidade.negrito,
.esmeralda.acessibilidade.negrito * {
  font-weight: 900 !important;
}

.esmeralda.acessibilidade.escala-cinza {
  filter: grayscale(1);
}

.esmeralda.acessibilidade.invertido {
  filter: invert(100%);
}

.esmeralda .corFundo {
  background: rgba(10, 36, 62, 0.4) !important;
}

.esmeralda .corFundo2 {
  background: #074a31 !important;
}

.esmeralda .htema:hover,
.esmeralda .bktema,
.esmeralda .z-window,
.esmeralda .tag2,
.esmeralda .modal-title,
.esmeralda .hover3:hover,
.esmeralda .btnTema:hover {
  background-color: #074a31 !important;
}

.esmeralda .htema:hover:after,
.esmeralda .bktema:after,
.esmeralda .z-window:after,
.esmeralda .tag2:after,
.esmeralda .modal-title:after,
.esmeralda .hover3:hover:after,
.esmeralda .btnTema:hover:after {
  border-top-color: #074a31 !important;
}

.esmeralda .bktema10 {
  background-color: rgba(7, 74, 49, 0.1) !important;
}

.esmeralda .bktema20 {
  background-color: rgba(7, 74, 49, 0.2) !important;
}

.esmeralda .bktema30 {
  background-color: rgba(7, 74, 49, 0.3) !important;
}

.esmeralda .bktema40 {
  background-color: rgba(7, 74, 49, 0.4) !important;
}

.esmeralda .bktema50,
.esmeralda .linhaMenu:hover {
  background-color: rgba(7, 74, 49, 0.5) !important;
}

.esmeralda .bktema60 {
  background-color: rgba(7, 74, 49, 0.6) !important;
}

.esmeralda .bktema70 {
  background-color: rgba(7, 74, 49, 0.7) !important;
}

.esmeralda .bktema80 {
  background-color: rgba(7, 74, 49, 0.8) !important;
}

.esmeralda .bktema90 {
  background-color: rgba(7, 74, 49, 0.9) !important;
}

.esmeralda .btnMenuEnquete .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.esmeralda .btnMenuEnquete:hover .cortxt {
  color: #074a31 !important;
}

.esmeralda .btnMenuEnquete .ativo {
  color: #074a31 !important;
}

.esmeralda .btnMenuEnquete {
  box-shadow: 0px -1px 0px 0px rgba(7, 74, 49, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.esmeralda .btnMenuEnquete:hover {
  box-shadow: 0px -5px 0px 0px #074a31 inset;
  border: none;
}

.esmeralda .btnMenuAdm .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.esmeralda .btnMenuAdm:hover .cortxt {
  color: #074a31 !important;
}

.esmeralda .btnMenuAdm .ativo {
  color: #074a31 !important;
}

.esmeralda .btnMenuAdm {
  box-shadow: 0px -1px 0px 0px rgba(7, 74, 49, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.esmeralda .btnMenuAdm:hover {
  box-shadow: 0px -5px 0px 0px #074a31 inset;
  border: none;
}

.esmeralda .ativo2 {
  box-shadow: 0px -5px 0px 0px rgba(7, 74, 49, 0.5) inset;
  border: none;
  transition: All 0.5s ease;
}

.esmeralda .btnall2 {
  box-shadow: 300px 0px 0px 0px #074a31 inset;
  border: none;
  transition: All 0.2s ease;
}

.esmeralda .btnall2:hover {
  box-shadow: 0px 0px 0px 0px #074a31 inset;
  border: none;
}

.esmeralda .bktemadegrade {
  background: linear-gradient(-60deg, #074a31, #cde1f3) !important;
  /* degradê */
}

.esmeralda body,
.esmeralda .hover1:hover,
.esmeralda .btnNumber:hover,
.esmeralda .cortxt {
  color: #074a31 !important;
}

.esmeralda .cortxt.qrcode path:nth-child(2) {
  fill: #074a31 !important;
}

.esmeralda .cortxt10 {
  color: rgba(7, 74, 49, 0.1) !important;
}

.esmeralda .cortxt20 {
  color: rgba(7, 74, 49, 0.2) !important;
}

.esmeralda .cortxt30 {
  color: rgba(7, 74, 49, 0.3) !important;
}

.esmeralda .totalFiltro:before,
.esmeralda .totalFiltro:after,
.esmeralda .cortxt40 {
  color: rgba(7, 74, 49, 0.4) !important;
}

.esmeralda .cortxt50 {
  color: rgba(7, 74, 49, 0.5) !important;
}

.esmeralda .cortxt60 {
  color: rgba(7, 74, 49, 0.6) !important;
}

.esmeralda .btnAzul,
.esmeralda .cortxt70 {
  color: rgba(7, 74, 49, 0.7) !important;
}

.esmeralda .cortxt80 {
  color: rgba(7, 74, 49, 0.8) !important;
}

.esmeralda .cortxt90 {
  color: rgba(7, 74, 49, 0.9) !important;
}

.esmeralda .cortxt2 {
  color: #fff !important;
}

.esmeralda .b-tema {
  border-color: #074a31 !important;
}

.esmeralda .b-none {
  border: none !important;
}

.esmeralda .capitalizar {
  text-transform: uppercase !important;
}

.esmeralda .b-tema10 {
  border-color: rgba(7, 74, 49, 0.1) !important;
}

.esmeralda .b-tema20 {
  border-color: rgba(7, 74, 49, 0.2) !important;
}

.esmeralda .b-tema30 {
  border-color: rgba(7, 74, 49, 0.3) !important;
}

.esmeralda .b-tema40 {
  border-color: rgba(7, 74, 49, 0.4) !important;
}

.esmeralda .b-tema50 {
  border-color: rgba(7, 74, 49, 0.5) !important;
}

.esmeralda .b-tema70 {
  border-color: rgba(7, 74, 49, 0.7) !important;
}

.esmeralda .b-tema80 {
  border-color: rgba(7, 74, 49, 0.8) !important;
}

.esmeralda .b-tema90 {
  border-color: rgba(7, 74, 49, 0.9) !important;
}

.esmeralda .stema {
  box-shadow: 1px 6px 10px 1px rgba(7, 74, 49, 0.4) !important;
}

.esmeralda .tag2:after {
  border-color: #074a31 transparent transparent transparent;
}

.esmeralda .detaque_titulo1 {
  padding: 10px 10px 10px 22px;
  border-left: 8px solid rgba(7, 74, 49, 0.2);
  /* */
}

.esmeralda .imgTemaSelect {
  outline: 1px solid rgba(7, 74, 49, 0.2);
}

.esmeralda .z-window-header {
  color: white !important;
}

.esmeralda .login1 {
  box-shadow: 0px 0px 9px 7px rgba(7, 74, 49, 0.2);
  border: 3px solid rgba(7, 74, 49, 0.29);
  background: rgba(255, 255, 255, 0.9);
}

.esmeralda .logintit {
  box-shadow: 0px 2px 18px rgba(7, 74, 49, 0.1);
  /* cor pura com opacidade de 10% */
}

.esmeralda .footerLogin {
  background: rgba(10, 36, 62, 0.4);
  /* cor pura + escura com opacidade de 40% */
}

.esmeralda .imgLogoSelect {
  border: 1px solid #074a31;
}

.esmeralda .linhabot01 {
  background: #f9f9f9;
}

.esmeralda .linhabot02 {
  background: #fff;
}

.esmeralda button {
  border: groove rgba(255, 255, 255, 0.5);
}

.esmeralda button:hover {
  border: groove rgba(255, 255, 255, 0.8);
}

.esmeralda button:active {
  border: groove rgba(7, 74, 49, 0.2);
}

.esmeralda .z-button,
.esmeralda .z-button:focus {
  color: #991426;
}

.esmeralda .z-button:hover {
  background: #000;
  color: #fff;
}

.esmeralda .z-button:active {
  background: #008000;
  color: #fff;
}

.esmeralda .btn1,
.esmeralda .btn1:focus {
  background: #074a31;
  color: #fff;
}

.oceanus {
  /* cor pura com opacidade de 100% */
  /* cor para contraste / segunda cor */
}

.oceanus.acessibilidade.zoom-20 {
  zoom: 120%;
  width: 120%;
}

.oceanus.acessibilidade.zoom-40 {
  zoom: 140%;
  width: 140%;
}

.oceanus.acessibilidade.zoom-60 {
  zoom: 160%;
  width: 160%;
}

.oceanus.acessibilidade.zoom-80 {
  zoom: 180%;
  width: 180%;
}

.oceanus.acessibilidade.zoom-100 {
  width: 200%;
  zoom: 200%;
}

.oceanus.acessibilidade.negrito,
.oceanus.acessibilidade.negrito * {
  font-weight: 900 !important;
}

.oceanus.acessibilidade.escala-cinza {
  filter: grayscale(1);
}

.oceanus.acessibilidade.invertido {
  filter: invert(100%);
}

.oceanus .corFundo {
  background: rgba(10, 36, 62, 0.4) !important;
}

.oceanus .corFundo2 {
  background: #3096ff !important;
}

.oceanus .htema:hover,
.oceanus .bktema,
.oceanus .z-window,
.oceanus .tag2,
.oceanus .modal-title,
.oceanus .hover3:hover,
.oceanus .btnTema:hover {
  background-color: #3096ff !important;
}

.oceanus .htema:hover:after,
.oceanus .bktema:after,
.oceanus .z-window:after,
.oceanus .tag2:after,
.oceanus .modal-title:after,
.oceanus .hover3:hover:after,
.oceanus .btnTema:hover:after {
  border-top-color: #3096ff !important;
}

.oceanus .bktema10 {
  background-color: rgba(48, 150, 255, 0.1) !important;
}

.oceanus .bktema20 {
  background-color: rgba(48, 150, 255, 0.2) !important;
}

.oceanus .bktema30 {
  background-color: rgba(48, 150, 255, 0.3) !important;
}

.oceanus .bktema40 {
  background-color: rgba(48, 150, 255, 0.4) !important;
}

.oceanus .bktema50,
.oceanus .linhaMenu:hover {
  background-color: rgba(48, 150, 255, 0.5) !important;
}

.oceanus .bktema60 {
  background-color: rgba(48, 150, 255, 0.6) !important;
}

.oceanus .bktema70 {
  background-color: rgba(48, 150, 255, 0.7) !important;
}

.oceanus .bktema80 {
  background-color: rgba(48, 150, 255, 0.8) !important;
}

.oceanus .bktema90 {
  background-color: rgba(48, 150, 255, 0.9) !important;
}

.oceanus .btnMenuEnquete .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.oceanus .btnMenuEnquete:hover .cortxt {
  color: #3096ff !important;
}

.oceanus .btnMenuEnquete .ativo {
  color: #3096ff !important;
}

.oceanus .btnMenuEnquete {
  box-shadow: 0px -1px 0px 0px rgba(48, 150, 255, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.oceanus .btnMenuEnquete:hover {
  box-shadow: 0px -5px 0px 0px #3096ff inset;
  border: none;
}

.oceanus .btnMenuAdm .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.oceanus .btnMenuAdm:hover .cortxt {
  color: #3096ff !important;
}

.oceanus .btnMenuAdm .ativo {
  color: #3096ff !important;
}

.oceanus .btnMenuAdm {
  box-shadow: 0px -1px 0px 0px rgba(48, 150, 255, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.oceanus .btnMenuAdm:hover {
  box-shadow: 0px -5px 0px 0px #3096ff inset;
  border: none;
}

.oceanus .ativo2 {
  box-shadow: 0px -5px 0px 0px rgba(48, 150, 255, 0.5) inset;
  border: none;
  transition: All 0.5s ease;
}

.oceanus .btnall2 {
  box-shadow: 300px 0px 0px 0px #3096ff inset;
  border: none;
  transition: All 0.2s ease;
}

.oceanus .btnall2:hover {
  box-shadow: 0px 0px 0px 0px #3096ff inset;
  border: none;
}

.oceanus .bktemadegrade {
  background: linear-gradient(-60deg, #3096ff, #cde1f3) !important;
  /* degradê */
}

.oceanus body,
.oceanus .hover1:hover,
.oceanus .btnNumber:hover,
.oceanus .cortxt {
  color: #3096ff !important;
}

.oceanus .cortxt.qrcode path:nth-child(2) {
  fill: #3096ff !important;
}

.oceanus .cortxt10 {
  color: rgba(48, 150, 255, 0.1) !important;
}

.oceanus .cortxt20 {
  color: rgba(48, 150, 255, 0.2) !important;
}

.oceanus .cortxt30 {
  color: rgba(48, 150, 255, 0.3) !important;
}

.oceanus .totalFiltro:before,
.oceanus .totalFiltro:after,
.oceanus .cortxt40 {
  color: rgba(48, 150, 255, 0.4) !important;
}

.oceanus .cortxt50 {
  color: rgba(48, 150, 255, 0.5) !important;
}

.oceanus .cortxt60 {
  color: rgba(48, 150, 255, 0.6) !important;
}

.oceanus .btnAzul,
.oceanus .cortxt70 {
  color: rgba(48, 150, 255, 0.7) !important;
}

.oceanus .cortxt80 {
  color: rgba(48, 150, 255, 0.8) !important;
}

.oceanus .cortxt90 {
  color: rgba(48, 150, 255, 0.9) !important;
}

.oceanus .cortxt2 {
  color: #fff !important;
}

.oceanus .b-tema {
  border-color: #3096ff !important;
}

.oceanus .b-none {
  border: none !important;
}

.oceanus .capitalizar {
  text-transform: uppercase !important;
}

.oceanus .b-tema10 {
  border-color: rgba(48, 150, 255, 0.1) !important;
}

.oceanus .b-tema20 {
  border-color: rgba(48, 150, 255, 0.2) !important;
}

.oceanus .b-tema30 {
  border-color: rgba(48, 150, 255, 0.3) !important;
}

.oceanus .b-tema40 {
  border-color: rgba(48, 150, 255, 0.4) !important;
}

.oceanus .b-tema50 {
  border-color: rgba(48, 150, 255, 0.5) !important;
}

.oceanus .b-tema70 {
  border-color: rgba(48, 150, 255, 0.7) !important;
}

.oceanus .b-tema80 {
  border-color: rgba(48, 150, 255, 0.8) !important;
}

.oceanus .b-tema90 {
  border-color: rgba(48, 150, 255, 0.9) !important;
}

.oceanus .stema {
  box-shadow: 1px 6px 10px 1px rgba(48, 150, 255, 0.4) !important;
}

.oceanus .tag2:after {
  border-color: #3096ff transparent transparent transparent;
}

.oceanus .detaque_titulo1 {
  padding: 10px 10px 10px 22px;
  border-left: 8px solid rgba(48, 150, 255, 0.2);
  /* */
}

.oceanus .imgTemaSelect {
  outline: 1px solid rgba(48, 150, 255, 0.2);
}

.oceanus .z-window-header {
  color: white !important;
}

.oceanus .login1 {
  box-shadow: 0px 0px 9px 7px rgba(48, 150, 255, 0.2);
  border: 3px solid rgba(48, 150, 255, 0.29);
  background: rgba(255, 255, 255, 0.9);
}

.oceanus .logintit {
  box-shadow: 0px 2px 18px rgba(48, 150, 255, 0.1);
  /* cor pura com opacidade de 10% */
}

.oceanus .footerLogin {
  background: rgba(10, 36, 62, 0.4);
  /* cor pura + escura com opacidade de 40% */
}

.oceanus .imgLogoSelect {
  border: 1px solid #3096ff;
}

.oceanus .linhabot01 {
  background: #f9f9f9;
}

.oceanus .linhabot02 {
  background: #fff;
}

.oceanus button {
  border: groove rgba(255, 255, 255, 0.5);
}

.oceanus button:hover {
  border: groove rgba(255, 255, 255, 0.8);
}

.oceanus button:active {
  border: groove rgba(48, 150, 255, 0.2);
}

.oceanus .z-button,
.oceanus .z-button:focus {
  color: #991426;
}

.oceanus .z-button:hover {
  background: #000;
  color: #fff;
}

.oceanus .z-button:active {
  background: #008000;
  color: #fff;
}

.oceanus .btn1,
.oceanus .btn1:focus {
  background: #3096ff;
  color: #fff;
}

.rose {
  /* cor pura com opacidade de 100% */
  /* cor para contraste / segunda cor */
}

.rose.acessibilidade.zoom-20 {
  zoom: 120%;
  width: 120%;
}

.rose.acessibilidade.zoom-40 {
  zoom: 140%;
  width: 140%;
}

.rose.acessibilidade.zoom-60 {
  zoom: 160%;
  width: 160%;
}

.rose.acessibilidade.zoom-80 {
  zoom: 180%;
  width: 180%;
}

.rose.acessibilidade.zoom-100 {
  width: 200%;
  zoom: 200%;
}

.rose.acessibilidade.negrito,
.rose.acessibilidade.negrito * {
  font-weight: 900 !important;
}

.rose.acessibilidade.escala-cinza {
  filter: grayscale(1);
}

.rose.acessibilidade.invertido {
  filter: invert(100%);
}

.rose .corFundo {
  background: rgba(10, 36, 62, 0.4) !important;
}

.rose .corFundo2 {
  background: #d65858 !important;
}

.rose .htema:hover,
.rose .bktema,
.rose .z-window,
.rose .tag2,
.rose .modal-title,
.rose .hover3:hover,
.rose .btnTema:hover {
  background-color: #d65858 !important;
}

.rose .htema:hover:after,
.rose .bktema:after,
.rose .z-window:after,
.rose .tag2:after,
.rose .modal-title:after,
.rose .hover3:hover:after,
.rose .btnTema:hover:after {
  border-top-color: #d65858 !important;
}

.rose .bktema10 {
  background-color: rgba(214, 88, 88, 0.1) !important;
}

.rose .bktema20 {
  background-color: rgba(214, 88, 88, 0.2) !important;
}

.rose .bktema30 {
  background-color: rgba(214, 88, 88, 0.3) !important;
}

.rose .bktema40 {
  background-color: rgba(214, 88, 88, 0.4) !important;
}

.rose .bktema50,
.rose .linhaMenu:hover {
  background-color: rgba(214, 88, 88, 0.5) !important;
}

.rose .bktema60 {
  background-color: rgba(214, 88, 88, 0.6) !important;
}

.rose .bktema70 {
  background-color: rgba(214, 88, 88, 0.7) !important;
}

.rose .bktema80 {
  background-color: rgba(214, 88, 88, 0.8) !important;
}

.rose .bktema90 {
  background-color: rgba(214, 88, 88, 0.9) !important;
}

.rose .btnMenuEnquete .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.rose .btnMenuEnquete:hover .cortxt {
  color: #d65858 !important;
}

.rose .btnMenuEnquete .ativo {
  color: #d65858 !important;
}

.rose .btnMenuEnquete {
  box-shadow: 0px -1px 0px 0px rgba(214, 88, 88, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.rose .btnMenuEnquete:hover {
  box-shadow: 0px -5px 0px 0px #d65858 inset;
  border: none;
}

.rose .btnMenuAdm .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.rose .btnMenuAdm:hover .cortxt {
  color: #d65858 !important;
}

.rose .btnMenuAdm .ativo {
  color: #d65858 !important;
}

.rose .btnMenuAdm {
  box-shadow: 0px -1px 0px 0px rgba(214, 88, 88, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.rose .btnMenuAdm:hover {
  box-shadow: 0px -5px 0px 0px #d65858 inset;
  border: none;
}

.rose .ativo2 {
  box-shadow: 0px -5px 0px 0px rgba(214, 88, 88, 0.5) inset;
  border: none;
  transition: All 0.5s ease;
}

.rose .btnall2 {
  box-shadow: 300px 0px 0px 0px #d65858 inset;
  border: none;
  transition: All 0.2s ease;
}

.rose .btnall2:hover {
  box-shadow: 0px 0px 0px 0px #d65858 inset;
  border: none;
}

.rose .bktemadegrade {
  background: linear-gradient(-60deg, #d65858, #cde1f3) !important;
  /* degradê */
}

.rose body,
.rose .hover1:hover,
.rose .btnNumber:hover,
.rose .cortxt {
  color: #d65858 !important;
}

.rose .cortxt.qrcode path:nth-child(2) {
  fill: #d65858 !important;
}

.rose .cortxt10 {
  color: rgba(214, 88, 88, 0.1) !important;
}

.rose .cortxt20 {
  color: rgba(214, 88, 88, 0.2) !important;
}

.rose .cortxt30 {
  color: rgba(214, 88, 88, 0.3) !important;
}

.rose .totalFiltro:before,
.rose .totalFiltro:after,
.rose .cortxt40 {
  color: rgba(214, 88, 88, 0.4) !important;
}

.rose .cortxt50 {
  color: rgba(214, 88, 88, 0.5) !important;
}

.rose .cortxt60 {
  color: rgba(214, 88, 88, 0.6) !important;
}

.rose .btnAzul,
.rose .cortxt70 {
  color: rgba(214, 88, 88, 0.7) !important;
}

.rose .cortxt80 {
  color: rgba(214, 88, 88, 0.8) !important;
}

.rose .cortxt90 {
  color: rgba(214, 88, 88, 0.9) !important;
}

.rose .cortxt2 {
  color: #fff !important;
}

.rose .b-tema {
  border-color: #d65858 !important;
}

.rose .b-none {
  border: none !important;
}

.rose .capitalizar {
  text-transform: uppercase !important;
}

.rose .b-tema10 {
  border-color: rgba(214, 88, 88, 0.1) !important;
}

.rose .b-tema20 {
  border-color: rgba(214, 88, 88, 0.2) !important;
}

.rose .b-tema30 {
  border-color: rgba(214, 88, 88, 0.3) !important;
}

.rose .b-tema40 {
  border-color: rgba(214, 88, 88, 0.4) !important;
}

.rose .b-tema50 {
  border-color: rgba(214, 88, 88, 0.5) !important;
}

.rose .b-tema70 {
  border-color: rgba(214, 88, 88, 0.7) !important;
}

.rose .b-tema80 {
  border-color: rgba(214, 88, 88, 0.8) !important;
}

.rose .b-tema90 {
  border-color: rgba(214, 88, 88, 0.9) !important;
}

.rose .stema {
  box-shadow: 1px 6px 10px 1px rgba(214, 88, 88, 0.4) !important;
}

.rose .tag2:after {
  border-color: #d65858 transparent transparent transparent;
}

.rose .detaque_titulo1 {
  padding: 10px 10px 10px 22px;
  border-left: 8px solid rgba(214, 88, 88, 0.2);
  /* */
}

.rose .imgTemaSelect {
  outline: 1px solid rgba(214, 88, 88, 0.2);
}

.rose .z-window-header {
  color: white !important;
}

.rose .login1 {
  box-shadow: 0px 0px 9px 7px rgba(214, 88, 88, 0.2);
  border: 3px solid rgba(214, 88, 88, 0.29);
  background: rgba(255, 255, 255, 0.9);
}

.rose .logintit {
  box-shadow: 0px 2px 18px rgba(214, 88, 88, 0.1);
  /* cor pura com opacidade de 10% */
}

.rose .footerLogin {
  background: rgba(10, 36, 62, 0.4);
  /* cor pura + escura com opacidade de 40% */
}

.rose .imgLogoSelect {
  border: 1px solid #d65858;
}

.rose .linhabot01 {
  background: #f9f9f9;
}

.rose .linhabot02 {
  background: #fff;
}

.rose button {
  border: groove rgba(255, 255, 255, 0.5);
}

.rose button:hover {
  border: groove rgba(255, 255, 255, 0.8);
}

.rose button:active {
  border: groove rgba(214, 88, 88, 0.2);
}

.rose .z-button,
.rose .z-button:focus {
  color: #991426;
}

.rose .z-button:hover {
  background: #000;
  color: #fff;
}

.rose .z-button:active {
  background: #008000;
  color: #fff;
}

.rose .btn1,
.rose .btn1:focus {
  background: #d65858;
  color: #fff;
}

.rubi {
  /* cor pura com opacidade de 100% */
  /* cor para contraste / segunda cor */
}

.rubi.acessibilidade.zoom-20 {
  zoom: 120%;
  width: 120%;
}

.rubi.acessibilidade.zoom-40 {
  zoom: 140%;
  width: 140%;
}

.rubi.acessibilidade.zoom-60 {
  zoom: 160%;
  width: 160%;
}

.rubi.acessibilidade.zoom-80 {
  zoom: 180%;
  width: 180%;
}

.rubi.acessibilidade.zoom-100 {
  width: 200%;
  zoom: 200%;
}

.rubi.acessibilidade.negrito,
.rubi.acessibilidade.negrito * {
  font-weight: 900 !important;
}

.rubi.acessibilidade.escala-cinza {
  filter: grayscale(1);
}

.rubi.acessibilidade.invertido {
  filter: invert(100%);
}

.rubi .corFundo {
  background: rgba(10, 36, 62, 0.4) !important;
}

.rubi .corFundo2 {
  background: #ac2333 !important;
}

.rubi .htema:hover,
.rubi .bktema,
.rubi .z-window,
.rubi .tag2,
.rubi .modal-title,
.rubi .hover3:hover,
.rubi .btnTema:hover {
  background-color: #ac2333 !important;
}

.rubi .htema:hover:after,
.rubi .bktema:after,
.rubi .z-window:after,
.rubi .tag2:after,
.rubi .modal-title:after,
.rubi .hover3:hover:after,
.rubi .btnTema:hover:after {
  border-top-color: #ac2333 !important;
}

.rubi .bktema10 {
  background-color: rgba(172, 35, 51, 0.1) !important;
}

.rubi .bktema20 {
  background-color: rgba(172, 35, 51, 0.2) !important;
}

.rubi .bktema30 {
  background-color: rgba(172, 35, 51, 0.3) !important;
}

.rubi .bktema40 {
  background-color: rgba(172, 35, 51, 0.4) !important;
}

.rubi .bktema50,
.rubi .linhaMenu:hover {
  background-color: rgba(172, 35, 51, 0.5) !important;
}

.rubi .bktema60 {
  background-color: rgba(172, 35, 51, 0.6) !important;
}

.rubi .bktema70 {
  background-color: rgba(172, 35, 51, 0.7) !important;
}

.rubi .bktema80 {
  background-color: rgba(172, 35, 51, 0.8) !important;
}

.rubi .bktema90 {
  background-color: rgba(172, 35, 51, 0.9) !important;
}

.rubi .btnMenuEnquete .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.rubi .btnMenuEnquete:hover .cortxt {
  color: #ac2333 !important;
}

.rubi .btnMenuEnquete .ativo {
  color: #ac2333 !important;
}

.rubi .btnMenuEnquete {
  box-shadow: 0px -1px 0px 0px rgba(172, 35, 51, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.rubi .btnMenuEnquete:hover {
  box-shadow: 0px -5px 0px 0px #ac2333 inset;
  border: none;
}

.rubi .btnMenuAdm .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.rubi .btnMenuAdm:hover .cortxt {
  color: #ac2333 !important;
}

.rubi .btnMenuAdm .ativo {
  color: #ac2333 !important;
}

.rubi .btnMenuAdm {
  box-shadow: 0px -1px 0px 0px rgba(172, 35, 51, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.rubi .btnMenuAdm:hover {
  box-shadow: 0px -5px 0px 0px #ac2333 inset;
  border: none;
}

.rubi .ativo2 {
  box-shadow: 0px -5px 0px 0px rgba(172, 35, 51, 0.5) inset;
  border: none;
  transition: All 0.5s ease;
}

.rubi .btnall2 {
  box-shadow: 300px 0px 0px 0px #ac2333 inset;
  border: none;
  transition: All 0.2s ease;
}

.rubi .btnall2:hover {
  box-shadow: 0px 0px 0px 0px #ac2333 inset;
  border: none;
}

.rubi .bktemadegrade {
  background: linear-gradient(-60deg, #ac2333, #cde1f3) !important;
  /* degradê */
}

.rubi body,
.rubi .hover1:hover,
.rubi .btnNumber:hover,
.rubi .cortxt {
  color: #ac2333 !important;
}

.rubi .cortxt.qrcode path:nth-child(2) {
  fill: #ac2333 !important;
}

.rubi .cortxt10 {
  color: rgba(172, 35, 51, 0.1) !important;
}

.rubi .cortxt20 {
  color: rgba(172, 35, 51, 0.2) !important;
}

.rubi .cortxt30 {
  color: rgba(172, 35, 51, 0.3) !important;
}

.rubi .totalFiltro:before,
.rubi .totalFiltro:after,
.rubi .cortxt40 {
  color: rgba(172, 35, 51, 0.4) !important;
}

.rubi .cortxt50 {
  color: rgba(172, 35, 51, 0.5) !important;
}

.rubi .cortxt60 {
  color: rgba(172, 35, 51, 0.6) !important;
}

.rubi .btnAzul,
.rubi .cortxt70 {
  color: rgba(172, 35, 51, 0.7) !important;
}

.rubi .cortxt80 {
  color: rgba(172, 35, 51, 0.8) !important;
}

.rubi .cortxt90 {
  color: rgba(172, 35, 51, 0.9) !important;
}

.rubi .cortxt2 {
  color: #fff !important;
}

.rubi .b-tema {
  border-color: #ac2333 !important;
}

.rubi .b-none {
  border: none !important;
}

.rubi .capitalizar {
  text-transform: uppercase !important;
}

.rubi .b-tema10 {
  border-color: rgba(172, 35, 51, 0.1) !important;
}

.rubi .b-tema20 {
  border-color: rgba(172, 35, 51, 0.2) !important;
}

.rubi .b-tema30 {
  border-color: rgba(172, 35, 51, 0.3) !important;
}

.rubi .b-tema40 {
  border-color: rgba(172, 35, 51, 0.4) !important;
}

.rubi .b-tema50 {
  border-color: rgba(172, 35, 51, 0.5) !important;
}

.rubi .b-tema70 {
  border-color: rgba(172, 35, 51, 0.7) !important;
}

.rubi .b-tema80 {
  border-color: rgba(172, 35, 51, 0.8) !important;
}

.rubi .b-tema90 {
  border-color: rgba(172, 35, 51, 0.9) !important;
}

.rubi .stema {
  box-shadow: 1px 6px 10px 1px rgba(172, 35, 51, 0.4) !important;
}

.rubi .tag2:after {
  border-color: #ac2333 transparent transparent transparent;
}

.rubi .detaque_titulo1 {
  padding: 10px 10px 10px 22px;
  border-left: 8px solid rgba(172, 35, 51, 0.2);
  /* */
}

.rubi .imgTemaSelect {
  outline: 1px solid rgba(172, 35, 51, 0.2);
}

.rubi .z-window-header {
  color: white !important;
}

.rubi .login1 {
  box-shadow: 0px 0px 9px 7px rgba(172, 35, 51, 0.2);
  border: 3px solid rgba(172, 35, 51, 0.29);
  background: rgba(255, 255, 255, 0.9);
}

.rubi .logintit {
  box-shadow: 0px 2px 18px rgba(172, 35, 51, 0.1);
  /* cor pura com opacidade de 10% */
}

.rubi .footerLogin {
  background: rgba(10, 36, 62, 0.4);
  /* cor pura + escura com opacidade de 40% */
}

.rubi .imgLogoSelect {
  border: 1px solid #ac2333;
}

.rubi .linhabot01 {
  background: #f9f9f9;
}

.rubi .linhabot02 {
  background: #fff;
}

.rubi button {
  border: groove rgba(255, 255, 255, 0.5);
}

.rubi button:hover {
  border: groove rgba(255, 255, 255, 0.8);
}

.rubi button:active {
  border: groove rgba(172, 35, 51, 0.2);
}

.rubi .z-button,
.rubi .z-button:focus {
  color: #991426;
}

.rubi .z-button:hover {
  background: #000;
  color: #fff;
}

.rubi .z-button:active {
  background: #008000;
  color: #fff;
}

.rubi .btn1,
.rubi .btn1:focus {
  background: #ac2333;
  color: #fff;
}

.safira {
  /* cor pura com opacidade de 100% */
  /* cor para contraste / segunda cor */
}

.safira.acessibilidade.zoom-20 {
  zoom: 120%;
  width: 120%;
}

.safira.acessibilidade.zoom-40 {
  zoom: 140%;
  width: 140%;
}

.safira.acessibilidade.zoom-60 {
  zoom: 160%;
  width: 160%;
}

.safira.acessibilidade.zoom-80 {
  zoom: 180%;
  width: 180%;
}

.safira.acessibilidade.zoom-100 {
  width: 200%;
  zoom: 200%;
}

.safira.acessibilidade.negrito,
.safira.acessibilidade.negrito * {
  font-weight: 900 !important;
}

.safira.acessibilidade.escala-cinza {
  filter: grayscale(1);
}

.safira.acessibilidade.invertido {
  filter: invert(100%);
}

.safira .corFundo {
  background: rgba(10, 36, 62, 0.4) !important;
}

.safira .corFundo2 {
  background: #003366 !important;
}

.safira .htema:hover,
.safira .bktema,
.safira .z-window,
.safira .tag2,
.safira .modal-title,
.safira .hover3:hover,
.safira .btnTema:hover {
  background-color: #003366 !important;
}

.safira .htema:hover:after,
.safira .bktema:after,
.safira .z-window:after,
.safira .tag2:after,
.safira .modal-title:after,
.safira .hover3:hover:after,
.safira .btnTema:hover:after {
  border-top-color: #003366 !important;
}

.safira .bktema10 {
  background-color: rgba(0, 51, 102, 0.1) !important;
}

.safira .bktema20 {
  background-color: rgba(0, 51, 102, 0.2) !important;
}

.safira .bktema30 {
  background-color: rgba(0, 51, 102, 0.3) !important;
}

.safira .bktema40 {
  background-color: rgba(0, 51, 102, 0.4) !important;
}

.safira .bktema50,
.safira .linhaMenu:hover {
  background-color: rgba(0, 51, 102, 0.5) !important;
}

.safira .bktema60 {
  background-color: rgba(0, 51, 102, 0.6) !important;
}

.safira .bktema70 {
  background-color: rgba(0, 51, 102, 0.7) !important;
}

.safira .bktema80 {
  background-color: rgba(0, 51, 102, 0.8) !important;
}

.safira .bktema90 {
  background-color: rgba(0, 51, 102, 0.9) !important;
}

.safira .btnMenuEnquete .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.safira .btnMenuEnquete:hover .cortxt {
  color: #003366 !important;
}

.safira .btnMenuEnquete .ativo {
  color: #003366 !important;
}

.safira .btnMenuEnquete {
  box-shadow: 0px -1px 0px 0px rgba(0, 51, 102, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.safira .btnMenuEnquete:hover {
  box-shadow: 0px -5px 0px 0px #003366 inset;
  border: none;
}

.safira .btnMenuAdm .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.safira .btnMenuAdm:hover .cortxt {
  color: #003366 !important;
}

.safira .btnMenuAdm .ativo {
  color: #003366 !important;
}

.safira .btnMenuAdm {
  box-shadow: 0px -1px 0px 0px rgba(0, 51, 102, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.safira .btnMenuAdm:hover {
  box-shadow: 0px -5px 0px 0px #003366 inset;
  border: none;
}

.safira .ativo2 {
  box-shadow: 0px -5px 0px 0px rgba(0, 51, 102, 0.5) inset;
  border: none;
  transition: All 0.5s ease;
}

.safira .btnall2 {
  box-shadow: 300px 0px 0px 0px #003366 inset;
  border: none;
  transition: All 0.2s ease;
}

.safira .btnall2:hover {
  box-shadow: 0px 0px 0px 0px #003366 inset;
  border: none;
}

.safira .bktemadegrade {
  background: linear-gradient(-60deg, #003366, #cde1f3) !important;
  /* degradê */
}

.safira body,
.safira .hover1:hover,
.safira .btnNumber:hover,
.safira .cortxt {
  color: #003366 !important;
}

.safira .cortxt.qrcode path:nth-child(2) {
  fill: #003366 !important;
}

.safira .cortxt10 {
  color: rgba(0, 51, 102, 0.1) !important;
}

.safira .cortxt20 {
  color: rgba(0, 51, 102, 0.2) !important;
}

.safira .cortxt30 {
  color: rgba(0, 51, 102, 0.3) !important;
}

.safira .totalFiltro:before,
.safira .totalFiltro:after,
.safira .cortxt40 {
  color: rgba(0, 51, 102, 0.4) !important;
}

.safira .cortxt50 {
  color: rgba(0, 51, 102, 0.5) !important;
}

.safira .cortxt60 {
  color: rgba(0, 51, 102, 0.6) !important;
}

.safira .btnAzul,
.safira .cortxt70 {
  color: rgba(0, 51, 102, 0.7) !important;
}

.safira .cortxt80 {
  color: rgba(0, 51, 102, 0.8) !important;
}

.safira .cortxt90 {
  color: rgba(0, 51, 102, 0.9) !important;
}

.safira .cortxt2 {
  color: #fff !important;
}

.safira .b-tema {
  border-color: #003366 !important;
}

.safira .b-none {
  border: none !important;
}

.safira .capitalizar {
  text-transform: uppercase !important;
}

.safira .b-tema10 {
  border-color: rgba(0, 51, 102, 0.1) !important;
}

.safira .b-tema20 {
  border-color: rgba(0, 51, 102, 0.2) !important;
}

.safira .b-tema30 {
  border-color: rgba(0, 51, 102, 0.3) !important;
}

.safira .b-tema40 {
  border-color: rgba(0, 51, 102, 0.4) !important;
}

.safira .b-tema50 {
  border-color: rgba(0, 51, 102, 0.5) !important;
}

.safira .b-tema70 {
  border-color: rgba(0, 51, 102, 0.7) !important;
}

.safira .b-tema80 {
  border-color: rgba(0, 51, 102, 0.8) !important;
}

.safira .b-tema90 {
  border-color: rgba(0, 51, 102, 0.9) !important;
}

.safira .stema {
  box-shadow: 1px 6px 10px 1px rgba(0, 51, 102, 0.4) !important;
}

.safira .tag2:after {
  border-color: #003366 transparent transparent transparent;
}

.safira .detaque_titulo1 {
  padding: 10px 10px 10px 22px;
  border-left: 8px solid rgba(0, 51, 102, 0.2);
  /* */
}

.safira .imgTemaSelect {
  outline: 1px solid rgba(0, 51, 102, 0.2);
}

.safira .z-window-header {
  color: white !important;
}

.safira .login1 {
  box-shadow: 0px 0px 9px 7px rgba(0, 51, 102, 0.2);
  border: 3px solid rgba(0, 51, 102, 0.29);
  background: rgba(255, 255, 255, 0.9);
}

.safira .logintit {
  box-shadow: 0px 2px 18px rgba(0, 51, 102, 0.1);
  /* cor pura com opacidade de 10% */
}

.safira .footerLogin {
  background: rgba(10, 36, 62, 0.4);
  /* cor pura + escura com opacidade de 40% */
}

.safira .imgLogoSelect {
  border: 1px solid #003366;
}

.safira .linhabot01 {
  background: #f9f9f9;
}

.safira .linhabot02 {
  background: #fff;
}

.safira button {
  border: groove rgba(255, 255, 255, 0.5);
}

.safira button:hover {
  border: groove rgba(255, 255, 255, 0.8);
}

.safira button:active {
  border: groove rgba(0, 51, 102, 0.2);
}

.safira .z-button,
.safira .z-button:focus {
  color: #991426;
}

.safira .z-button:hover {
  background: #000;
  color: #fff;
}

.safira .z-button:active {
  background: #008000;
  color: #fff;
}

.safira .btn1,
.safira .btn1:focus {
  background: #003366;
  color: #fff;
}

.topazio {
  /* cor pura com opacidade de 100% */
  /* cor para contraste / segunda cor */
}

.topazio.acessibilidade.zoom-20 {
  zoom: 120%;
  width: 120%;
}

.topazio.acessibilidade.zoom-40 {
  zoom: 140%;
  width: 140%;
}

.topazio.acessibilidade.zoom-60 {
  zoom: 160%;
  width: 160%;
}

.topazio.acessibilidade.zoom-80 {
  zoom: 180%;
  width: 180%;
}

.topazio.acessibilidade.zoom-100 {
  width: 200%;
  zoom: 200%;
}

.topazio.acessibilidade.negrito,
.topazio.acessibilidade.negrito * {
  font-weight: 900 !important;
}

.topazio.acessibilidade.escala-cinza {
  filter: grayscale(1);
}

.topazio.acessibilidade.invertido {
  filter: invert(100%);
}

.topazio .corFundo {
  background: rgba(10, 36, 62, 0.4) !important;
}

.topazio .corFundo2 {
  background: #d04d00 !important;
}

.topazio .htema:hover,
.topazio .bktema,
.topazio .z-window,
.topazio .tag2,
.topazio .modal-title,
.topazio .hover3:hover,
.topazio .btnTema:hover {
  background-color: #d04d00 !important;
}

.topazio .htema:hover:after,
.topazio .bktema:after,
.topazio .z-window:after,
.topazio .tag2:after,
.topazio .modal-title:after,
.topazio .hover3:hover:after,
.topazio .btnTema:hover:after {
  border-top-color: #d04d00 !important;
}

.topazio .bktema10 {
  background-color: rgba(208, 77, 0, 0.1) !important;
}

.topazio .bktema20 {
  background-color: rgba(208, 77, 0, 0.2) !important;
}

.topazio .bktema30 {
  background-color: rgba(208, 77, 0, 0.3) !important;
}

.topazio .bktema40 {
  background-color: rgba(208, 77, 0, 0.4) !important;
}

.topazio .bktema50,
.topazio .linhaMenu:hover {
  background-color: rgba(208, 77, 0, 0.5) !important;
}

.topazio .bktema60 {
  background-color: rgba(208, 77, 0, 0.6) !important;
}

.topazio .bktema70 {
  background-color: rgba(208, 77, 0, 0.7) !important;
}

.topazio .bktema80 {
  background-color: rgba(208, 77, 0, 0.8) !important;
}

.topazio .bktema90 {
  background-color: rgba(208, 77, 0, 0.9) !important;
}

.topazio .btnMenuEnquete .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.topazio .btnMenuEnquete:hover .cortxt {
  color: #d04d00 !important;
}

.topazio .btnMenuEnquete .ativo {
  color: #d04d00 !important;
}

.topazio .btnMenuEnquete {
  box-shadow: 0px -1px 0px 0px rgba(208, 77, 0, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.topazio .btnMenuEnquete:hover {
  box-shadow: 0px -5px 0px 0px #d04d00 inset;
  border: none;
}

.topazio .btnMenuAdm .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.topazio .btnMenuAdm:hover .cortxt {
  color: #d04d00 !important;
}

.topazio .btnMenuAdm .ativo {
  color: #d04d00 !important;
}

.topazio .btnMenuAdm {
  box-shadow: 0px -1px 0px 0px rgba(208, 77, 0, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.topazio .btnMenuAdm:hover {
  box-shadow: 0px -5px 0px 0px #d04d00 inset;
  border: none;
}

.topazio .ativo2 {
  box-shadow: 0px -5px 0px 0px rgba(208, 77, 0, 0.5) inset;
  border: none;
  transition: All 0.5s ease;
}

.topazio .btnall2 {
  box-shadow: 300px 0px 0px 0px #d04d00 inset;
  border: none;
  transition: All 0.2s ease;
}

.topazio .btnall2:hover {
  box-shadow: 0px 0px 0px 0px #d04d00 inset;
  border: none;
}

.topazio .bktemadegrade {
  background: linear-gradient(-60deg, #d04d00, #cde1f3) !important;
  /* degradê */
}

.topazio body,
.topazio .hover1:hover,
.topazio .btnNumber:hover,
.topazio .cortxt {
  color: #d04d00 !important;
}

.topazio .cortxt.qrcode path:nth-child(2) {
  fill: #d04d00 !important;
}

.topazio .cortxt10 {
  color: rgba(208, 77, 0, 0.1) !important;
}

.topazio .cortxt20 {
  color: rgba(208, 77, 0, 0.2) !important;
}

.topazio .cortxt30 {
  color: rgba(208, 77, 0, 0.3) !important;
}

.topazio .totalFiltro:before,
.topazio .totalFiltro:after,
.topazio .cortxt40 {
  color: rgba(208, 77, 0, 0.4) !important;
}

.topazio .cortxt50 {
  color: rgba(208, 77, 0, 0.5) !important;
}

.topazio .cortxt60 {
  color: rgba(208, 77, 0, 0.6) !important;
}

.topazio .btnAzul,
.topazio .cortxt70 {
  color: rgba(208, 77, 0, 0.7) !important;
}

.topazio .cortxt80 {
  color: rgba(208, 77, 0, 0.8) !important;
}

.topazio .cortxt90 {
  color: rgba(208, 77, 0, 0.9) !important;
}

.topazio .cortxt2 {
  color: #fff !important;
}

.topazio .b-tema {
  border-color: #d04d00 !important;
}

.topazio .b-none {
  border: none !important;
}

.topazio .capitalizar {
  text-transform: uppercase !important;
}

.topazio .b-tema10 {
  border-color: rgba(208, 77, 0, 0.1) !important;
}

.topazio .b-tema20 {
  border-color: rgba(208, 77, 0, 0.2) !important;
}

.topazio .b-tema30 {
  border-color: rgba(208, 77, 0, 0.3) !important;
}

.topazio .b-tema40 {
  border-color: rgba(208, 77, 0, 0.4) !important;
}

.topazio .b-tema50 {
  border-color: rgba(208, 77, 0, 0.5) !important;
}

.topazio .b-tema70 {
  border-color: rgba(208, 77, 0, 0.7) !important;
}

.topazio .b-tema80 {
  border-color: rgba(208, 77, 0, 0.8) !important;
}

.topazio .b-tema90 {
  border-color: rgba(208, 77, 0, 0.9) !important;
}

.topazio .stema {
  box-shadow: 1px 6px 10px 1px rgba(208, 77, 0, 0.4) !important;
}

.topazio .tag2:after {
  border-color: #d04d00 transparent transparent transparent;
}

.topazio .detaque_titulo1 {
  padding: 10px 10px 10px 22px;
  border-left: 8px solid rgba(208, 77, 0, 0.2);
  /* */
}

.topazio .imgTemaSelect {
  outline: 1px solid rgba(208, 77, 0, 0.2);
}

.topazio .z-window-header {
  color: white !important;
}

.topazio .login1 {
  box-shadow: 0px 0px 9px 7px rgba(208, 77, 0, 0.2);
  border: 3px solid rgba(208, 77, 0, 0.29);
  background: rgba(255, 255, 255, 0.9);
}

.topazio .logintit {
  box-shadow: 0px 2px 18px rgba(208, 77, 0, 0.1);
  /* cor pura com opacidade de 10% */
}

.topazio .footerLogin {
  background: rgba(10, 36, 62, 0.4);
  /* cor pura + escura com opacidade de 40% */
}

.topazio .imgLogoSelect {
  border: 1px solid #d04d00;
}

.topazio .linhabot01 {
  background: #f9f9f9;
}

.topazio .linhabot02 {
  background: #fff;
}

.topazio button {
  border: groove rgba(255, 255, 255, 0.5);
}

.topazio button:hover {
  border: groove rgba(255, 255, 255, 0.8);
}

.topazio button:active {
  border: groove rgba(208, 77, 0, 0.2);
}

.topazio .z-button,
.topazio .z-button:focus {
  color: #991426;
}

.topazio .z-button:hover {
  background: #000;
  color: #fff;
}

.topazio .z-button:active {
  background: #008000;
  color: #fff;
}

.topazio .btn1,
.topazio .btn1:focus {
  background: #d04d00;
  color: #fff;
}

.temaAltoContraste {
  /* cor pura com opacidade de 100% */
  /* cor para contraste / segunda cor */
}

.temaAltoContraste.acessibilidade.zoom-20 {
  zoom: 120%;
  width: 120%;
}

.temaAltoContraste.acessibilidade.zoom-40 {
  zoom: 140%;
  width: 140%;
}

.temaAltoContraste.acessibilidade.zoom-60 {
  zoom: 160%;
  width: 160%;
}

.temaAltoContraste.acessibilidade.zoom-80 {
  zoom: 180%;
  width: 180%;
}

.temaAltoContraste.acessibilidade.zoom-100 {
  width: 200%;
  zoom: 200%;
}

.temaAltoContraste.acessibilidade.negrito,
.temaAltoContraste.acessibilidade.negrito * {
  font-weight: 900 !important;
}

.temaAltoContraste.acessibilidade.escala-cinza {
  filter: grayscale(1);
}

.temaAltoContraste.acessibilidade.invertido {
  filter: invert(100%);
}

.temaAltoContraste .corFundo {
  background: rgba(255, 255, 255, 0.4) !important;
}

.temaAltoContraste .corFundo2 {
  background: #0a0a0a !important;
}

.temaAltoContraste .htema:hover,
.temaAltoContraste .bktema,
.temaAltoContraste .z-window,
.temaAltoContraste .tag2,
.temaAltoContraste .modal-title,
.temaAltoContraste .hover3:hover,
.temaAltoContraste .btnTema:hover {
  background-color: #0a0a0a !important;
}

.temaAltoContraste .htema:hover:after,
.temaAltoContraste .bktema:after,
.temaAltoContraste .z-window:after,
.temaAltoContraste .tag2:after,
.temaAltoContraste .modal-title:after,
.temaAltoContraste .hover3:hover:after,
.temaAltoContraste .btnTema:hover:after {
  border-top-color: #0a0a0a !important;
}

.temaAltoContraste .bktema10 {
  background-color: rgba(10, 10, 10, 0.1) !important;
}

.temaAltoContraste .bktema20 {
  background-color: rgba(10, 10, 10, 0.2) !important;
}

.temaAltoContraste .bktema30 {
  background-color: rgba(10, 10, 10, 0.3) !important;
}

.temaAltoContraste .bktema40 {
  background-color: rgba(10, 10, 10, 0.4) !important;
}

.temaAltoContraste .bktema50,
.temaAltoContraste .linhaMenu:hover {
  background-color: rgba(10, 10, 10, 0.5) !important;
}

.temaAltoContraste .bktema60 {
  background-color: rgba(10, 10, 10, 0.6) !important;
}

.temaAltoContraste .bktema70 {
  background-color: rgba(10, 10, 10, 0.7) !important;
}

.temaAltoContraste .bktema80 {
  background-color: rgba(10, 10, 10, 0.8) !important;
}

.temaAltoContraste .bktema90 {
  background-color: rgba(10, 10, 10, 0.9) !important;
}

.temaAltoContraste .btnMenuEnquete .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.temaAltoContraste .btnMenuEnquete:hover .cortxt {
  color: #0a0a0a !important;
}

.temaAltoContraste .btnMenuEnquete .ativo {
  color: #0a0a0a !important;
}

.temaAltoContraste .btnMenuEnquete {
  box-shadow: 0px -1px 0px 0px rgba(10, 10, 10, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.temaAltoContraste .btnMenuEnquete:hover {
  box-shadow: 0px -5px 0px 0px #0a0a0a inset;
  border: none;
}

.temaAltoContraste .btnMenuAdm .cortxt {
  color: rgba(3, 13, 23, 0.8) !important;
  transition: All 0.2s ease;
}

.temaAltoContraste .btnMenuAdm:hover .cortxt {
  color: #0a0a0a !important;
}

.temaAltoContraste .btnMenuAdm .ativo {
  color: #0a0a0a !important;
}

.temaAltoContraste .btnMenuAdm {
  box-shadow: 0px -1px 0px 0px rgba(10, 10, 10, 0.2) inset;
  border: none;
  transition: All 0.5s ease;
}

.temaAltoContraste .btnMenuAdm:hover {
  box-shadow: 0px -5px 0px 0px #0a0a0a inset;
  border: none;
}

.temaAltoContraste .ativo2 {
  box-shadow: 0px -5px 0px 0px rgba(10, 10, 10, 0.5) inset;
  border: none;
  transition: All 0.5s ease;
}

.temaAltoContraste .btnall2 {
  box-shadow: 300px 0px 0px 0px #0a0a0a inset;
  border: none;
  transition: All 0.2s ease;
}

.temaAltoContraste .btnall2:hover {
  box-shadow: 0px 0px 0px 0px #0a0a0a inset;
  border: none;
}

.temaAltoContraste .bktemadegrade {
  background: linear-gradient(-60deg, #0a0a0a, #cde1f3) !important;
  /* degradê */
}

.temaAltoContraste body,
.temaAltoContraste .hover1:hover,
.temaAltoContraste .btnNumber:hover,
.temaAltoContraste .cortxt {
  color: #0a0a0a !important;
}

.temaAltoContraste .cortxt.qrcode path:nth-child(2) {
  fill: #0a0a0a !important;
}

.temaAltoContraste .cortxt10 {
  color: rgba(10, 10, 10, 0.1) !important;
}

.temaAltoContraste .cortxt20 {
  color: rgba(10, 10, 10, 0.2) !important;
}

.temaAltoContraste .cortxt30 {
  color: rgba(10, 10, 10, 0.3) !important;
}

.temaAltoContraste .totalFiltro:before,
.temaAltoContraste .totalFiltro:after,
.temaAltoContraste .cortxt40 {
  color: rgba(10, 10, 10, 0.4) !important;
}

.temaAltoContraste .cortxt50 {
  color: rgba(10, 10, 10, 0.5) !important;
}

.temaAltoContraste .cortxt60 {
  color: rgba(10, 10, 10, 0.6) !important;
}

.temaAltoContraste .btnAzul,
.temaAltoContraste .cortxt70 {
  color: rgba(10, 10, 10, 0.7) !important;
}

.temaAltoContraste .cortxt80 {
  color: rgba(10, 10, 10, 0.8) !important;
}

.temaAltoContraste .cortxt90 {
  color: rgba(10, 10, 10, 0.9) !important;
}

.temaAltoContraste .cortxt2 {
  color: #fff !important;
}

.temaAltoContraste .b-tema {
  border-color: #0a0a0a !important;
}

.temaAltoContraste .b-none {
  border: none !important;
}

.temaAltoContraste .capitalizar {
  text-transform: uppercase !important;
}

.temaAltoContraste .b-tema10 {
  border-color: rgba(10, 10, 10, 0.1) !important;
}

.temaAltoContraste .b-tema20 {
  border-color: rgba(10, 10, 10, 0.2) !important;
}

.temaAltoContraste .b-tema30 {
  border-color: rgba(10, 10, 10, 0.3) !important;
}

.temaAltoContraste .b-tema40 {
  border-color: rgba(10, 10, 10, 0.4) !important;
}

.temaAltoContraste .b-tema50 {
  border-color: rgba(10, 10, 10, 0.5) !important;
}

.temaAltoContraste .b-tema70 {
  border-color: rgba(10, 10, 10, 0.7) !important;
}

.temaAltoContraste .b-tema80 {
  border-color: rgba(10, 10, 10, 0.8) !important;
}

.temaAltoContraste .b-tema90 {
  border-color: rgba(10, 10, 10, 0.9) !important;
}

.temaAltoContraste .stema {
  box-shadow: 1px 6px 10px 1px rgba(10, 10, 10, 0.4) !important;
}

.temaAltoContraste .tag2:after {
  border-color: #0a0a0a transparent transparent transparent;
}

.temaAltoContraste .detaque_titulo1 {
  padding: 10px 10px 10px 22px;
  border-left: 8px solid rgba(10, 10, 10, 0.2);
  /* */
}

.temaAltoContraste .imgTemaSelect {
  outline: 1px solid rgba(10, 10, 10, 0.2);
}

.temaAltoContraste .z-window-header {
  color: white !important;
}

.temaAltoContraste .login1 {
  box-shadow: 0px 0px 9px 7px rgba(10, 10, 10, 0.2);
  border: 3px solid rgba(10, 10, 10, 0.29);
  background: rgba(255, 255, 255, 0.9);
}

.temaAltoContraste .logintit {
  box-shadow: 0px 2px 18px rgba(10, 10, 10, 0.1);
  /* cor pura com opacidade de 10% */
}

.temaAltoContraste .footerLogin {
  background: rgba(255, 255, 255, 0.4);
  /* cor pura + escura com opacidade de 40% */
}

.temaAltoContraste .imgLogoSelect {
  border: 1px solid #0a0a0a;
}

.temaAltoContraste .linhabot01 {
  background: #f9f9f9;
}

.temaAltoContraste .linhabot02 {
  background: #fff;
}

.temaAltoContraste button {
  border: groove rgba(255, 255, 255, 0.5);
}

.temaAltoContraste button:hover {
  border: groove rgba(255, 255, 255, 0.8);
}

.temaAltoContraste button:active {
  border: groove rgba(10, 10, 10, 0.2);
}

.temaAltoContraste .z-button,
.temaAltoContraste .z-button:focus {
  color: #991426;
}

.temaAltoContraste .z-button:hover {
  background: #000;
  color: #fff;
}

.temaAltoContraste .z-button:active {
  background: #008000;
  color: #fff;
}

.temaAltoContraste .btn1,
.temaAltoContraste .btn1:focus {
  background: #0a0a0a;
  color: #fff;
}

.agata-color.text {
  color: black;
}

.agata-color.background {
  color: #0a243e;
}

.ambar-color.text {
  color: #d29b00;
}

.ambar-color.background {
  color: #0a243e;
}

.ametista-color.text {
  color: #42095f;
}

.ametista-color.background {
  color: #0a243e;
}

.cristal-color.text {
  color: #0a243e;
}

.cristal-color.background {
  color: #6f6f71;
}

.esmeralda-color.text {
  color: #074a31;
}

.esmeralda-color.background {
  color: #0a243e;
}

.oceanus-color.text {
  color: #3096ff;
}

.oceanus-color.background {
  color: #0a243e;
}

.rose-color.text {
  color: #d65858;
}

.rose-color.background {
  color: #0a243e;
}

.rubi-color.text {
  color: #ac2333;
}

.rubi-color.background {
  color: #0a243e;
}

.safira-color.text {
  color: #003366;
}

.safira-color.background {
  color: #0a243e;
}

.topazio-color.text {
  color: #d04d00;
}

.topazio-color.background {
  color: #0a243e;
}

.temaAltoContraste-color.text {
  color: #0a0a0a;
}

.temaAltoContraste-color.background {
  color: white;
}

.no-p > p {
  margin: 0 !important;
  padding: 0 !important;
}

.fixedToTop {
  position: fixed;
  right: 40px;
  bottom: 50px;
  z-index: 3;
  transition: all;
}

/* --- cores --- */
.amarelo {
  color: #ffec0c !important;
}

.azul {
  color: #007bff !important;
}

.azul1 {
  color: #b0c8e0 !important;
}

.azul_celeste {
  color: #04c6bc !important;
}

.btnSearch,
.verde {
  color: #419f42 !important;
}

.verde1 {
  color: #b3ccb3 !important;
}

.vermelho {
  color: #f00 !important;
}

.btnVermelho,
.vermelho1 {
  color: #c39292 !important;
}

.laranja {
  color: #ff7f24 !important;
}

.laranja1 {
  color: #f5ad7a !important;
}

.roxo {
  color: #8a2be2 !important;
}

.preto {
  color: #000000 !important;
}

.apergamum {
  color: #003366 !important;
}

.hover3:hover,
.hover2:hover,
.btnTema:hover,
.btnBranco,
.branco,
.linhaMenu:hover,
.branco {
  color: #fffffe !important;
}

.branco10 {
  color: rgba(255, 255, 255, 0.1) !important;
}

.branco20 {
  color: rgba(255, 255, 255, 0.2) !important;
}

.branco30 {
  color: rgba(255, 255, 255, 0.3) !important;
}

.branco40 {
  color: rgba(255, 255, 255, 0.4) !important;
}

.branco50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.branco60 {
  color: rgba(255, 255, 255, 0.6) !important;
}

.branco70 {
  color: rgba(255, 255, 255, 0.7) !important;
}

.branco80 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.branco90 {
  color: rgba(255, 255, 255, 0.9) !important;
}

.cinza {
  color: #c0c0c0 !important;
}

.azul_celeste1 {
  color: #a2b3e6 !important;
}

.magenta {
  color: #ff0076 !important;
}

.preto10 {
  color: rgba(0, 0, 0, 0.1) !important;
}

.preto20 {
  color: rgba(0, 0, 0, 0.2) !important;
}

.preto30 {
  color: rgba(0, 0, 0, 0.3) !important;
}

.preto40 {
  color: rgba(0, 0, 0, 0.4) !important;
}

.preto50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.preto60 {
  color: rgba(0, 0, 0, 0.6) !important;
}

.preto70 {
  color: rgba(0, 0, 0, 0.7) !important;
}

.preto80 {
  color: rgba(0, 0, 0, 0.8) !important;
}

.preto90 {
  color: rgba(0, 0, 0, 0.9) !important;
}

.pergamum-text {
  color: #003f62 !important;
}

.pergamum-80-text {
  color: rgba(0, 63, 98, 0.8) !important;
}

.pergamum-50-text {
  color: rgba(0, 63, 98, 0.5) !important;
}

.pergamum-10-text {
  color: rgba(0, 63, 98, 0.1) !important;
}

/* --- background --- */
.bkamarelo {
  background-color: #ffec0c !important;
}

.bkazul {
  background-color: #3399ff !important;
}

.bkazul1 {
  background-color: #b0c8e0 !important;
}

.bkverde {
  background-color: #419f42 !important;
}

.bkverde1 {
  background-color: #b3ccb3 !important;
}

.bkvermelho {
  background-color: #f00 !important;
}

.bkvermelho1 {
  background-color: #c39292 !important;
}

.bkvermelho2 {
  background-color: #d9534f !important;
}

.bklaranja {
  background-color: #ff7f24 !important;
}

.bklaranja1 {
  background-color: #f5ad7a !important;
}

.bkroxo {
  background-color: #8a2be2 !important;
}

.bkpreto {
  background-color: #000000 !important;
}

.bkamarelo {
  background-color: #fdc00d !important;
}

.bkpergamum {
  background-color: #00476b !important;
}

.bkbranco {
  background-color: #fff !important;
}

.bkcinza {
  background-color: #c0c0c0 !important;
}

.bkcinza2 {
  background-color: rgba(192, 192, 192, 0.2) !important;
}

.bkcinza3 {
  background-color: #525252 !important;
}

.bkazul_celeste {
  background-color: #04c6bc !important;
}

.bkmagenta {
  background-color: #ff0076 !important;
}

.bkpreto {
  background-color: #000 !important;
}

.bkazulclaro1 {
  background-color: #f1f5fb !important;
}

.bkamarelo {
  background-color: #ffec0c !important;
}

/* --- hover --- */
.z-checkbox .z-checkbox-content:hover,
.hover:hover {
  color: #fff !important;
  -webkit-transition: All 0.5s ease;
  transition: All 0.4s ease;
}

.hover2:hover {
  color: #000 !important;
  -webkit-transition: All 0.5s ease;
  transition: All 0.4s ease;
}

.hamarelo:hover {
  background-color: #ffec0c !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hazul:hover {
  background-color: #3399ff !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hazul1:hover {
  background-color: #b0c8e0 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hverde:hover {
  background-color: #419f42 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hverde1:hover {
  background-color: #b3ccb3 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hvermelho:hover {
  background-color: #f00 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hvermelho1:hover {
  background-color: #c39292 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hvermelho2:hover {
  background-color: #d9534f !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hlaranja:hover {
  background-color: #ff7f24 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hlaranja1:hover {
  background-color: #f5ad7a !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hroxo:hover {
  background-color: #8a2be2 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hpreto:hover {
  background-color: #000000 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hpergamum:hover {
  background-color: #003366 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hbranco:hover {
  background-color: #fff !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hcinza:hover {
  background-color: #c0c0c0 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hcinza2:hover {
  background-color: rgba(192, 192, 192, 0.2) !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hcinza3:hover {
  background-color: #525252 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hazul_celeste:hover {
  background-color: #04c6bc !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hmagenta:hover {
  background-color: #c609cc !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hpreto:hover {
  background-color: #000 !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

.hazulclaro1:hover {
  background-color: #f1f5fb !important;
  -webkit-transition: All 0.2s ease;
  transition: All 0.2s ease;
}

/* --- .hover --- */
/* --- opacidade --- */
.op00 {
  opacity: 0;
}

.op01 {
  opacity: 0.1;
}

.op02 {
  opacity: 0.2;
}

.op03 {
  opacity: 0.3;
}

.op04 {
  opacity: 0.4;
}

.op05 {
  opacity: 0.5;
}

.op06 {
  opacity: 0.6;
}

.op07 {
  opacity: 0.7;
}

.op08 {
  opacity: 0.8;
}

.op09 {
  opacity: 0.9;
}

.op10 {
  opacity: 1;
}

/* --- .opacidade --- */
.acessibilidade.contraste {
  /* -- contraste 					    -- */
  /* -- by Bourscheid, Ricardo 	-- */
  /* -- 2022 	          				-- */
  /* -------------------------------------------------------------------------------- */
}

.acessibilidade.contraste body,
.acessibilidade.contraste body .contraste {
  color: rgba(255, 255, 255, 0.7) !important;
}

/* ------------------------------------- variables ------------------------------------- */
:root {
  --verde: 76, 165, 80;
  --roxo: 141, 65, 159;
  --vermelho: 255, 0, 0;
  --azul: 0, 188, 212;
  --laranja: 255, 127, 36;
  --tiffany: 8, 124, 162;
  --magenta: 226, 10, 123;
  --amarelo: 255, 127, 36;
  --preto: 0, 0, 0;
  --branco: 255, 255, 255;
  --agata: 0, 0, 0;
  --ambar: 210, 155, 0;
  --ametista: 66, 9, 95;
  --cristal: 111, 111, 113;
  --esmeralda: 7, 74, 49;
  --oceanus: 48, 150, 255;
  --rose: 235, 158, 158;
  --rubi: 172, 35, 51;
  --safira: 0, 51, 102;
  --topazio: 208, 77, 0;
  --family: 'Roboto', sans-serif !important;
  --blur1: blur(5px);
  --blur2: blur(10px);
  --blur3: blur(15px);
  --blur4: blur(20px);
  --anima1: 1000ms;
  --anima2: 500ms;
}

/* ------------------------------------- .variables ------------------------------------ */
/* seletor #mp-root para aplicar somente em telas do meu pergamum */
#mp-root {
  /* LINK */
  /* .LINK */
  /* ANIMA */
  /* .ANIMA */
  /* ESTRUTURA */
  /* .ESTRUTURA */
  /* CARTAO VIRTUAL */
  /* .cartao:hover .cartao-container, */
  /* .CARTAO VIRTUAL */
  /* BUTTON */
  /* .BUTTON */
  /* BUTTON NEO */
  /* FORM */
  /* .FORM */
  /* BASIC */
  /* .BASIC */
  /* BOOSTRAP */
  /* .BOOSTRAP */
  /* MODAL */
  /* .MODAL */
  /* DARK */
  /* DARK */
  /* ---------------------------------- Media Queries ------------------------------------------ */
}

#mp-root .app.acessibilidade.agata {
  --tema: var(--agata);
}

#mp-root .app.acessibilidade.ambar {
  --tema: var(--ambar);
}

#mp-root .app.acessibilidade.ametista {
  --tema: var(--ametista);
}

#mp-root .app.acessibilidade.cristal {
  --tema: var(--cristal);
}

#mp-root .app.acessibilidade.esmeralda {
  --tema: var(--esmeralda);
}

#mp-root .app.acessibilidade.oceanus {
  --tema: var(--oceanus);
}

#mp-root .app.acessibilidade.rose {
  --tema: var(--rose);
}

#mp-root .app.acessibilidade.rubi {
  --tema: var(--rubi);
}

#mp-root .app.acessibilidade.safira {
  --tema: var(--safira);
}

#mp-root .app.acessibilidade.topazio {
  --tema: var(--topazio);
}

#mp-root .z-a,
#mp-root .z-a2 {
  font-family: 'Roboto', sans-serif !important;
  text-decoration: none;
  border-radius: 2px;
  padding: 2px;
  transition: All 0.4s ease;
  cursor: pointer;
}

#mp-root .z-a {
  color: rgba(var(--preto), 1);
  border-bottom: 2px solid rgba(var(--tema), 1);
}

#mp-root .z-a2 {
  color: rgba(var(--branco), 1);
  border-bottom: 1px solid rgba(var(--branco), 0.5);
}

#mp-root .z-a2:visited {
  color: rgba(var(--branco), 1);
}

#mp-root .z-a:visited {
  color: rgba(var(--tema), 1);
}

#mp-root .z-a:visited:hover,
#mp-root .z-a2:visited:hover {
  color: #fff !important;
}

#mp-root .z-a[disabled] {
  border-bottom: 2px dotted rgba(255, 0, 0, 0.5);
}

#mp-root .z-a:hover,
#mp-root .z-a2:hover {
  color: #fff !important;
  background: rgba(var(--tema), 1);
  border-radius: 5px;
  text-decoration: none;
}

#mp-root .z-a[disabled]:hover {
  color: #fff;
  background: red;
  cursor: no-drop;
  border-radius: 5px;
  text-decoration: none;
}

#mp-root .mov-1 {
  transform: translateX(-110%);
  opacity: 0;
}

#mp-root .mov0 {
  transform: translateX(0%);
  opacity: 1;
}

#mp-root .mov1 {
  transform: translateX(110%);
  opacity: 0;
}

#mp-root h5,
#mp-root h6 {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, rgba(var(--tema), 0.2), rgba(var(--tema), 1));
  border-image-slice: 1;
  color: rgba(var(--tema), 1);
}

#mp-root h5 svg,
#mp-root h6 svg {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 0.5em;
}

#mp-root .tabela .row {
  padding: 8px;
}

#mp-root .tabela {
  font-size: 14px;
}

#mp-root .tabela .row:nth-child(1) {
  background-color: transparent;
  border-bottom: 1px solid rgba(var(--tema), 0.4);
  font-weight: 700;
}

#mp-root .tabela .row:nth-child(odd) {
  background-color: transparent;
}

#mp-root .tabela .row:nth-child(even) {
  background-color: rgba(var(--preto), 0.02);
}

#mp-root .rotulo {
  font-family: var(--family);
  font-weight: 700;
  font-size: 12px;
  color: rgba(var(--preto), 0.7);
  margin-left: 5px;
}

#mp-root .w-300 {
  width: 300px;
}

#mp-root .imgMeuPergamum {
  height: 100vh;
  background-image: url("../../images/original_bg_login.png");
  background-size: cover;
  background-color: rgba(var(--tema), 0);
  background-attachment: fixed;
  background-blend-mode: overlay;
}

#mp-root .alturadoHeaderMeuPergamum {
  margin-top: calc(-100vh + 150px);
}

#mp-root .topLinkSpaceMeuPergamum {
  float: right;
  border-radius: 0 0 8px 8px;
  margin-right: 24px;
}

#mp-root .img_logoMeuPergamum {
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 226px;
  height: 120px;
  border-radius: 0px 0px 5px 5px;
  position: relative;
  margin-left: 90px;
  border-top: 1px solid;
  margin-top: 1px;
}

#mp-root .menu_logout {
  position: absolute;
  width: 100%;
  bottom: 0px;
}

@media screen and (max-width: 768px) {
  #mp-root .menu_container {
    position: absolute;
    z-index: 1;
    width: 100%;
    pointer-events: none;
  }
  #mp-root .menu_container.mb-open {
    pointer-events: all;
  }
}

@media screen and (max-width: 768px) {
  #mp-root .menu_wrapper {
    width: 100% !important;
  }
}

#mp-root .submenu_wrapper {
  margin-top: -45px;
}

#mp-root .anima-1 {
  transition: var(--anima1);
}

#mp-root .anima-2 {
  transition: var(--anima2);
}

#mp-root .anima-3 {
  transition: var(--anima3);
}

#mp-root .anima-4 {
  transition: var(--anima4);
}

#mp-root .posicao1 {
  position: absolute;
  top: 46px;
  width: 100%;
}

#mp-root .btnABC {
  width: 50px;
}

#mp-root .alertaNumeral {
  width: 30px;
  height: 30px;
  text-align: center;
  padding-top: 5px;
  border-radius: 50%;
  position: relative;
  top: -11px;
  left: -11px;
  font-weight: 700;
  background: rgba(var(--laranja), 0.9);
  color: rgba(var(--branco), 1);
  font-size: 11px;
  border: 2px solid rgba(var(--branco), 0.5);
  box-shadow: 0px 0px 14px 1px rgba(109, 109, 109, 0.3);
  display: inline-block;
}

@media screen and (max-width: 768px) {
  #mp-root .alertaNumeral {
    width: 24px;
    height: 24px;
    top: -10px;
    left: -10px;
  }
}

#mp-root .alertaMsg {
  position: absolute;
  z-index: 1;
}

#mp-root .alert-dismissible > .close {
  border: none !important;
}

#mp-root .alert-dismissible > .close:hover {
  color: rgba(10, 10, 10, 0.8) !important;
  border: none !important;
  background-image: none !important;
  background-color: transparent !important;
}

#mp-root .cartao {
  height: 250px;
  min-width: 200px;
  max-width: 400px;
  width: 100%;
  perspective: 1000px;
}

#mp-root .cartao-container:before,
#mp-root .cartao-container:after {
  content: '';
  display: block;
  clear: both;
}

#mp-root .cartao-container {
  position: relative;
  height: 100%;
  transition: transform 0.4s;
  transform-style: preserve-3d;
}

#mp-root .girar {
  transform: rotateY(180deg);
}

#mp-root .cartao-front,
#mp-root .cartao-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 1;
}

#mp-root .cartao-front {
  background: green;
}

#mp-root .cartao-back {
  transform: rotateY(180deg);
  background: red;
}

#mp-root .maskCircle {
  clip-path: circle(40% at 50% 50%);
}

#mp-root button.primeiro,
#mp-root .btnPri {
  box-shadow: 0 12px 20px -10px rgba(var(--tema), 0.28), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 0 4px -5px rgba(var(--tema), 0.2);
  padding: 0.5rem !important;
  border-radius: 5px;
  font-weight: 500;
  transition: All 0.4s ease;
  color: #fff;
  border: none;
  font-size: 14px;
  margin-left: 1rem;
  background-color: rgba(var(--tema), 1);
  background-image: none;
  text-shadow: none;
  cursor: pointer;
  margin-left: 1rem;
}

#mp-root button.primeiro:disabled,
#mp-root .btnPri:disabled {
  cursor: no-drop;
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
  text-decoration: line-through;
  font-weight: 500;
  background-color: rgba(122, 60, 60, 0.2) !important;
  background-image: none;
  color: rgba(163, 128, 128, 0.7);
}

#mp-root button.segundo,
#mp-root .btnSeg {
  box-shadow: inset 0 0px 0px 1px rgba(var(--tema), 0.3);
  padding: 0.5rem !important;
  border-radius: 5px;
  font-weight: 500;
  transition: All 0.4s ease;
  color: rgba(var(--preto), 0.6);
  border: none;
  font-size: 14px;
  margin-left: 1rem;
  background-color: rgba(var(--tema), 0);
  cursor: pointer;
  margin-left: 1rem;
}

#mp-root button.segundo:disabled,
#mp-root .btnSeg:disabled {
  cursor: no-drop;
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
  text-decoration: line-through;
  font-weight: 500;
  background-color: rgba(122, 60, 60, 0.1) !important;
  box-shadow: inset 0 0px 0px 1px rgba(var(--tema), 0.1);
  color: rgba(163, 128, 128, 0.7);
}

#mp-root button:focus,
#mp-root .btnPri:focus,
#mp-root .btnSeg:focus {
  color: #fff !important;
  -webkit-transition: All 0.8s ease;
  transition: All 0.8s ease;
  background: linear-gradient(139deg, #2c5624, transparent);
  box-shadow: 0px 0px 20px 0px #f2ff0096, inset 0px 0px 8px 3px #eefb0094 !important;
  background-image: none;
}

#mp-root button:active,
#mp-root .btnPri:active,
#mp-root .btnSeg:active {
  background-color: #000 !important;
  color: #fff !important;
  -webkit-transition: All 0.8s ease;
  transition: All 0.8s ease;
  background-image: linear-gradient(139deg, #2c5624, transparent);
  box-shadow: none !important;
}

#mp-root button:active .s1,
#mp-root .btnPri:active .s1,
#mp-root .btnSeg:active .s1 {
  display: none;
}

#mp-root button:active .s2,
#mp-root .btnPri:active .s2,
#mp-root .btnSeg:active .s2 {
  display: inline !important;
  -webkit-animation: rotate-center 2s linear infinite;
  animation: rotate-center 2s linear infinite;
}

#mp-root button:hover,
#mp-root .btnPri:hover,
#mp-root .btnSeg:hover {
  background-color: #000 !important;
  color: #fff !important;
  -webkit-transition: All 0.8s ease;
  transition: All 0.8s ease;
  background-image: linear-gradient(139deg, #2c5624, transparent);
}

#mp-root button:focus,
#mp-root button.primeiro:focus,
#mp-root button.segundo:focus {
  background-color: rgba(var(--verde), 1) !important;
}

#mp-root button .loading {
  -webkit-animation: rotate-center 2s linear infinite;
  animation: rotate-center 2s linear infinite;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#mp-root .nav-tabs .nav-link.active:hover {
  background: rgba(var(--preto), 1);
}

#mp-root .nav-tabs > li {
  font-size: 14px;
  cursor: pointer;
}

#mp-root .nav-link {
  color: rgba(var(--tema), 1);
}

#mp-root .nav-link:hover {
  color: rgba(var(--preto), 1);
  background: rgba(var(--branco), 0.5);
}

#mp-root .nav-item:hover {
  background: rgba(var(--branco), 0.5);
}

#mp-root .nav-tabs .nav-link.active:hover {
  background: rgba(var(--preto), 1);
}

#mp-root .nav-tabs > li {
  font-size: 14px;
  cursor: pointer;
}

#mp-root .nav-link {
  color: rgba(var(--tema), 1);
}

#mp-root .nav-link:hover {
  color: rgba(var(--preto), 1);
  background: rgba(var(--branco), 0.5);
}

#mp-root .nav-item:hover {
  background: rgba(var(--branco), 0.5);
}

#mp-root .custom-radio input[type='radio'],
#mp-root input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
  width: 24px;
  height: 24px;
  accent-color: rgba(var(--tema), 1);
  margin-top: 0;
  margin-left: 0px;
}

#mp-root .custom-checkbox label,
#mp-root .custom-radio label {
  cursor: pointer;
  margin-left: 32px;
  font-family: var(--family);
  font-weight: 300;
  font-size: 20px;
}

#mp-root .blur1 {
  backdrop-filter: var(--blur1);
  -webkit-backdrop-filter: var(--blur1);
}

#mp-root .blur2 {
  backdrop-filter: var(--blur2);
  -webkit-backdrop-filter: var(--blur2);
}

#mp-root .blur3 {
  backdrop-filter: var(--blur3);
  -webkit-backdrop-filter: var(--blur3);
}

#mp-root .b-tema-10 {
  border-color: rgba(var(--tema), 0.1);
}

#mp-root .b-tema-20 {
  border-color: rgba(var(--tema), 0.2);
}

#mp-root .b-tema-30 {
  border-color: rgba(var(--tema), 0.3);
}

#mp-root .b-tema-40 {
  border-color: rgba(var(--tema), 0.4);
}

#mp-root .b-tema-50 {
  border-color: rgba(var(--tema), 0.5);
}

#mp-root .b-tema-60 {
  border-color: rgba(var(--tema), 0.6);
}

#mp-root .b-tema-70 {
  border-color: rgba(var(--tema), 0.7);
}

#mp-root .b-tema-80 {
  border-color: rgba(var(--tema), 0.8);
}

#mp-root .b-tema-90 {
  border-color: rgba(var(--tema), 0.9);
}

#mp-root .b-tema {
  border-color: rgba(var(--tema), 1);
}

#mp-root .b-azul-50 {
  border-color: rgba(var(--azul), 0.5);
}

#mp-root .b-verde-50 {
  border-color: rgba(var(--verde), 0.5);
}

#mp-root .b-verde-60 {
  border-color: rgba(var(--verde), 0.6);
}

#mp-root .b-verde-70 {
  border-color: rgba(var(--verde), 0.7);
}

#mp-root .b-verde-80 {
  border-color: rgba(var(--verde), 0.8);
}

#mp-root .b-verde-90 {
  border-color: rgba(var(--verde), 0.9);
}

#mp-root .b-preto-20 {
  border-color: rgba(var(--preto), 0.2);
}

#mp-root .bg-tema {
  background-color: rgba(var(--tema), 1);
}

#mp-root .bg-tema-10 {
  background-color: rgba(var(--tema), 0.1);
}

#mp-root .bg-tema-20 {
  background-color: rgba(var(--tema), 0.2);
}

#mp-root .bg-tema-30 {
  background-color: rgba(var(--tema), 0.3);
}

#mp-root .bg-tema-40 {
  background-color: rgba(var(--tema), 0.4);
}

#mp-root .bg-tema-50 {
  background-color: rgba(var(--tema), 0.5);
}

#mp-root .bg-tema-60 {
  background-color: rgba(var(--tema), 0.6);
}

#mp-root .bg-tema-70 {
  background-color: rgba(var(--tema), 0.7);
}

#mp-root .bg-tema-80 {
  background-color: rgba(var(--tema), 0.8);
}

#mp-root .bg-tema-90 {
  background-color: rgba(var(--tema), 0.9);
}

#mp-root .bg-none {
  background-color: rgba(var(--preto), 0) !important;
}

#mp-root .bg-degrade-mobile {
  background-image: linear-gradient(145deg, rgba(var(--tema), 1) 0%, rgba(var(--tema), 0.8) 35%, rgba(var(--tema), 0.05) 100%);
}

#mp-root .text-tema {
  color: rgba(var(--tema), 1);
}

#mp-root .text-tema-10 {
  color: rgba(var(--tema), 0.1);
}

#mp-root .text-tema-20 {
  color: rgba(var(--tema), 0.2);
}

#mp-root .text-tema-30 {
  color: rgba(var(--tema), 0.3);
}

#mp-root .text-tema-40 {
  color: rgba(var(--tema), 0.4);
}

#mp-root .text-tema-50 {
  color: rgba(var(--tema), 0.5);
}

#mp-root .text-tema-60 {
  color: rgba(var(--tema), 0.6);
}

#mp-root .text-tema-70 {
  color: rgba(var(--tema), 0.7);
}

#mp-root .text-tema-80 {
  color: rgba(var(--tema), 0.8);
}

#mp-root .text-tema-90 {
  color: rgba(var(--tema), 0.9);
}

#mp-root .bg-verde {
  background-color: rgba(var(--verde), 1);
}

#mp-root .bg-verde-5 {
  background-color: rgba(var(--verde), 0.05);
}

#mp-root .bg-verde-10 {
  background-color: rgba(var(--verde), 0.1);
}

#mp-root .bg-verde-20 {
  background-color: rgba(var(--verde), 0.2);
}

#mp-root .bg-verde-30 {
  background-color: rgba(var(--verde), 0.3);
}

#mp-root .bg-verde-40 {
  background-color: rgba(var(--verde), 0.4);
}

#mp-root .bg-verde-50 {
  background-color: rgba(var(--verde), 0.5);
}

#mp-root .bg-vermelho {
  background-color: rgba(var(--vermelho), 1);
}

#mp-root .bg-roxo {
  background-color: rgba(var(--roxo), 1);
}

#mp-root .bg-azul {
  background-color: rgba(var(--azul), 1);
}

#mp-root .bg-azul-5 {
  background-color: rgba(var(--azul), 0.05);
}

#mp-root .bg-azul-10 {
  background-color: rgba(var(--azul), 0.1);
}

#mp-root .bg-azul-20 {
  background-color: rgba(var(--azul), 0.2);
}

#mp-root .bg-azul-30 {
  background-color: rgba(var(--azul), 0.3);
}

#mp-root .bg-azul-40 {
  background-color: rgba(var(--azul), 0.4);
}

#mp-root .bg-azul-50 {
  background-color: rgba(var(--azul), 0.5);
}

#mp-root .bg-laranja {
  background-color: rgba(var(--laranja), 1);
}

#mp-root .bg-tiffany {
  background-color: rgba(var(--tiffany), 1);
}

#mp-root .bg-magenta {
  background-color: rgba(var(--magenta), 1);
}

#mp-root .bg-preto {
  background-color: rgba(var(--preto), 0.7);
}

#mp-root .bg-amarelo {
  background-color: rgba(var(--amarelo), 1);
}

#mp-root .bg-branco {
  background-color: rgba(var(--branco), 1);
}

#mp-root .text-verde {
  color: rgba(var(--verde), 1);
}

#mp-root .text-vermelho {
  color: rgba(var(--vermelho), 1);
}

#mp-root .text-roxo {
  color: rgba(var(--roxo), 1);
}

#mp-root .text-azul {
  color: rgba(var(--azul), 1);
}

#mp-root .text-laranja {
  color: rgba(var(--laranja), 1);
}

#mp-root .text-tiffany {
  color: rgba(var(--tiffany), 1);
}

#mp-root .text-magenta {
  color: rgba(var(--magenta), 1);
}

#mp-root .text-preto {
  color: rgba(var(--preto), 0.7);
}

#mp-root .text-amarelo {
  color: rgba(var(--amarelo), 1);
}

#mp-root .text-branco {
  color: rgba(var(--branco), 1);
}

#mp-root .obrigatorio::after {
  color: #f00;
  content: ' (*)';
  font-weight: 900;
  font-family: var(--family);
}

#mp-root .opcional::after {
  color: rgba(var(--azul), 1);
  content: ' (op)';
  font-weight: 900;
  font-family: var(--family);
}

#mp-root .link-underline:hover {
  cursor: pointer;
  text-decoration: underline;
}

#mp-root .btn-w100 {
  display: inline;
  width: auto;
}

#mp-root .modal-title {
  background-color: rgba(var(--tema), 1);
  border-radius: 5px;
  background-image: url("../../images/montanha.png"), url("../../images/card_tema.svg");
  background-position: center;
  background-size: cover;
}

#mp-root .neo {
  /* BUTTON MOBILE */
  /* .BUTTON MOBILE */
  /* BUTTON NEO */
  /* BUTTON NEO */
}

#mp-root .neo button.primeiro.mobile,
#mp-root .neo button.segundo.mobile {
  padding: 0.5rem 0rem 0.5rem 1rem !important;
  text-align: left;
  height: 60px;
}

#mp-root .neo button.primeiro,
#mp-root .neo .btnPri {
  box-shadow: 15px 10px 20px -13px rgba(var(--tema), 1), -20px -10px 20px -10px rgba(var(--preto), 0.1), inset 3px 3px 10px 5px rgba(255, 255, 255, 0.5);
  padding: 0.5rem !important;
  border-radius: 16px;
  font-weight: 500;
  transition: All 0.4s ease;
  color: #fff;
  border: 0px solid rgba(255, 255, 255, 0.8);
  font-size: 14px;
  margin-left: 1rem;
  background-color: rgba(var(--tema), 1);
  background-image: none;
  text-shadow: none;
  cursor: pointer;
  margin-left: 0.25rem;
}

#mp-root .neo button.primeiro:disabled,
#mp-root .neo .btnPri:disabled {
  cursor: no-drop;
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
  text-decoration: line-through;
  font-weight: 500;
  background-color: rgba(122, 60, 60, 0.2) !important;
  background-image: none;
  color: rgba(163, 128, 128, 0.7);
  box-shadow: 15px 10px 20px -13px rgba(var(--tema), 0.4), -20px -10px 20px -10px rgba(var(--preto), 0.1), inset 3px 3px 10px 5px rgba(255, 255, 255, 0.5);
}

#mp-root .neo button.segundo,
#mp-root .neo .btnSeg {
  box-shadow: inset 0 0px 0px 1px rgba(var(--tema), 0.3);
  padding: 0.5rem !important;
  border-radius: 16px;
  font-weight: 500;
  transition: All 0.4s ease;
  color: rgba(var(--preto), 0.6);
  border: rgba(var(--tema), 0.3) 1px solid;
  font-size: 14px;
  margin-left: 1rem;
  background-color: rgba(var(--tema), 0);
  cursor: pointer;
  margin-left: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 15px 10px 20px -13px rgba(var(--tema), 0.3), -20px -10px 20px -10px rgba(var(--preto), 0.05), inset 3px 3px 10px 5px rgba(255, 255, 255, 0.5);
}

#mp-root .neo button.segundo:disabled,
#mp-root .neo .btnSeg:disabled {
  cursor: no-drop;
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
  text-decoration: line-through;
  font-weight: 500;
  background-color: rgba(122, 60, 60, 0.1) !important;
  box-shadow: inset 0 0px 0px 1px rgba(var(--tema), 0.1);
  color: rgba(163, 128, 128, 0.7);
}

#mp-root .neo button.primeiro.nao,
#mp-root .neo .btnPri.nao {
  box-shadow: 15px 10px 20px -13px rgba(var(--vermelho), 1), -20px -10px 20px -10px rgba(var(--preto), 0.1), inset 3px 3px 10px 5px rgba(255, 255, 255, 0.5);
  padding: 0.5rem !important;
  border-radius: 16px;
  font-weight: 500;
  transition: All 0.4s ease;
  color: #fff;
  border: 0px solid rgba(255, 255, 255, 0.8);
  font-size: 14px;
  margin-left: 1rem;
  background-color: rgba(var(--vermelho), 1);
  background-image: none;
  text-shadow: none;
  cursor: pointer;
  margin-left: 0.25rem;
}

#mp-root .neo button.segundo.nao,
#mp-root .neo .btnSeg.nao {
  box-shadow: inset 0 0px 0px 1px rgba(var(--vermelho), 0.3);
  padding: 0.5rem !important;
  border-radius: 16px;
  font-weight: 500;
  transition: All 0.4s ease;
  color: rgba(var(--vermelho), 0.6);
  border: rgba(var(--vermelho), 0.3) 1px solid;
  font-size: 14px;
  margin-left: 1rem;
  background-color: rgba(var(--vermelho), 0.05);
  cursor: pointer;
  margin-left: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 15px 10px 20px -13px rgba(var(--vermelho), 0.3), -20px -10px 20px -10px rgba(var(--preto), 0.05), inset 3px 3px 10px 5px rgba(255, 255, 255, 0.1);
}

#mp-root .neo .nav-tabs .nav-link.active {
  background: rgba(var(--tema), 0.9) !important;
  color: #fff;
  font-family: var(--family);
  font-weight: 700;
}

#mp-root .neo input[type='date'],
#mp-root .neo input[type='datetime-local'],
#mp-root .neo input[type='email'],
#mp-root .neo input[type='hidden'],
#mp-root .neo input[type='image'],
#mp-root .neo input[type='month'],
#mp-root .neo input[type='number'],
#mp-root .neo input[type='password'],
#mp-root .neo input[type='search'],
#mp-root .neo input[type='tel'],
#mp-root .neo input[type='text'],
#mp-root .neo input[type='time'],
#mp-root .neo input[type='week'],
#mp-root .neo textarea,
#mp-root .neo select,
#mp-root .neo input[type='url'] {
  font-family: var(--family);
  font-weight: 300;
  font-size: 20px;
  color: rgba(var(--preto), 1);
  border-radius: 5px;
  box-shadow: inset 0 -1px rgba(var(--tema), 0.2), inset 3px 3px 10px rgba(var(--tema), 0.1), inset -2px -2px 10px #ffffff;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #eeeeee;
  transition: all 0.4s ease 0s;
  padding: 10px 15px !important;
}

#mp-root .neo textarea {
  height: 205px !important;
}

#mp-root .neo .bkneo {
  background: linear-gradient(204deg, #f1f0ff 0%, #e7e7e7 35%, #f4fbfd 100%);
}

#mp-root .neo .bkneo {
  background-color: rgba(var(--preto), 0);
}

#mp-root .neo .imgMeuPergamum {
  height: 100vh;
  background-image: url(/images/original_bg_login.png);
  background-size: cover;
  background-color: rgba(var(--tema), 0.1);
  background-attachment: fixed;
  background-blend-mode: overlay;
}

#mp-root .neo .alturadoHeaderMeuPergamum {
  margin-top: calc(-100vh + 150px);
}

#mp-root .neo .bkmenu-1 {
  background: rgba(var(--branco), 0.3);
}

#mp-root .neo .bkmenu-2 {
  background: linear-gradient(140deg, rgba(var(--preto), 0.8) 0%, rgba(var(--tema), 0.8) 50%, rgba(var(--tema), 1) 100%);
}

#mp-root .neo .b-menu {
  border: rgba(var(--branco), 1) solid 1px;
}

#mp-root .neo .b-menu-divide {
  border-color: rgba(var(--branco), 0.3);
}

#mp-root .neo .cor-menu {
  color: rgba(var(--branco), 1);
}

#mp-root .neo .cor-menu-ico {
  color: rgba(var(--branco), 0.5);
}

#mp-root .neo .cor-menu-sub {
  color: rgba(var(--branco), 0.5);
}

#mp-root .neo .bkbread {
  background: rgba(240, 240, 245, 0.9);
}

#mp-root .neo .cor-bread {
  color: rgba(var(--preto), 0.8);
}

#mp-root .neo .z-a-bread {
  color: rgba(var(--preto), 0.8);
}

#mp-root .neo .cor-bread-ico {
  color: rgba(var(--tema), 1);
}

#mp-root .neo .bk-theme-meio {
  background: rgba(var(--branco), 0.5);
}

#mp-root .dark button.primeiro,
#mp-root .dark .btnPri {
  box-shadow: 15px 10px 20px -13px rgba(var(--tema), 1), -20px -10px 20px -10px rgba(var(--branco), 0.1), inset 3px 3px 10px 5px rgba(165, 165, 165, 0.5);
  padding: 0.5rem !important;
  border-radius: 16px;
  font-weight: 500;
  transition: All 0.4s ease;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 14px;
  margin-left: 1rem;
  background-color: rgba(var(--tema), 1);
  background-image: none;
  text-shadow: none;
  cursor: pointer;
  margin-left: 0.25rem;
}

#mp-root .dark button.primeiro:disabled,
#mp-root .dark .btnPri:disabled {
  cursor: no-drop;
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
  text-decoration: line-through;
  font-weight: 500;
  background-color: rgba(122, 60, 60, 0.2) !important;
  background-image: none;
  color: rgba(163, 128, 128, 0.7);
  box-shadow: 15px 10px 20px -13px rgba(var(--tema), 0.4), -20px -10px 20px -10px rgba(var(--preto), 0.1), inset 3px 3px 10px 5px rgba(255, 255, 255, 0.5);
}

#mp-root .dark button.segundo,
#mp-root .dark .btnSeg {
  box-shadow: inset 0 0px 0px 1px rgba(var(--tema), 0.3);
  padding: 0.5rem !important;
  border-radius: 16px;
  font-weight: 500;
  transition: All 0.4s ease;
  color: rgba(var(--branco), 0.8);
  border: rgba(var(--branco), 0.2) 1px solid;
  font-size: 14px;
  margin-left: 1rem;
  background-color: rgba(var(--tema), 0);
  cursor: pointer;
  margin-left: 1rem;
  background-color: rgba(149, 149, 149, 0.2);
  box-shadow: 15px 10px 20px -13px rgba(var(--preto), 0.3), -20px -10px 20px -10px rgba(var(--branco), 0.05), inset 3px 3px 10px 5px rgba(5, 5, 5, 0.1);
}

#mp-root .dark button.segundo:disabled,
#mp-root .dark .btnSeg:disabled {
  cursor: no-drop;
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
  text-decoration: line-through;
  font-weight: 500;
  background-color: rgba(122, 60, 60, 0.1) !important;
  box-shadow: inset 0 0px 0px 1px rgba(var(--tema), 0.1);
  color: rgba(163, 128, 128, 0.7);
}

#mp-root .dark button.primeiro.nao,
#mp-root .dark .btnPri.nao {
  box-shadow: 15px 10px 20px -13px rgba(var(--vermelho), 1), -20px -10px 20px -10px rgba(var(--preto), 0.1), inset 3px 3px 10px 5px rgba(255, 255, 255, 0.5);
  padding: 0.5rem !important;
  border-radius: 16px;
  font-weight: 500;
  transition: All 0.4s ease;
  color: #fff;
  border: 1px solid rgba(var(--vermelho), 0.3);
  font-size: 14px;
  margin-left: 1rem;
  background-color: rgba(var(--vermelho), 1);
  background-image: none;
  text-shadow: none;
  cursor: pointer;
  margin-left: 0.25rem;
}

#mp-root .dark button.segundo.nao,
#mp-root .dark .btnSeg.nao {
  box-shadow: inset 0 0px 0px 1px rgba(var(--vermelho), 0.3);
  padding: 0.5rem !important;
  border-radius: 16px;
  font-weight: 500;
  transition: All 0.4s ease;
  color: rgba(var(--vermelho), 0.6);
  border: rgba(var(--vermelho), 0.3) 1px solid;
  font-size: 14px;
  margin-left: 1rem;
  background-color: rgba(var(--vermelho), 0.05);
  cursor: pointer;
  margin-left: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 15px 10px 20px -13px rgba(var(--vermelho), 0.3), -20px -10px 20px -10px rgba(var(--preto), 0.05), inset 3px 3px 10px 5px rgba(255, 255, 255, 0.1);
}

#mp-root .dark .nav-tabs .nav-link.active {
  background: rgba(var(--tema), 0.9) !important;
  color: #fff;
  font-family: var(--family);
  font-weight: 700;
}

#mp-root .dark input[type='date'],
#mp-root .dark input[type='datetime-local'],
#mp-root .dark input[type='email'],
#mp-root .dark input[type='hidden'],
#mp-root .dark input[type='image'],
#mp-root .dark input[type='month'],
#mp-root .dark input[type='number'],
#mp-root .dark input[type='password'],
#mp-root .dark input[type='search'],
#mp-root .dark input[type='tel'],
#mp-root .dark input[type='text'],
#mp-root .dark input[type='time'],
#mp-root .dark input[type='week'],
#mp-root .dark textarea,
#mp-root .dark select,
#mp-root .dark input[type='url'] {
  font-family: var(--family);
  font-weight: 300;
  font-size: 20px;
  color: rgba(var(--branco), 1);
  border-radius: 5px;
  box-shadow: inset 0 -1px rgba(var(--preto), 0.2), inset 3px 3px 10px rgba(var(--preto), 0.1), inset -2px -2px 10px #353535;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(var(--branco), 0.3);
  transition: all 0.4s ease 0s;
  padding: 10px 15px !important;
}

#mp-root .dark textarea {
  height: 205px !important;
}

#mp-root .dark .form-control:focus {
  color: #000000;
  background-color: rgba(255, 255, 255, 0.8);
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

#mp-root .dark .bkneo {
  background: linear-gradient(155deg, #343434 0%, #525252 35%, #525252 100%);
}

#mp-root .dark .imgMeuPergamum {
  height: 100vh;
  background-image: url(/images/original_bg_login.png);
  background-size: cover;
  background-color: rgba(var(--tema), 0.1);
  background-attachment: fixed;
  background-blend-mode: overlay;
}

#mp-root .dark .alturadoHeaderMeuPergamum {
  margin-top: calc(-100vh + 150px);
}

#mp-root .dark .bkmenu-1 {
  background: rgba(var(--preto), 0.7);
}

#mp-root .dark .bkmenu-2 {
  background: linear-gradient(140deg, rgba(var(--preto), 0.8) 0%, rgba(var(--tema), 0.8) 50%, rgba(var(--tema), 1) 100%);
}

#mp-root .dark .b-menu {
  border: rgba(var(--preto), 1) solid 1px;
}

#mp-root .dark .b-menu-divide {
  border-color: rgba(var(--branco), 0.3);
}

#mp-root .dark .cor-menu {
  color: rgba(var(--branco), 1);
}

#mp-root .dark .cor-menu-ico {
  color: rgba(var(--branco), 0.5);
}

#mp-root .dark .cor-menu-sub {
  color: rgba(var(--branco), 0.5);
}

#mp-root .dark .bkbread {
  background: rgba(10, 10, 10, 0.8);
}

#mp-root .dark .cor-bread {
  color: rgba(var(--branco), 0.8);
}

#mp-root .dark .z-a-bread {
  color: rgba(var(--branco), 0.8);
}

#mp-root .dark .cor-bread-ico {
  color: rgba(var(--branco), 1);
}

#mp-root .dark .bk-theme-meio {
  background: rgba(var(--branco), 0.1);
}

#mp-root .dark .rotulo {
  color: rgba(var(--branco), 1);
}

#mp-root .dark h5,
#mp-root .dark h6 {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, rgba(var(--branco), 0.2), rgba(var(--branco), 0.5));
  border-image-slice: 1;
  color: rgba(var(--branco), 1);
}

#mp-root .dark h5 svg,
#mp-root .dark h6 svg {
  color: rgba(var(--branco), 0.5);
  margin-right: 0.5em;
}

#mp-root .dark .tabela {
  color: rgba(var(--branco), 1);
}

#mp-root .dark .tabela .row:nth-child(1) {
  border-bottom: 1px solid rgba(var(--branco), 0.2);
}

#mp-root .dark .topLinkSpaceMeuPergamum {
  background-color: rgba(var(--preto), 0.5);
}

#mp-root .dark .b-tema {
  border-color: rgba(var(--preto), 0.5);
}

@media (max-width: 576px) {
  #mp-root .btn-w100 {
    display: block;
    width: 100%;
  }
  #mp-root .tabela .row:nth-child(1) {
    background-color: transparent;
    border-bottom: none;
    font-weight: 700;
    margin-top: -15px;
  }
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.header {
  min-height: 440px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.header .header__navbar__logo {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 226px;
  height: 120px;
  background-color: #fff;
  border-radius: 0px 0px 5px 5px;
  border-top: 1px solid #003366;
  box-shadow: 0px 4px 8px 5px rgba(68, 68, 68, 0.16);
  margin-left: 50px;
}

.header .header__navbar__user {
  display: flex;
  border-color: rgba(172, 35, 51, 0.3);
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-right: 10px;
  height: 50px;
  font-size: 1.5em;
  color: rgba(0, 0, 0, 0.7);
  background-color: #c5c5c5;
  border-radius: 0 0 10px 10px !important;
  cursor: pointer;
}

.header .header__navbar__user:hover {
  color: #ac2333;
}

.header .header__navbar__user svg {
  color: rgba(0, 0, 0, 0.1);
}

.header .header__navbar__user svg:hover {
  color: #ac2333;
}

.header .header__navbar__user h6 {
  margin: 0;
  font-weight: 400;
}

.header .header__navbar__user {
  box-shadow: 0px 0px 14px 1px rgba(109, 109, 109, 0.3);
}

.header__search {
  /*width: 100%;
    z-index: 2;
    position: absolute;
    height: 330px;
    margin-top: 170px;
    display: flex;
    justify-content: center;
    align-items: center;*/
  width: 100%;
  margin-top: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -374px;
}

@media (max-width: 575px) {
  .header__navbar {
    flex-direction: column;
  }
  .header__navbar .header__navbar__user {
    align-self: flex-end;
  }
}

.header__img {
  background-image: url(../../images/img_topo_biblioteca.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.footer__layer-1 {
  background-color: #c0c0c033;
  width: 100%;
  height: 80px;
}

.footer__layer-2 {
  width: 100%;
  height: 100px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 1em;
}

.footer__layer-2 .footer__layer-2__img {
  background-image: url(/static/media/ico.7c632ecf.svg);
  height: 66px;
  background-size: cover;
  width: 100px;
}

.footer__layer-3 {
  width: 100%;
  height: 50px;
  background-color: #003366;
  display: flex;
  justify-content: center;
  padding: 1em;
}

.footer__layer-3 label {
  color: #fff;
  font-size: 0.85em;
}

.footer__layer-3 label a {
  color: #fff;
  text-decoration: underline;
  font-weight: bold;
}

#pesquisa_select {
  display: none;
}

.clean {
  height: 35px;
  width: 35px;
}

/* Visibilidade dos links de pesquisa: */
@media (max-width: 1007px) {
  #pesquisa_list {
    display: none;
  }
  #pesquisa_select {
    display: block;
  }
  .input-holder,
  .left__input__action {
    padding: 0 !important;
  }
  .left__input__action button {
    border: solid #cecece !important;
  }
  .left__input__action button.search {
    border-color: #01ad4f !important;
  }
  .left__input__action button.clean {
    border-color: #c00 !important;
  }
  .left__input__action span {
    display: block !important;
  }
}

@media (max-width: 576px) {
  .left__input__action {
    flex-direction: column !important;
  }
  .left__input__action button {
    width: 100%;
    border-radius: 5px;
  }
  .left__input__action button.search {
    background-color: #01ad4f !important;
  }
  .left__input__action button.clean {
    background-color: #c00 !important;
    margin-top: 1em;
  }
  .left__input__action button svg,
  .left__input__action button span {
    color: white !important;
  }
}

.header__search__content {
  padding-bottom: 100px;
}

.header__search__content .search__content__left {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.header__search__content .search__content__left a.content__left__header:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: transparent;
}

.header__search__content .search__content__left .content__left__header {
  width: 100%;
}

.header__search__content .search__content__left .content__left__header label.guia_pesquisa {
  border-bottom: 1px dotted #a6a6a6;
  color: #a6a6a6;
  padding: 5px 10px 5px 10px;
  border-radius: 0px;
  display: inline-block;
  position: relative;
  margin: 4px 7px 4px 7px;
  text-decoration: none;
  cursor: pointer;
  transition: All 0.2s ease;
  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px 5px 10px;
}

.header__search__content .search__content__left .content__left__header label.guia_pesquisa.active {
  border: none;
  background: #8b8b8b;
  color: #fff;
  border-radius: 5px !important;
  border-color: #003366 !important transparent transparent transparent;
}

.header__search__content .search__content__left .content__left__header label.guia_pesquisa.active:after {
  display: inline-block;
  border: 5px solid;
  height: 0;
  width: 0;
  position: absolute;
  top: 27px;
  content: "";
  display: inline-block;
  left: calc(50% - 4px);
  border-color: #003366 transparent transparent transparent;
}

.header__search__content .search__content__left .content__left__header label.guia_pesquisa:hover {
  color: white !important;
}

.header__search__content .search__content__left .content__left__header .content__left__input {
  display: flex;
  flex-direction: row;
}

.header__search__content .search__content__left .content__left__header .content__left__input .left__input__action {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.header__search__content .search__content__left .content__left__header .content__left__input .left__input__action button {
  background-color: transparent;
  border: transparent;
  display: flex;
}

.header__search__content .search__content__left .content__left__header .content__left__input .left__input__action button:hover svg {
  color: #fff !important;
}

.header__search__content .search__content__left .content__left__header .content__left__input .left__input__action button.search:hover {
  transition: 800ms;
  background-color: #01ad4f;
}

.header__search__content .search__content__left .content__left__header .content__left__input .left__input__action button.clean:hover {
  transition: 800ms;
  background-color: #c00;
}

.header__search__content .search__content__left .content__left__header .content__left__input .left__input__action button span {
  color: #000;
  margin-left: 10px;
  display: none;
}

.header__search__content .search__content__left .content__left__header .content__left__input .left__input__action svg {
  cursor: pointer;
}

.header__search__content .search__content__left .content__left__header .content__left__input .left__input__historic {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #003366;
}

.header__search__content .search__content__left .content__left__header .content__left__input .left__input__historic svg {
  margin-right: 10px;
}

.header__search__content .search__content__left .content__left__header .content__left__input .left__input__historic h6 {
  margin: 0;
  text-decoration: underline;
}

.header__search__content .search__content__left a:hover label {
  border: none;
  transition: All 0.2s ease;
  background: #8b8b8b;
  color: #fff;
  border-radius: 5px;
}

.header__search__content .search__content__left a:hover label:after {
  display: inline-block;
  border: 5px solid;
  height: 0;
  width: 0;
  position: absolute;
  top: 27px;
  content: "";
  display: inline-block;
  left: calc(50% - 4px);
  border-color: #8b8b8b transparent transparent transparent;
}

.header__search__content .search__content__left a:hover label.active:after {
  border-color: #003366 transparent transparent transparent;
}

/* Visibilidade dos links de pesquisa: */
@media (max-width: 600px) {
  .info__tabs,
  .info__tabs__content {
    padding: 40px 0 0 0 !important;
  }
  .card__header,
  .card__header__title {
    text-align: center;
  }
  .card__holder {
    padding: 0 !important;
  }
}

.info__tabs {
  padding: 20px 30px;
  background-color: #eeeeee;
}

.info__tabs.tabs-ask {
  background-color: #0000001a;
}

.info__tabs .info__tabs__tabs {
  display: flex;
  justify-content: center;
  border-bottom: 1px;
}

.info__tabs .info__tabs__tabs .nav-item:hover {
  background-color: #fff;
  transition: 300ms;
  box-shadow: inset 0px -3px 0px 0px rgba(0, 51, 102, 0.5);
}

.info__tabs .tab-content {
  background-color: #fff;
}

.info__tabs .card__header {
  padding: 0.4em 1em;
  font-size: 1.3em;
}

.info__tabs .card__header .card__header__title {
  color: #003366;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-bottom: 1px dotted #003366;
}

.info__tabs .card__header .card__header__title.tabs-ask {
  color: #000;
}

.info__tabs .card__header .icone-header {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding: 10px;
  background-color: #fff;
}

.info__tabs .card__holder {
  padding: 0 5em;
}

.info__tabs .card__holder .card__holder__content.col {
  display: flex;
  flex-direction: column;
}

.info__tabs .info__tabs__collapse {
  display: none;
  flex-direction: column;
}

.info__tabs .info__tabs__collapse label {
  padding: 1em;
  background-color: #fff;
  border: solid rgba(0, 51, 102, 0.5);
  border-radius: 3px;
  color: #003366;
  width: 100%;
  cursor: pointer;
}

.info__tabs .info__tabs__collapse .collapse__content {
  background-color: #fff;
  margin-bottom: 1em;
  border: solid #cecece;
}

.filtro-name {
  display: block;
  width: 179px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropDownSearch {
  display: flex;
  position: relative;
}

.dropDownSearch .dataList {
  z-index: 9999;
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  max-height: 500px;
}

.dropDownSearch .dataList ul {
  background-color: #fff;
  margin: 0;
  padding: 5px;
  border-radius: 0.25rem;
}

.dropDownSearch .dataList ul li.item {
  padding: 5px 15px !important;
  cursor: pointer;
  width: 100%;
  display: flex;
  color: #003366 !important;
}

.dropDownSearch .dataList ul li.item.selectedItem {
  background-color: rgba(0, 51, 102, 0.1) !important;
}

.dropDownSearch .__pagination {
  background-color: #F2F2F2;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dropDownSearch .__pagination .__btnChangePage {
  color: #003366;
  font-size: 20px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.dropDownSearch .__pagination .__pageInfo {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.dropDownSearch .__pagination .__pageInfo .__pageNumber {
  font-size: 16px;
}

.dropDownSearch .__pagination .__pageInfo .__pageNumber:first-child {
  font-weight: 600;
}

.novasAquisicoesTipoLista .__coverList {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  list-style: none;
}

.novasAquisicoesTipoLista .__coverList li.__cover {
  cursor: pointer;
}

.novasAquisicoesTipoLista .__coverList li.__cover .__item {
  max-width: 230px;
  max-height: 300px;
  overflow: hidden;
  position: relative;
}

.novasAquisicoesTipoLista .__coverList li.__cover .__item .img-fluid {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
}

.__bookName {
  position: absolute;
  left: 0;
  top: 0;
  z-index: +1;
  color: #fff;
  font-size: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  width: 100%;
  text-align: center;
}

.consultaContent {
  background-color: #c0c0c033;
}

.modal-header .close {
  border: none !important;
  margin-top: -45px;
  padding: 0.5rem 0.5rem;
  opacity: 1;
}

.modal-header {
  display: block;
}

.suspense-loader {
  width: 200px;
  height: 100px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  margin: auto;
}

.suspense-loader::before {
  content: '';
  z-index: 500;
  background-color: RGBA(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.suspense-loader div {
  display: inline-block;
}

.carouselM .janela .horizontal {
  transition: 450ms transform;
  white-space: nowrap;
  overflow-x: hidden;
  /*scroll-snap-type: x mandatory;*/
  /*Para de funcionar o scroll para o topo*/
  scroll-snap-type: none;
}

.carouselM .janela .horizontal .imgCarousel {
  margin-right: 2%;
  scroll-snap-align: start;
  width: 28%;
}

.carouselM .janela .horizontal .imgCarousel .nomeEvento {
  position: absolute;
  margin-top: -180px;
  width: 28%;
  white-space: normal;
}

.movCarousel-1 {
  transform: translateX(calc(0 * -90%));
  transition: 450ms transform;
}

.movCarousel-2 {
  transform: translateX(calc(1 * -90%));
  transition: 450ms transform;
}

.movCarousel-3 {
  transform: translateX(calc(2 * -90%));
  transition: 450ms transform;
}

.pegaCaptcha > div > div {
  margin: auto;
}
